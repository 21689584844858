import React, { useEffect, useState } from "react";
import StyledBody from "./styled";
import CampaignDetail from "../CampaignDetail";
import CreateCampaign from "../CreateCampaign";
import FullStory from "react-fullstory";
import {FULLSTORY_ORG_ID} from "../../../../utils/axios_helper";
import { RootState } from "services/store";
import { useSelector } from "react-redux";

interface BodyInterface {
    heading: String[];
    setHeading: (val: String[]) => void;
}

const Body: React.FunctionComponent<BodyInterface> = ({ heading, setHeading }) => {
    const [showCampaignDetailsPage, setShowCampaignDetailsPage] = useState(true);
    const country = useSelector((state: RootState) => state.home.country);
    useEffect(() => {
        setHeading(['Campaign', country.charAt(0) + country.substring(1).toLowerCase()])
    }, [])
    return (
        <StyledBody>
            {
                showCampaignDetailsPage ? <CampaignDetail setShowCampaignDetailsPage={setShowCampaignDetailsPage}/> :
                    <CreateCampaign setHeading={setHeading}/>
            }
            <div>
                <FullStory org={FULLSTORY_ORG_ID || ''}/>
            </div>
        </StyledBody>
    )
}

export default Body;