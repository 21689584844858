import React from "react";
import StyledLeft from "./styled";
import { useSelector } from "react-redux";
import { RootState } from "services/store";

const Left = () => {
  const audience = useSelector((state: RootState) => ({
    method: state.audience.method,
    activeTab: state.audience.activeTab,
  }));

  const divLabels = [
    {
      tabnum: 0,
      name: "Platforms",
    },
    {
      tabnum: 1,
      name: "Campaign",
    },
    {
      tabnum: 2,
      name: "Summary",
    },
  ];

  return (
    <StyledLeft>
      {divLabels.map((item, ind) => (
        <div key={ind}>
          <div key={ind} className="row">
            <div
              className={
                audience.activeTab >= item.tabnum ? "num-active" : "num"
              }
            >
              {ind + 1}
            </div>

            <span
              className={
                audience.activeTab >= item.tabnum ? "name-active" : "name"
              }
            >
              {item.name}
            </span>
          </div>
          {item.tabnum + 1 < divLabels.length && (
            <div
              className={
                audience.activeTab >= item.tabnum
                  ? "ver-line-active"
                  : "ver-line"
              }
            ></div>
          )}
        </div>
      ))}
    </StyledLeft>
  );
};

export default Left;
