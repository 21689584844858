import styled from "styled-components";

const StyledCampaign = styled.div`
  display: flex;
  flex-direction: column;

  .container {
    display: flex;
    flex-direction: column;
  }

  .campaign-selection {
    width: 315px;
    position: relative;
    font-size: 32px;
    line-height: 32px;
    display: inline-block;
    font-family: Inter;
    color: #000;
    text-align: left;
    margin-top: 16px;
  }

  .connect-and-choose {
    width: 480px;
    position: relative;
    font-size: 14px;
    font-family: Inter;
    color: #000;
    text-align: left;
    margin-top: 16px;
  }

  .created-audience {
    width: 142px;
    position: relative;
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    font-family: Inter;
    color: #000;
    text-align: left;
    margin-top: 32px;
  }

  .banking-loans {
    width: 132px;
    position: relative;
    font-size: 20px;
    line-height: 24px;
    font-family: Inter;
    color: #000;
    text-align: left;
    margin-top: 8px;
  }

  .span {
    color: #82868f;
  }
  .select-campaigns-2-container {
    width: 164px;
    margin-top: 32px;
    position: relative;
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    font-family: Inter;
    text-align: left;
    display: flex;
    color: #000;
  }

  .campaign-table-body {
    height: 90%;
    overflow-y: auto;
  }

  .campaign-table-title {
    width: 143px;
    position: relative;
    font-size: 14px;
    font-weight: 500;
    font-family: Inter;
    color: #5c5e62;
    text-align: left;
    display: inline-block;
  }

  .campaign-name {
    width: 143px;
    position: relative;
    font-size: 14px;
    font-weight: 500;
    font-family: Inter;
    color: #000;
    text-align: left;
    display: inline-block;
    word-wrap: break-word;
  }

  .campaign-table-select-all {
    width: 80px;
    display: flex;
  }

  .campaign-client {
    width: 143px;
    position: relative;
    font-size: 14px;
    font-family: Inter;
    color: #000;
    text-align: left;
    display: inline-block;
    word-wrap: break-word;
  }

  .campaign-platform {
    width: 143px;
    position: relative;
    font-size: 14px;
    font-family: Inter;
    color: #000;
    text-align: left;
    display: inline-block;
    word-wrap: break-word;
  }

  .keywrod-table-h {
    margin: 8px 13px;
    display: grid;
    grid-template-columns: 3fr 1fr 3fr 1fr;
  }

  .keyword-item {
    color: #000;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .keyword-item-score {
    color: #000;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .keyword-table-row {
    padding: 0px 13px;
    height: 56px;
    background: #f7f7f7;
    display: grid;
    grid-template-columns: 3fr 1fr 3fr 1fr;
    align-items: center;
  }

  .campaign-table-row-even {
    padding: 0px 13px;
    min-height: 56px;
    background: #fff;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    align-items: center;
  }

  .campaign-table-h {
    margin: 8px 13px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  .keyword-table-ctn {
    width: 540px;
    height: 424px;
    flex-shrink: 0;
    fill: #fff;
    stroke-width: 1px;
    stroke: #cdd1d8;
    border: 1px solid #cdd1d8;
    border-radius: 8px;
    overflow-y: auto;
    margin-top: 12px;
  }

  .little-dark-row {
    background-color: #f7f7f7;
  }

  .campaign-checkbox {
    display: flex;
  }

  .hor-line {
    height: 1px;
    width: 100%;
    background: #cdd1d8;
  }

  .continue-btn-container {
    position: relative;
    font-weight: 500;
    margin-top: 100px;
    cursor: pointer;
    display: flex;
    gap: 16px;
    fill: #fff;
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.16);
    width: 510px;
    height: 50px;
    flex-shrink: 0;
    padding: 12px 16px;
    border-radius: 8px;
    background: #fff;
  }

  .continue-btn {
    width: 412px;
    position: relative;
    border-radius: 8px;
    background-color: #4485f4;
    border: 1px solid rgba(220, 227, 227, 0);
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 13px 12px;
    text-align: left;
    font-size: 18px;
    color: #fff;
    font-family: Inter;
    cursor: pointer;
  }

  .back-btn {
    border-radius: 8px;
    border: 1px solid #cdd1d8;
    background: #fff;
    display: flex;
    width: 80px;
    padding: 13px 12px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    color: #000;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
`;

export default StyledCampaign;
