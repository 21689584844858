import styled from "styled-components";

const StyledAudience = styled.div`
    width: 100%;
    margin: 10px 0px;
    display: flex;
    justify-content: center;

`;

export default StyledAudience;