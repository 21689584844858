import React, { useEffect } from "react";
import StyledCreateAudience from "./styled";
import { Route, Routes } from "react-router";
import Method from "./Method";
import Left from "./Left";
import Define from "./Define";
import Keyword from "./Keyword";
import Platform from "./Platform";
import Campaign from "./Campaign";
import Summary from "./Summary";
import {
  getGoogleAuthUrl,
  getMetaAuthUrl,
  getTikTokAuthUrl,
  getXAuthUrl,
  vaticAxiosGet,
} from "utils/axios_helper";
import { useDispatch, useSelector } from "react-redux";
import audience, {
  Campaign as CampaignInterface,
  set,
} from "services/reducer/audience";
import platform, { set as platformSet } from "services/reducer/platform";
import { RootState } from "services/store";

interface CreateAudienceProps {
  setHeading: (val: String[]) => void;
}

const CreateAudience: React.FunctionComponent<CreateAudienceProps> = ({
  setHeading,
}) => {
  const dispatch = useDispatch();
  const platformInfo = useSelector((state: RootState) => ({
    google: state.platform.google,
    meta: state.platform.meta,
    x: state.platform.x,
    tiktok: state.platform.tiktok,
  }));
  const audience = useSelector((state: RootState) => ({
    campaigns: state.audience.campaigns,
  }));
  const country = useSelector((state: RootState) => state.home.country);

  useEffect(() => {
    setHeading(["Audience", country.charAt(0) + country.substring(1).toLowerCase(), "Create"]);
    const load = async () => {
      const tmp = await getGoogleAuthUrl(
        "/dashboard/audience/create/platforms"
      );
      dispatch(set({ googleAuthUrl: tmp }));
      const temp1 = await getMetaAuthUrl(
        "/dashboard/audience/create/platforms"
      );
      dispatch(set({ metaAuthUrl: temp1 }));

      const temp2 = await getXAuthUrl("/dashboard/platform");
      dispatch(set({ xAuthUrl: temp2.url }));
      localStorage.setItem('xAuthSecret', temp2.secret);

      const temp3 = await getTikTokAuthUrl("/dashboard/platform");
      dispatch(set({ tiktokAuthUrl: temp3 }));

      // const googleConnectedAccRes = await vaticAxiosGet(
      //   "/api/v1/ads/google/connected-accounts"
      // );
      // if (googleConnectedAccRes && googleConnectedAccRes.data) {
      //   dispatch(platformSet({ google: googleConnectedAccRes.data }));
      // }

      // const metaConnectedAccRes = await vaticAxiosGet(
      //   "/api/v1/ads/meta/connected-accounts"
      // );
      // if (metaConnectedAccRes && metaConnectedAccRes.data) {
      //   dispatch(platformSet({ meta: metaConnectedAccRes.data }));
      // }
    };
    load();
  }, []);

  useEffect(() => {
    let temp: CampaignInterface[] = [];
    const load = async () => {
      const res = await vaticAxiosGet(`/api/v1/ads/google/campaigns`);
      if (res.data) {
        let data = res.data;
        data = data.map((i: any) => {
          return {
            checked: false,
            name: i.name,
            client: "Home Credit",
            platforms: ["google"],
            id: i.id,
            acc_id: i.acc_id,
          };
        });
        temp = data;
      }

      const res1 = await vaticAxiosGet(`/api/v1/ads/meta/campaigns`);
      if (res1.data) {
        let data = res1.data;
        data = data.map((i: any) => {
          return {
            checked: false,
            name: i.name,
            client: "Home Credit",
            platforms: ["meta"],
            id: i.id,
            acc_id: i.acc_id,
          };
        });
        temp = [...temp, ...data];
        dispatch(set({ campaigns: temp }));
      }

      const res2 = await vaticAxiosGet(`/api/v1/ads/x/campaigns`);
      if (res2.data) {
        let data = res2.data;
        data = data.map((i: any) => {
          return {
            checked: false,
            name: i.name,
            client: "Home Credit",
            platforms: ["x"],
            id: i.id,
            acc_id: i.acc_id,
          };
        });
        temp = [...temp, ...data];
        dispatch(set({ campaigns: temp }));
      }

      const res3 = await vaticAxiosGet(`/api/v1/ads/tiktok/campaigns`);
      if (res3.data) {
        let data = res3.data;
        data = data.map((i: any) => {
          return {
            checked: false,
            name: i.name,
            client: "Home Credit",
            platforms: ["tiktok"],
            id: i.id,
            acc_id: i.acc_id,
          };
        });
        temp = [...temp, ...data];
      }
      dispatch(set({ campaigns: temp }));
    };

    load();
  }, [platformInfo.google, platformInfo.meta]);
  return (
    <StyledCreateAudience>
      <div className="container">
        <Left></Left>
        <div>
          <Routes>
            <Route path="*" element={<Platform  setHeading={setHeading}/>} />
            <Route path="campaign" element={<Campaign setHeading={setHeading}/>} />
            <Route path="summary" element={<Summary  setHeading={setHeading}/>} />
          </Routes>
        </div>
      </div>
    </StyledCreateAudience>
  );
};

export default CreateAudience;
