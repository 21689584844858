import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { act } from "react-dom/test-utils";

export interface Keyword {
  candi_keyword: string;
  score: number;
  selected: boolean;
}

export interface EstimateState {
  method: number;
  activeTab: number;
  estimateName: string;
  customKeywords: string;
  region: string;
  awareness: string;
  promoting: string;
  audience: string;
  updating: boolean;
  currEstimateId: number;
  promptId: number;
  keywords: Keyword[];
  language: string;
  urls: string;
  keywordsFetched: boolean;
}

const temp = localStorage.getItem("estimate") || "{}";
let obj = JSON.parse(temp);

const initialState: EstimateState = {
  method: obj.method || 1,
  activeTab: obj.activeTab || 0,
  estimateName: obj.estimateName || "",
  customKeywords: obj.customKeywords || "",
  region: obj.region || "INDIA",
  awareness: obj.goal || "awareness",
  promoting: obj.promoting || "",
  audience: obj.audience || "",
  updating: false,
  currEstimateId: obj.currEstimateId || -1,
  promptId: obj.promptId || -1,
  keywords: obj.keywords || [],
  language: obj.language || "English",
  urls: obj.urls || "",
  keywordsFetched: obj.keywordsFetched || false,
};

export const estimateSlice = createSlice({
  name: "estimate",
  initialState,
  reducers: {
    set: (state, action: PayloadAction<Partial<EstimateState>>) => {
      const t0 = JSON.parse(localStorage.getItem("estimate") || "{}");

      localStorage.setItem(
        "estimate",
        JSON.stringify({
          ...t0,
          ...action.payload,
        })
      );

      return {
        ...state,
        ...action.payload,
      };
    },
    unset: (state) => {
      try {
        localStorage.removeItem("estimate");
      } catch {
        console.log("error");
      }

      const new_initial_state: EstimateState = {
        method: 1,
        activeTab: 0,
        estimateName: "",
        customKeywords: "",
        region: "",
        awareness: "",
        promoting: "",
        audience: "",
        updating: false,
        currEstimateId: -1,
        promptId: -1,
        keywords: [],
        language: "English",
        urls: "",
        keywordsFetched: false,
      };

      return {
        ...new_initial_state,
      };
    },
  },
});

export const { set, unset } = estimateSlice.actions;
export default estimateSlice.reducer;
