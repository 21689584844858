import React, { useEffect, useState } from "react";
import StyledAudiencePlatforms from "./styled";
import fb_icon from "assets/platform_icons/meta-ads-logo.svg";
import x_icon from "assets/platform_icons/x-icon.svg";
import google_social_icon from "assets/platform_icons/google-social-icon.svg";
import snapchat_icon from "assets/platform_icons/snapchat-icon.png";
import tiktok_icon from "assets/platform_icons/tiktok-icon.svg";
import connected_icon from "assets/platform_icons/connected-icon.svg";
import platformRight from "assets/platform-right-side.svg";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "services/store";
import {
  CampaignInterface,
  meta_ads_cred,
  set,
  unset,
  x_ads_cred,
} from "services/reducer/platform";
import {
  REACT_APP_BASE_URL,
  getGoogleAuthUrl,
  getMetaAccessToken,
  getMetaAuthUrl,
  getXAuthUrl,
  vaticAxiosGet,
  vaticAxiosPost,
  FULLSTORY_ORG_ID,
  getTikTokAuthUrl,
} from "utils/axios_helper";
import FullStory from "react-fullstory";

interface PlatformInterface {
  setHeading: (val: String[]) => void;
}

const AudiencePlatforms: React.FunctionComponent<PlatformInterface> = ({
  setHeading,
}) => {
  const platformInfo = useSelector((state: RootState) => ({
    google: state.platform.google,
    meta_access_token: state.platform.meta,
    meta: state.platform.meta,
    x: state.platform.x,
    tiktok: state.platform.tiktok,
  }));
  const countryDb = useSelector((state: RootState) => state.home.countryDb);
  const userId = useSelector((state: RootState) => state.login.user_id);
  const dispatch = useDispatch();
  const country = useSelector((state: RootState) => state.home.country);

  const [googleAuthUrl, setGoogleAuthUrl] = useState("");
  const [metaAuthUrl, setMetaAuthUrl] = useState("");
  const [xAuthUrl, setXAuthUrl] = useState("");
  const [tiktokAuthUrl, setTiktokAuthUrl] = useState("");
  const location = useLocation();
  const metaState = process.env.REACT_APP_META_STATE;

  const getCampaigns = async () => {
    let temp: CampaignInterface[] = [];
    const res = await vaticAxiosGet(`/api/v1/ads/google/campaigns`);
    if (res.data) {
      let data = res.data;
      data = data.map((i: any) => {
        return {
          checked: false,
          name: i.name,
          client: "Home Credit",
          platforms: ["google"],
          id: i.id,
          acc_id: i.acc_id,
        };
      });
      temp = data;
      dispatch(set({ campaigns: temp }));
    }

    const res1 = await vaticAxiosGet(`/api/v1/ads/meta/campaigns`);
    if (res1.data) {
      let data = res1.data;
      data = data.map((i: any) => {
        return {
          checked: false,
          name: i.name,
          client: "Home Credit",
          platforms: ["meta"],
          id: i.id,
          acc_id: i.acc_id,
        };
      });
      temp = [...temp, ...data];
      dispatch(set({ campaigns: temp }));
    }

    const res2 = await vaticAxiosGet(`/api/v1/ads/x/campaigns`);
    if (res2.data) {
      let data = res2.data;
      data = data.map((i: any) => {
        return {
          checked: false,
          name: i.name,
          client: "Home Credit",
          platforms: ["x"],
          id: i.id,
          acc_id: i.acc_id,
        };
      });
      temp = [...temp, ...data];
      dispatch(set({ campaigns: temp }));
    }

    const res3 = await vaticAxiosGet(`/api/v1/ads/tiktok/campaigns`);
    if (res3.data) {
      let data = res3.data;
      data = data.map((i: any) => {
        return {
          checked: false,
          name: i.name,
          client: "Home Credit",
          platforms: ["tiktok"],
          id: i.id,
          acc_id: i.acc_id,
        };
      });
      temp = [...temp, ...data];
    }
    dispatch(set({ campaigns: temp }));
  };

  useEffect(() => {
    setHeading(["Connect", country.charAt(0) + country.substring(1).toLowerCase()]);
    const load = async () => {
      const tmp = await getGoogleAuthUrl("/dashboard/platform");
      setGoogleAuthUrl(tmp);
      const temp1 = await getMetaAuthUrl("/dashboard/platform");
      setMetaAuthUrl(temp1);
      const temp2 = await getXAuthUrl("/dashboard/platform");
      setXAuthUrl(temp2.url);
      localStorage.setItem('xAuthSecret', temp2.secret);
      const temp3 = await getTikTokAuthUrl("/dashboard/platform");
      setTiktokAuthUrl(temp3);
    };
    load();
  }, []);

  useEffect(() => {
    const currentUrlSearchParams = queryString.parse(location.search);

    const google_scope = Array.isArray(currentUrlSearchParams["scope"])
      ? currentUrlSearchParams["scope"][0]
      : currentUrlSearchParams["scope"];
    const code = Array.isArray(currentUrlSearchParams["code"])
      ? currentUrlSearchParams["code"][0]
      : currentUrlSearchParams["code"];
    const state = Array.isArray(currentUrlSearchParams["state"])
      ? currentUrlSearchParams["state"][0]
      : currentUrlSearchParams["state"];
    
    const oauthVerifier = Array.isArray(currentUrlSearchParams["oauth_verifier"])
      ? currentUrlSearchParams["oauth_verifier"][0]
      : currentUrlSearchParams["oauth_verifier"];

    const oauthToken = Array.isArray(currentUrlSearchParams["oauth_token"])
      ? currentUrlSearchParams["oauth_token"][0]
      : currentUrlSearchParams["oauth_token"];

    const auth_code = Array.isArray(currentUrlSearchParams["auth_code"])
    ? currentUrlSearchParams["auth_code"][0]
    : currentUrlSearchParams["auth_code"];

    if (google_scope && code) {
      const loadGoogleAccessTok = async () => {
        const res = await vaticAxiosGet("/api/v1/ads/google/refresh-token", {
          code: code,
          user_id: userId,
          redirect_uri: REACT_APP_BASE_URL + "/dashboard/platform",
          db: countryDb,
        });

        const load = async () => {
          const googleConnectedAccRes = await vaticAxiosGet(
            "/api/v1/ads/google/connected-accounts"
          );
          if (googleConnectedAccRes && googleConnectedAccRes.data) {
            dispatch(set({ google: googleConnectedAccRes.data }));
            getCampaigns();
          }
        };
        load();
      };
      loadGoogleAccessTok();
    } else if (state === metaState) {
      const loadMetaAccessToken = async () => {
        await getMetaAccessToken(code || "", countryDb, "/dashboard/platform");
        const load = async () => {
          const metaConnectedAccRes = await vaticAxiosGet(
            "/api/v1/ads/meta/connected-accounts"
          );
          if (metaConnectedAccRes && metaConnectedAccRes.data) {
            dispatch(set({ meta: metaConnectedAccRes.data }));
            getCampaigns();
          }
        };
        load();
      };
      loadMetaAccessToken();
    } else if(oauthToken && oauthVerifier) {
      const loadXAccessToken = async () => {
        await vaticAxiosPost("/api/v1/ads/x/access-token", {
          oauth_verifier: oauthVerifier,
          oauth_token: oauthToken,
          token_secret: localStorage.getItem('xAuthSecret') || '',
          db: countryDb,
        });
        const load = async () => {
          const xConnectedAccRes = await vaticAxiosGet(
            "/api/v1/ads/x/connected-accounts"
          );
          if (xConnectedAccRes && xConnectedAccRes.data) {
            dispatch(set({ x: xConnectedAccRes.data }));
            getCampaigns();
          }
        };
        load();
      };
      loadXAccessToken();
    } else if(auth_code) {
      const loadTiktokAccessToken = async () => {
        await vaticAxiosPost("/api/v1/ads/tiktok/access-token", {
          auth_code: auth_code,
          code: code,
          db: countryDb,
        });
        const load = async () => {
          const tiktokConnectedAccRes = await vaticAxiosGet(
            "/api/v1/ads/tiktok/connected-accounts"
          );
          if (tiktokConnectedAccRes && tiktokConnectedAccRes.data) {
            dispatch(set({ tiktok: tiktokConnectedAccRes.data }));
            getCampaigns();
          }
        };
        load();
      };
      loadTiktokAccessToken();
    }
  }, [location]);
  return (
    <StyledAudiencePlatforms>
      <div className="container">
        <div className="container-left">
          <div className="platforms">Connect to your ad platform</div>
          <div className="platforms-sub">
            Select and sign into your prefered ad platform
          </div>

          <div className="rectangle-div">
            <div className="platform-container">
              <div className="platform-icon-container">
                <img className="platform-icon" src={fb_icon}></img>
              </div>

              <div className="w-100">
                <div className="platform-google-container">
                  <div className="platform-name">
                    Facebook Ads <span className="asterisk1">&nbsp;*</span>
                  </div>

                  <div className="platform-connect-container">
                    <a className="connect" href={metaAuthUrl}>
                      CONNECT
                    </a>
                  </div>
                </div>

                {platformInfo.meta.map((val, ind) => (
                  <div className="platform-google-container" key={ind}>
                    <div className="user-name">
                      @{val.name}
                    </div>
                    <div className="connected-ctn">
                      <div className="connected">CONNECTED</div>
                      <img src={connected_icon} className="connected-icon"></img>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="rectangle-div">
            <div className="platform-container">
              <div className="platform-icon-container">
                <img className="platform-icon" src={google_social_icon}></img>
              </div>

              <div className="w-100">
                <div className="platform-google-container">
                  <div className="platform-name">
                    Google Ads <span className="asterisk1">&nbsp;*</span>
                  </div>

                  <div className="platform-connect-container">
                    <a className="connect" href={googleAuthUrl}>
                      CONNECT
                    </a>
                  </div>
                </div>

                {platformInfo.google.map((val, ind) => (
                  <div className="platform-google-container" key={ind}>
                    <div className="user-name">
                      @{val.name}
                    </div>
                    <div className="connected-ctn">
                      <div className="connected">CONNECTED</div>
                      <img
                        src={connected_icon}
                        className="connected-icon"
                      ></img>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="rectangle-div">
            <div className="platform-container">
              <div className="platform-icon-container">
                <img className="platform-icon" src={x_icon}></img>
              </div>

              <div className="w-100">
                <div className="platform-google-container">
                  <div className="platform-name">
                  Twitter(X) Ads <span className="asterisk1">&nbsp;*</span>
                  </div>

                  <div className="platform-connect-container">
                    <a className="connect" href={xAuthUrl}>
                      CONNECT
                    </a>
                  </div>
                </div>

                {platformInfo.x.map((val, ind) => (
                  <div className="platform-google-container" key={ind}>
                    <div className="user-name">
                      @{val.name}
                    </div>
                    <div className="connected-ctn">
                      <div className="connected">CONNECTED</div>
                      <img
                        src={connected_icon}
                        className="connected-icon"
                      ></img>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="rectangle-div">
          <div className="platform-container">
              <div className="platform-icon-container">
                <img className="platform-icon" src={tiktok_icon}></img>
              </div>

              <div className="w-100">
                <div className="platform-google-container">
                  <div className="platform-name">
                  TikTok Ads <span className="asterisk1">&nbsp;*</span>
                  </div>

                  <div className="platform-connect-container">
                    <a className="connect" href={tiktokAuthUrl}>
                      CONNECT
                    </a>
                  </div>
                </div>

                {platformInfo.tiktok.map((val, ind) => (
                  <div className="platform-google-container" key={ind}>
                    <div className="user-name">
                      @{val.name}
                    </div>
                    <div className="connected-ctn">
                      <div className="connected">CONNECTED</div>
                      <img
                        src={connected_icon}
                        className="connected-icon"
                      ></img>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="asterisk-text">
            <span className="asterisk1"> * </span>
            <span>
              When you provide consent through connecting your google account
              our platform will use it to obtain list of campaigns and then
              upload a user-list to your google ads platform and then attach it
              to the campaign of your choice.
            </span>
          </div>
        </div>

        <div className="container-right">
          <img className="platform-left-img" src={platformRight}></img>
        </div>
      </div>
      <div>
        <FullStory org={FULLSTORY_ORG_ID || ""} />
      </div>
    </StyledAudiencePlatforms>
  );
};

export default AudiencePlatforms;
