import styled from "styled-components";

const StyledHomeCards = styled.div`
  //   width: 960px;
  // height: 80vh;
  overflow-y: auto;
  padding: 20px 0px 0px 30px;
  position: relative;

  .cards-container {
    // display: grid;
    // grid-template-columns: repeat(5, 1fr);
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }

  .home-card {
    background-color: #eee;
    padding: 10px;
    text-align: center;
    height: 250px;
    width: 200px;
    border-radius: 20px;
  }

  .home-card-tour {
    padding: 10px;
    font-size: 18px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: white;
    height: 250px;
    width: 200px;
    border-radius: 20px;

    cursor: pointer;
  }

  .icon-bg {
    background-color: #4485f4;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .close-tour-card {
    display: flex;
    justify-content: end;
  }

  .tour-card-footer {
    display: flex;
    justify-content:space-between;
    color: black;
    background-color: white;
    border-radius: 50px;
    padding: 5px;
    align-items:center;
  }

  .home-card-title {
    background-color: white;
    padding: 5px 10px;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    align-items: center;
    height: 30px;
    color: #000;
  }

  .home-card-add-widget {
    border: dotted 5px #eee;
    padding: 10px;
    font-size: 18px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: white;
    height: 250px;
    width: 200px;
    border-radius: 20px;
  }

  .card1 {
    background: #4485f4;
    cursor:pointer;
  }

  .card2 {
    background: #689bf1;
  }

  .card3 {
    background: #8bb3f8;
  }

  .card4 {
    background: #c6dafd;
  }

  .card5 {
    background: #d1e2ff;
  }

  .media-accounts-ctn {
    height: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
  }

  .media-img {
    display: flex;
    gap: 10px;
    justify-content: center;
    width: 100%;
  }

  .media-img div {
    width: 45px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: #fff;
  }

  .media-img img {
    width: 30px;
    height: 30px;
    margin: 20px;
  }

  .icon {
    width: 20px;
    height: 20px;
  }

  .text {
    color: #fff;
  }

  // Modal

  .app {
    position: relative;
    height: 100vh;
    width: 100vw;
  }

  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    position: relative;
  }

  .content-div {
    padding: 20px;
    margin: 10px;
    background-color: #ccc;
    cursor: pointer;
    transition: transform 0.3s ease, background-color 0.3s ease;
  }

  .modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }

  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.75);
    backdrop-filter: blur(5px);
    z-index: 1000;
  }

  .modal-content {
    text-align: center;
  }

  .modal-content h2 {
    margin-bottom: 20px;
  }

  .modal-content button {
    padding: 10px 20px;
    background-color: #4484f3;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
`;

export default StyledHomeCards;
