import React, { useState, useEffect } from "react";
import StyledHome from "./styled";
import LeftPanel from "./LeftPanel";
import Header from "./Header";
import Body from "./Body";
import { useNavigate } from "react-router";
import FullStory from "react-fullstory";
import { FULLSTORY_ORG_ID, vaticAxiosGet } from "../../utils/axios_helper";
import { CampaignInterface, set } from "services/reducer/platform";
import { useDispatch } from "react-redux";

const Home = () => {
  const [isExtended, setIsExtended] = useState(true);
  const [heading, setHeading] = useState<Array<String>>(["Home"]);
  const [activeTab, setActiveTab] = useState(1);
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const redirect = (path: string) => {
    navigate(path);
  };

  const getCampaigns = async () => {
    let temp: CampaignInterface[] = [];
    const res = await vaticAxiosGet(`/api/v1/ads/google/campaigns`);
    if (res.data) {
      let data = res.data;
      data = data.map((i: any) => {
        return {
          checked: false,
          name: i.name,
          client: "Home Credit",
          platforms: ["google"],
          id: i.id,
          acc_id: i.acc_id,
        };
      });
      temp = data;
      dispatch(set({ campaigns: temp }));
    }

    const res1 = await vaticAxiosGet(`/api/v1/ads/meta/campaigns`);
    if (res1.data) {
      let data = res1.data;
      data = data.map((i: any) => {
        return {
          checked: false,
          name: i.name,
          client: "Home Credit",
          platforms: ["meta"],
          id: i.id,
          acc_id: i.acc_id,
        };
      });
      temp = [...temp, ...data];
      dispatch(set({ campaigns: temp }));
    }

    const res2 = await vaticAxiosGet(`/api/v1/ads/x/campaigns`);
    if (res2.data) {
      let data = res2.data;
      data = data.map((i: any) => {
        return {
          checked: false,
          name: i.name,
          client: "Home Credit",
          platforms: ["x"],
          id: i.id,
          acc_id: i.acc_id,
        };
      });
      temp = [...temp, ...data];
      dispatch(set({ campaigns: temp }));
    }

    const res3 = await vaticAxiosGet(`/api/v1/ads/tiktok/campaigns`);
    if (res3.data) {
      let data = res3.data;
      data = data.map((i: any) => {
        return {
          checked: false,
          name: i.name,
          client: "Home Credit",
          platforms: ["tiktok"],
          id: i.id,
          acc_id: i.acc_id,
        };
      });
      temp = [...temp, ...data];
    }
    dispatch(set({ campaigns: temp }));
  };

  useEffect(() => {
    const load = async () => {
      const googleConnectedAccRes = await vaticAxiosGet(
        "/api/v1/ads/google/connected-accounts"
      );
      if (googleConnectedAccRes && googleConnectedAccRes.data) {
        dispatch(set({ google: googleConnectedAccRes.data }));
      }

      const metaConnectedAccRes = await vaticAxiosGet(
        "/api/v1/ads/meta/connected-accounts"
      );
      if (metaConnectedAccRes && metaConnectedAccRes.data) {
        dispatch(set({ meta: metaConnectedAccRes.data }));
      }

      const xConnectedAccRes = await vaticAxiosGet(
        "/api/v1/ads/x/connected-accounts"
      );
      if (xConnectedAccRes && xConnectedAccRes.data) {
        dispatch(set({ x: xConnectedAccRes.data }));
      }

      const tiktokConnectedAccRes = await vaticAxiosGet(
        "/api/v1/ads/tiktok/connected-accounts"
      );
      if (tiktokConnectedAccRes && tiktokConnectedAccRes.data) {
        dispatch(set({ tiktok: tiktokConnectedAccRes.data }));
      }
    };
    load();
    getCampaigns();
  }, []);

  return (
    <StyledHome>
      <LeftPanel
        isExtended={isExtended}
        heading={heading}
        setHeading={setHeading}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />
      <div className="container">
        <Header
          isExtended={isExtended}
          setIsExtended={setIsExtended}
          heading={heading}
        />
        <Body heading={heading} setHeading={setHeading} activeTab={activeTab} />
      </div>
      <div>
        <FullStory org={FULLSTORY_ORG_ID || ""} />
      </div>
    </StyledHome>
  );
};

export default Home;
