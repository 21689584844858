import React, {useEffect, useState} from "react";
import StyledResetPassword from "./styled";
import queryString from "query-string";
import {vaticAxiosGet, vaticAxiosPost} from "../../../utils/axios_helper";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {useNavigate} from "react-router";
import validator from "validator";

const ResetPassword = () => {
    const navigate = useNavigate();
    const [password, setPassword] = useState<{pass: string, confirmPass: string, email: string, token: string}>({pass: '', confirmPass: '', email: '', token: ''});
    const [errors, setErrors] = useState<{pass: string, confirmPass: string}>({pass: '', confirmPass: ''});

    const handleReset = async () => {
        const minLength = validator.isLength(password.pass, {min: 8});
        const hasNumbers = validator.matches(password.pass, /\d/);
        const hasUppercase = validator.matches(password.pass, /[A-Z]/);
        const hasSpecialChars = validator.matches(password.pass, /[^A-Za-z0-9]/);

        if (!minLength || !hasNumbers || !hasUppercase || !hasSpecialChars) {
            setErrors((prev) => { return {...prev, pass:
                "Your password must be at least 8 characters long, include at least one uppercase letter, one number, and one special character."}});
        } else if (password.pass != password.confirmPass) {
            setErrors((prev) => {return {...prev, confirmPass: "Password and confirm password should be same"}});
        } else {
            const res = await vaticAxiosPost("/api/auth/v1/reset-password", {
                email: password.email,
                token: password.token,
                password: password.pass
            });
            if (res.statusCode === 200) {
                toast.success(res.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 1000,
                });
                navigate("/auth/login");
            } else {
                toast.error(res.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 1000,
                });
            }
        }
    }

    useEffect(() => {
        const parsedHash = queryString.parse(window.location.search);
        const token = Array.isArray(parsedHash["token"])
            ? parsedHash["token"][0]
            : parsedHash["token"];
        const email = Array.isArray(parsedHash["email"])
            ? parsedHash["email"][0]
            : parsedHash["email"];
        if (!token || !email) {
            toast.success('Invalid Request', {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 1000,
            });
        } else {
            const load = async () => {
                const res = await vaticAxiosGet('/api/auth/v1/is-valid-pass-reset-req', {
                    token: token,
                    email: email
                });
                if (res.is_valid) {
                    setPassword((prev) => {return {...prev, email: email, token: token}});
                } else if(res.is_valid === false) {
                    toast.error(`Unable to Reset your password , Either this link has been expired or Invalid`, {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 1000
                    });
                    navigate('/auth/login');
                } else {
                    toast.error(res.message, {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 1000
                    })
                }
            }
            load();
        }

    }, []);
    return (
        <StyledResetPassword>
            <div className="form-section">
                <label className="password" htmlFor="password">
                    Password *
                </label>
                <input
                    className={`rectangle-div ${errors.pass ? "error" : ""}`}
                    placeholder="Create a password"
                    type="password"
                    id="password"
                    name="password"
                    value={password.pass}
                    onChange={(e) => {
                        setPassword((prev) => {return {... prev, pass: e.target.value}});
                        setErrors({pass: '', confirmPass: ''});
                    }}
                />
                {errors.pass && (
                    <span className="error-message">{errors.pass}</span>
                )}
            </div>

            <div className="form-section">
                <label className="password" htmlFor="password">
                    Confirm Password *
                </label>
                <input
                    className={`rectangle-div ${errors.confirmPass ? "error" : ""}`}
                    placeholder="Create a password"
                    type="password"
                    id="password"
                    name="password"
                    value={password.confirmPass}
                    onChange={(e) => {
                        setPassword((prev) => {return {... prev, confirmPass: e.target.value}});
                        setErrors({pass: '', confirmPass: ''})
                    }}
                />
                {errors.confirmPass && (
                    <span className="error-message">{errors.confirmPass}</span>
                )}
            </div>

            <div className="continue-btn-container">
                <div className="continue-btn" onClick={handleReset}>
                    Reset
                </div>
            </div>
        </StyledResetPassword>
    )
}

export default ResetPassword;