import React, { useEffect, useState } from "react";
import StyledBody from "./styled";
import HomeReports from "../Home_reports";
import CampaignBody from "components/Home/Campaign/Body";
import Audience from "../Audience";
import AudiencePlatforms from "../Platform";
import { Route, Routes } from "react-router";
import FullStory from "react-fullstory";
import { FULLSTORY_ORG_ID } from "../../../utils/axios_helper";
import Estimate from "../Estimate";
import Explore from "../Explore";
import HomeCards from "../HomeCards";

interface BodyInterface {
  heading: String[];
  setHeading: (val: String[]) => void;
  activeTab: number;
}

const Body: React.FunctionComponent<BodyInterface> = ({
  heading,
  setHeading,
  activeTab,
}) => {
  const temp = [
    <HomeReports setHeading={setHeading} />,
    <Audience heading={heading} setHeading={setHeading} />,
    <CampaignBody setHeading={setHeading} heading={["Campaign"]} />,
    <AudiencePlatforms setHeading={setHeading} />,
    <Estimate setHeading={setHeading} />,
    <Explore setHeading={setHeading}></Explore>,
    <HomeCards setHeading={setHeading} />,
  ];

  return (
    <StyledBody>
      {/* {temp[activeTab - 1]} */}
      <Routes>
        <Route path="report/*" element={temp[0]} />
        <Route path="audience/*" element={temp[1]} />
        <Route path="campaign" element={temp[2]} />
        <Route path="platform" element={temp[3]} />
        <Route path="estimate/*" element={temp[4]}></Route>
        <Route path="explore/*" element={temp[5]} />
        <Route path="*" element={temp[6]} />
      </Routes>
      <div>
        <FullStory org={FULLSTORY_ORG_ID || ""} />
      </div>
    </StyledBody>
  );
};

export default Body;
