import React, { useState } from 'react';
import backImg from "assets/Back.svg";
import { useNavigate } from 'react-router';
import StyledForgot from './styled';
import FullStory from 'react-fullstory';
import {FULLSTORY_ORG_ID, vaticAxiosPost} from "../../../utils/axios_helper";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

interface ForgotFormValues {
  email: string;
}

const ForgotForm: React.FC = () => {
  const navigate = useNavigate();
  const [values, setValues] = useState<ForgotFormValues>({
    email: ''
  });
  const [errorMsg, setErrorMsg] = useState('');

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
    setErrorMsg('');
  };

  const handleSubmit = async () => {

    if (!values.email) {
      setErrorMsg('Please fill in all required fields.');
      return;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!emailRegex.test(values.email)) {
      setErrorMsg('Please enter a valid email address.');
      return;
    }

    const res = await vaticAxiosPost('/api/auth/v1/reset-password-send', {
      'email': values.email
    });

    if(res.statusCode == 200) {
      toast.success(res.message, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
      navigate("/auth/login");
    } else {
      toast.error(res.message, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    }
  };

  return (
      <StyledForgot>
        <div className='forgot-container'>
          <img src={backImg} alt="Back" className="back-ctn" onClick={() => window.history.back()}/>

          <b className="forgot-password">Forgot Password?</b>

          <div className="please-provide-the-container">
            <p className="please-provide-the">Please provide the email address associated with your account, and we will
              send you instructions on how to reset your password.</p>
            <p className="please-provide-the">&nbsp;</p>
            <p className="please-provide-the">As a measure of security, please note that we do not retain your password.
              Therefore, you can be confident that your password will not be transmitted via email.</p>
          </div>

            <div className='form-section'>
              <label className="email-title" htmlFor="email">Email *</label>
              <input
                  className="rectangle-div"
                  placeholder='name@work-email.com'
                  type="email"
                  id="email"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
              />
            </div>
          {
            errorMsg && (
                  <div className="error-msg">{errorMsg}</div>
              )
          }

            <div className='reset-btn-container' onClick={handleSubmit}>
              <div className='send-reset-password' >Send reset password instructions</div>
            </div>

        </div>
        <div>
          <FullStory org={FULLSTORY_ORG_ID || ''}/>
        </div>
      </StyledForgot>
  );
};

export default ForgotForm;
