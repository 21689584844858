import styled from 'styled-components'

const StyledHomeReports = styled.div`
    width: 960px;
    height:100vh;
    border: 1px solid #CDD1D8;
    border-radius:4px;

    .container{
        display:flex;
        flex-direction:column;
    }

    .option-tab{
        width: 77px;
        height:50px;
        position: relative;
        font-size: 16px;
        line-height: 24px;
        font-weight: 600;
        font-family: Inter;
        color:#82868f;
        text-align: center;
        display: flex;
        justify-content:center;
        align-items:center;
        cursor:pointer;
        margin-left:16px;
        cursor: pointer;
    }
    
    .selected-option-tab{
        border-bottom: 3px solid #4485f4;;
        color:#000;
        margin-left:16px;
    }

    .options-container{
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #cdd1d8;
        width:100%;
    }

    .day-dropdown-container{
        display:flex;
    }

    .line-chart{
        height:300px !important;
    }

    .t-container {
        margin: 24px;
    }

    .t-head {
        height: 40px;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        align-items: center;
        margin: 0px 24px;
    }

    .t-head-font {
        color: #5C5E62;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    .t-body {
        overflow-x: auto;
        overflow-y: auto;
        height: 300px;
    }

    .t-row {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        height: 48px;
        align-items: center;
        padding: 0px 24px;

    }

    .t-row-odd {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        height: 48px;
        background: #F7F7F7;
        align-items: center;
        padding: 0px 24px;

    }

    .delete-img {
        width: 28px;
        height: 28px;
        cursor: pointer;
    }

    .t-data1 {
        color: #3A76DC;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    .t-data2 {
        color: #000;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .t-data3 {
        color: #000;
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
    

    .hor-line {
        height: 1px;
        background: #E3E5E9;
    }

    .t-body::-webkit-scrollbar {
        width: 5px; /* Set the width of the scrollbar */
    }

    /* Track */
    .t-body::-webkit-scrollbar-track {
        background: #f1f1f1; /* Color of the scrollbar track */
    }

    /* Handle */
    .t-body::-webkit-scrollbar-thumb {
        background: #C5C9CE; /* Color of the scrollbar handle */
        border-radius: 6px; /* Rounded corners */
    }

    /* Handle on hover */
    .t-body::-webkit-scrollbar-thumb:hover {
        background: #555; /* Color of the scrollbar handle on hover */
    }

`

export default StyledHomeReports;