import React, { useEffect, useState } from "react";
import StyledCreateCampaign from "./styled";
import fb_icon from "assets/platform_icons/facebook-icon.svg";
import x_icon from "assets/platform_icons/x-icon.svg";
import google_social_icon from "assets/platform_icons/google-social-icon.svg";
import snapchat_icon from "assets/platform_icons/snapchat-icon.png";
import tiktok_icon from "assets/platform_icons/tiktok-icon.svg";
import connected_icon from "assets/platform_icons/connected-icon.svg";
import empty_circle from "assets/campaign/empty-circle.svg";
import FullStory from "react-fullstory";
import {FULLSTORY_ORG_ID} from "../../../../utils/axios_helper";

interface CreateCampaignInterface {
    setHeading: (val: String[]) => void;
}

const CreateCampaign: React.FunctionComponent<CreateCampaignInterface> = ({ setHeading }) => {
    const [stepNo, setStepNo] = useState(1);
    const [campaignName, setCampaignName] = useState<String>();
    const [clientName, setClientName] = useState<String>();
    const [startDate, setStartDate] = useState<String>();
    const [endDate, setEndDate] = useState<String>();
    const [region, setRegion] = useState<String>();
    const [age, setAge] = useState<String>();
    const [gender, setGender] = useState<String>();
    const [selectedRegion, setSelectedRegion] = useState<String>();

    useEffect(() => {
        setHeading(['Campaign', 'New Campaign']);
    }, []);

    return (
        <StyledCreateCampaign>
            <div className="ctn1">
                <div>
                    <div className="ctn1-row">
                        <div className={stepNo >= 1 ? "num-circle-active" : "num-circle"}>1</div>
                        <div className={stepNo >= 1 ? "circle-title-active" : "circle-title"}>Set up</div>
                    </div>
                    {
                        (campaignName || clientName || startDate || endDate) && <div className="entered-data">
                            <div>{campaignName}</div>
                            <div>{clientName}</div>
                            <div>{startDate} {endDate}</div>
                        </div>
                    }
                </div>
                <div className="hor-line"></div>
                <div>
                    <div className="ctn1-row">
                        <div className={stepNo >= 2 ? "num-circle-active" : "num-circle"}>2</div>
                        <div className={stepNo >= 2 ? "circle-title-active" : "circle-title"}>Audience</div>
                    </div>
                    {
                        (region || age || gender || selectedRegion) &&
                        <div className="entered-data">
                            <div>{region}</div>
                            <div>{age}</div>
                            <div>{gender}</div>
                            <div>{selectedRegion}</div>
                        </div>}
                </div>
                <div className="hor-line"></div>
                <div className="ctn1-row">
                    <div className={stepNo >= 3 ? "num-circle-active" : "num-circle"}>3</div>
                    <div className={stepNo >= 3 ? "circle-title-active" : "circle-title"}>Platforms</div>
                </div>
                <div className="hor-line"></div>
                <div className="ctn1-row">
                    <div className={stepNo >= 4 ? "num-circle-active" : "num-circle"}>4</div>
                    <div className={stepNo >= 4 ? "circle-title-active" : "circle-title"}>Publish</div>
                </div>
            </div>
            {stepNo === 1 &&
                <div className="ctn2">
                    <div className="ctn2-heading">Set up your new campaign for Thailand</div>
                    <div className="hor-line"></div>
                    <div className="ctn2-body">
                        <div className="ctn2-body-row">
                            <div className="t1">Campaign name *</div>
                            <input type="text" className="t1-input" placeholder="Compaign name" onChange={(event) => {
                                setCampaignName(event.target.value)
                            }}/>
                        </div>
                        <div className="ctn2-body-row m-16">
                            <div className="t1">Client name *</div>
                            <input type="text" className="t1-input" placeholder="Client name" onChange={(event) => {
                                setClientName(event.target.value)
                            }}/>
                        </div>
                        <div className="ctn2-body-row2 m-16">
                            <div className="ctn2-body-row">
                                <div className="t1">Start Date *</div>
                                <input type="date" className="t1-input" placeholder="Client name" onChange={(event) => {
                                    setStartDate(event.target.value)
                                }}/>
                            </div>
                            <div className="ctn2-body-row">
                                <div className="t1">End Date *</div>
                                <input type="date" className="t1-input" placeholder="Client name" onChange={(event) => {
                                    setEndDate(event.target.value)
                                }}/>
                            </div>
                        </div>
                        <div className="next-btn" onClick={() => setStepNo(2)}>Next</div>
                    </div>
                </div>
            }

            {stepNo === 2 &&
                <div className="ctn2">
                    <div className="grid">
                        <div className="ctn2-heading h-active">New audience</div>
                        <div className="ctn2-h1">Select audience</div>
                    </div>
                    <div className="hor-line"></div>
                    <div className="ctn2-body">
                        <div className="ctn2-body-row">
                            <div className="t1">Region *</div>
                            <select className="reg-select" onChange={(event) => {
                                setRegion(event.target.value)
                            }}>
                                <option className="reg-option" value={"Thailand"}>Thailand</option>
                                <option className="reg-option" value={"Thailand"}>Thailand</option>
                            </select>
                        </div>
                        <div className="ctn2-body-row2 m-16">
                            <div className="ctn2-body-row">
                                <div className="t1">Age *</div>
                                <select className="t1-input" onChange={(event) => {
                                    setAge(event.target.value)
                                }}>
                                    <option value="18-35" className="reg-option">18-35</option>
                                    <option value="18-35" className="reg-option">18-35</option>
                                </select>
                            </div>
                            <div className="ctn2-body-row">
                                <div className="t1">Gender *</div>
                                <select className="t1-input" onChange={(event) => {
                                    setGender(event.target.value)
                                }}>
                                    <option value="All" className="reg-option">All</option>
                                    <option value="All" className="reg-option">All</option>
                                </select>
                            </div>
                        </div>
                        <div className="ctn2-body-row m-16">
                            <div className="t1">Select signal *</div>
                            <select className="select-region" onChange={(event) => {
                                setSelectedRegion(event.target.value)
                            }}>
                                <option className="reg-option" value={'Thailand'}>Thailand</option>
                                <option className="reg-option" value={'Thailand'}>Thailand</option>
                            </select>
                        </div>
                        <div className="next-btn-disabled" onClick={() => setStepNo(3)}>Next</div>
                    </div>
                </div>
            }

            {stepNo === 3 &&
                <div className="ctn2">
                    <div className="ctn2-heading">Select platforms for your campaign</div>
                    <div className="hor-line"></div>

                    <div className="platform-box">
                        <div className="rectangle-div">
                            <div className="platform-container">
                                <img className="platform-icon" src={fb_icon}></img>
                                <div className="platform-meta-container">
                                    <div className="platform-name">Facebook</div>
                                    <div className="user-name">@username</div>
                                </div>
                            </div>

                            <div className="platform-connect-container">
                                <div className="connected">CONNECTED</div>
                                <img src={connected_icon}></img>
                            </div>
                        </div>

                        <div className="rectangle-div">
                            <div className="platform-container">
                                <img className="platform-icon" src={x_icon}></img>
                                <div className="platform-meta-container">
                                    <div className="platform-name">X</div>
                                    <div className="user-name">@username</div>
                                </div>
                            </div>

                            <div className="platform-connect-container">
                                <div className="connected"></div>
                                <img src={empty_circle}></img>
                            </div>
                        </div>

                        <div className="rectangle-div">
                            <div className="platform-container">
                                <img className="platform-icon" src={google_social_icon}></img>
                                <div className="platform-meta-container">
                                    <div className="platform-name">Google</div>
                                    <div className="user-name">@username</div>
                                </div>
                            </div>
                            <div className="platform-connect-container">
                                <div className="connect">CONNECT</div>
                                {/* <div className="connected">CONNECTED</div>  */}
                                {/* <img src={connected_icon}></img> */}
                            </div>
                        </div>

                        <div className="rectangle-div">
                            <div className="platform-container">
                                <img className="platform-icon" src={snapchat_icon}></img>
                                <div className="platform-meta-container">
                                    <div className="platform-name">Snapchat</div>
                                    <div className="user-name">@username</div>
                                </div>
                            </div>

                            <div className="platform-connect-container">
                                <div className="connect">CONNECT</div>
                                {/* <div className="connected">CONNECTED</div>  */}
                                {/* <img src={connected_icon}></img> */}
                            </div>
                        </div>

                        <div className="rectangle-div">
                            <div className="platform-container">
                                <img className="platform-icon" src={tiktok_icon}></img>
                                <div className="platform-meta-container">
                                    <div className="platform-name">TikTok</div>
                                    <div className="user-name">@username</div>
                                </div>
                            </div>

                            <div className="platform-connect-container">
                                <div className="connect">CONNECT</div>
                                {/* <div className="connected">CONNECTED</div>  */}
                                {/* <img src={connected_icon}></img> */}
                            </div>
                        </div>
                        <div className="next-btn" onClick={() => setStepNo(4)}>Next</div>
                    </div>
                </div>
            }

            {stepNo === 4 &&
                <div className="ctn2">
                    <div className="ctn2-heading">Set up your new campaign for Thailand</div>
                    <div className="hor-line"></div>
                    <div className="ctn-summary">
                        <div className="next-btn">Next</div>
                    </div>
                </div>}
            <div>
                <FullStory org={FULLSTORY_ORG_ID || ''}/>
            </div>
        </StyledCreateCampaign>
    )
}

export default CreateCampaign;

