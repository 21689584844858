import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router";
import { RootState } from "services/store";
import { vaticAxiosGet } from "../../utils/axios_helper";
import { set as setlogin } from "../../services/reducer/login";

interface AuthenticateInterface {
  component: any;
}
const Authenticate: React.FunctionComponent<AuthenticateInterface> = ({
  component,
}) => {
  const isAuthenticated = useSelector(
    (state: RootState) => state.login.is_authenticated
  );
  const dispatch = useDispatch();

  useEffect(() => {
    const load = async () => {
      try {
        let resp = await vaticAxiosGet("/api/auth/v1/me");
        if (resp && resp.data) {
          dispatch(
            setlogin({
              first_name: resp.data.first_name,
              is_authenticated: true,
              img_url: resp.data.img_url,
              organization: resp.data.organization
            })
          );
        }
      } catch {
        dispatch(setlogin({ is_authenticated: false }));
      }
    };

    load();
  }, []);

  return isAuthenticated ? component : <Navigate to="/auth/login" />;
};

export default Authenticate;
