import styled from "styled-components";

const StyledCreateEstimate = styled.div`
  width: 100%;
  .container {
    display: grid;
    grid-template-columns: 1fr 3fr;
  }
`;

export default StyledCreateEstimate;
