import React, { useEffect, useState } from "react";
import StyledSignalCategory from "./styled";
import { vaticAxiosGet } from "utils/axios_helper";
import { useSelector } from "react-redux";
import { RootState } from "services/store";

import upArror from "assets/toggle-up-arrow.svg";
import downArrow from "assets/toggle-down-arrow.svg";

interface SingalCategoryProps {
  parentId: number;
  level: number;
  categoryName: string;
  handleSignalSelect: (segment_id: number) => void;
  currAudienceId: number;
  setCurrAudienceId: (audienceId: number) => void;
  setOpenParent: (val: boolean) => void;
}
const SignalCategory: React.FunctionComponent<SingalCategoryProps> = ({
  parentId,
  level,
  categoryName,
  handleSignalSelect,
  currAudienceId,
  setCurrAudienceId,
  setOpenParent
}) => {
  const [signalCategory, setSignalCategory] = useState<
    Array<{
      id: number;
      name: string;
      level: number;
      parent_level_id: number;
    }>
  >();
  const countryDb = useSelector((state: RootState) => state.home.countryDb);
  const [open, setOpen] = useState(parentId == 0);
  const [signals, setSignals] = useState<Array<any>>();

  useEffect(() => {
    const load = async () => {
      const res1 = await vaticAxiosGet("/api/v1/signals_category", {
        country: countryDb,
        level: level,
        parent_id: parentId,
      });
      if (res1 && res1.data) {
        setSignalCategory(res1.data);
      }

      const res = await vaticAxiosGet("/api/v1/signal_data/" + parentId, {
        db: countryDb,
      });
      if (res && res.data) {
        setSignals(res.data);
      }
    };
    load();
  }, []);

  return (
    <StyledSignalCategory>
      <div onClick={() => setOpen((prev) => !prev)} className="head">
        <span className="text">{categoryName}</span>
        <span>
          {open ? (
            <img src={upArror} className="img" />
          ) : (
            <img src={downArrow} className="img" />
          )}
        </span>
      </div>
      {open && (
        <div>
          {signalCategory?.map((item, ind) => (
            <SignalCategory
              parentId={item.id}
              level={level + 1}
              categoryName={item.name}
              handleSignalSelect={handleSignalSelect}
              currAudienceId={currAudienceId}
              setCurrAudienceId={setCurrAudienceId}
              setOpenParent={setOpenParent}
            />
          ))}

          {signals?.map((item, ind) => (
            <div
              onClick={() => {
                setCurrAudienceId(item.id);
                handleSignalSelect(item.id);
                setOpenParent(false);
              }}
              key={ind}
              className={
                currAudienceId == item.id ? "signal-name active" : "signal-name"
              }
            >
              {item.name}
            </div>
          ))}
        </div>
      )}

      {open && <div></div>}
    </StyledSignalCategory>
  );
};

export default SignalCategory;
