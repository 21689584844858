import google_logo from "assets/SignUp/google-logo.png";
import queryString from "query-string";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { set } from "../../../services/reducer/login";
import { set as platformSet } from "services/reducer/platform";
import {
  FULLSTORY_ORG_ID,
  getGoogleUserInfo,
  googleSignin,
  vaticAxiosGet,
  vaticAxiosPost,
} from "utils/axios_helper";
import StyledLogin from "./styled";
import FullStory from "react-fullstory";

interface LoginInterface {}

const Login: React.FunctionComponent<LoginInterface> = ({}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({
    email: "",
    password: "",
  });

  const validateForm = () => {
    let valid = true;
    const newErrors = {
      email: "",
      password: "",
    };

    if (!email) {
      newErrors.email = "Email is required";
      valid = false;
    }
    if (!password) {
      newErrors.password = "Password is required";
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  const dataFromGoogle = async (access_token: string) => {
    const data = await getGoogleUserInfo(access_token);
    const userEmail = data.email;
    const userFirstName = data.given_name;
    if (userEmail) {
      const userInfo = await vaticAxiosPost("/api/auth/v1/login-by-google", {
        email: userEmail,
      });
      if (userInfo && userInfo.statusCode == 200) {
        dispatch(
          set({
            user_id: userInfo.data.user_id,
            access_token: userInfo.data.accessToken,
            is_authenticated: true,
            first_name: userFirstName,
          })
        );
        toast.success(userInfo.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        navigate("/dashboard");
      } else {
        toast.error(`${userInfo.message}`, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      }
    } else {
      toast.error("Unable to log in using google account", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    }
  };

  useEffect(() => {
    const parsedHash = queryString.parse(window.location.hash);
    const accessToken = Array.isArray(parsedHash["access_token"])
      ? parsedHash["access_token"][0]
      : parsedHash["access_token"];
    if (accessToken) {
      dataFromGoogle(accessToken);
    }
  }, []);

  const authenticate = async () => {
    const isValid = validateForm();

    if (isValid) {
      const res = await vaticAxiosPost("/api/auth/v1/login", {
        email,
        password,
      });
      if (res && res.statusCode === 200) {
        dispatch(
          set({
            user_id: res.data.user_id,
            access_token: res.data.accessToken,
            is_authenticated: true,
            first_name: res.data.user_id,
          })
        );
        toast.success(res.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        navigate("/dashboard");
      } else {
        toast.error(res.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      }
    }
  };

  return (
      <StyledLogin>
        <div className="login-container">
          <b className="login">Login</b>

          <div className="form-section">
            <label className="email-title" htmlFor="email">
              Email *
            </label>
            <input
                className={`rectangle-div ${errors.email ? "error" : ""}`}
                placeholder="name@work-email.com"
                type="email"
                id="email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
            />
            {errors.email && (
                <span className="error-message">{errors.email}</span>
            )}
          </div>

          <div className="form-section">
            <div className="password-container">
              <label className="password" htmlFor="password">
                Password *
              </label>
              <div
                  className="forgot"
                  onClick={() => navigate("/auth/forgot-password")}
              >
                Forgot?
              </div>
            </div>
            <input
                className={`rectangle-div ${errors.password ? "error" : ""}`}
                placeholder="Enter password"
                type="password"
                id="password"
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
            />
            {errors.password && (
                <span className="error-message">{errors.password}</span>
            )}
          </div>

          <div className="login-btn-container">
            <button className="login-btn" onClick={authenticate}>
              Login
            </button>
          </div>

          <div className="orline">
            <div className="line-div"></div>
            <div className="or">OR</div>
            <div className="line-div"></div>
          </div>

          <div className="google-signup-btn-container" onClick={googleSignin}>
            <img className="google-icon" alt="google-login" src={google_logo}/>
            <div className="continue-with-google">Login with Google</div>
          </div>

          <div className="dont-have-an-container">
            <span>Don't have an account? </span>
            <span className="sign-up" onClick={() => navigate("/auth/signup")}>
            Sign up
          </span>
          </div>

          <div className="privacy"><span className='privacy-text'
                                         onClick={() => navigate('/privacy')}>PRIVACY POLICY</span></div>
        </div>
        <div>
          <FullStory org={FULLSTORY_ORG_ID || ''}/>
        </div>
      </StyledLogin>
  );
};

export default Login;
