import React, { useEffect, useState } from "react";
import StyledCampaignDetail from "./styled";
import plusImg from "assets/campaign/plus.svg";
import dropArrow from "assets/campaign/bottomArrow.svg";
import deleteImg from "assets/campaign/delete.svg";
import extendImg from "assets/campaign/extend.svg";
import emptyImg from "assets/campaign/empty.svg";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "services/store";
import { FULLSTORY_ORG_ID, vaticAxiosGet } from "utils/axios_helper";
import FullStory from "react-fullstory";
import { set } from "../../../../services/reducer/home";

interface CampaignDetailInterface {
  setShowCampaignDetailsPage: (val: boolean) => void;
}

interface campaignInterface {
  id: number;
  audience_id: number;
  name: string;
  platform: string;
  created_at: any;
}

const CampaignDetail: React.FunctionComponent<CampaignDetailInterface> = ({
  setShowCampaignDetailsPage,
}) => {
  const homeStore = useSelector((state: RootState) => ({
    campaigns: state.home.campaigns,
    country: state.home.country,
  }));
  const dispatch = useDispatch();
  const [campaigns, setCampaigns] = useState<Array<campaignInterface>>(
    homeStore.campaigns
  );
  const userId = useSelector((state: RootState) => state.login.user_id);

  useEffect(() => {
    const load = async () => {
      const res = await vaticAxiosGet("/api/v1/ads/audience-campaign-info", {
        user_id: userId,
      });
      if (res && res.data) {
        setCampaigns(res.data);
        dispatch(set({ campaigns: res.data }));
      }
    };

    load();
  }, []);

  // const campaignData: campaignDataInterface[] = [];
  return (
    <StyledCampaignDetail>
      <div className="head">
        {/* <div className="create-btn-ctn" onClick={() => { setShowCampaignDetailsPage(false) }}>
                    <img src={plusImg} alt="+" className="plus" />
                    <div className="plus-text">New Campaign</div>
                </div> */}
        {/* <div className="drop-down-ctn">
                    <div className="drop-down-opt">Sort by: Most recent</div>
                    <img src={dropArrow} alt="v" className="drop-down-arrow" />
                </div> */}
      </div>
      <div className="hor-line"></div>
      <div className="t-container">
        <div className="t-head">
          <div className="t-head-font">Action</div>
          <div className="t-head-font">Name</div>
          <div className="t-head-font">Start</div>
          <div className="t-head-font">Platforms</div>
        </div>
        <div className="hor-line"></div>
        {campaigns.length > 0 && (
          <div className="t-body">
            {campaigns.map((val, ind) => (
              <div className={ind % 2 === 0 ? "t-row-odd" : "t-row"} key={ind}>
                <img
                  className="t-data delete-img"
                  src={deleteImg}
                  alt="delete"
                />
                <div className="t-data1">{val.name}</div>
                <div className="t-data3">{val.created_at}</div>
                <div className="t-data2">{val.platform}</div>
              </div>
            ))}
          </div>
        )}

        {campaigns.length == 0 && (
          <div className="t-body-empty">
            <img src={emptyImg} alt="?" className="empty-img" />
            <div className="empty-h1">
              No campaigns for {homeStore.country} yet
            </div>
            <div className="empty-text">
              Create your first campaign by clicking the button above.
            </div>
          </div>
        )}
      </div>
      <div>
        <FullStory org={FULLSTORY_ORG_ID || ""} />
      </div>
    </StyledCampaignDetail>
  );
};

export default CampaignDetail;
