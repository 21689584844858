import styled from "styled-components";

const StyledVerify = styled.div`

    margin: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export default StyledVerify;