import React, { useEffect, useState } from "react";
import StyledPlatform from "./styled";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { set } from "services/reducer/audience";
import { RootState } from "services/store";
import queryString from "query-string";
import {
  REACT_APP_BASE_URL,
  getMetaAccessToken,
  vaticAxiosGet,
  vaticAxiosPost,
} from "utils/axios_helper";
import {
  google_ads_cred,
  meta_ads_cred,
  set as platformSet,
  tiktok_ads_cred,
  x_ads_cred,
} from "services/reducer/platform";
import fb_icon from "assets/platform_icons/facebook-icon.svg";
import google_social_icon from "assets/platform_google-1.svg";
import connected_icon from "assets/platform_icons/connected-icon.svg";
import x_icon from "assets/platform_x.svg";
import tiktok_icon from "assets/platform_icons/tiktok-icon.svg";

interface Props {
  setHeading: (val: String[]) => void;
}

const Platform: React.FunctionComponent<Props> = ({setHeading}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const metaState = process.env.REACT_APP_META_STATE;

  const audience = useSelector((state: RootState) => ({
    method: state.audience.method,
    metaAuthUrl: state.audience.metaAuthUrl,
    googleAuthUrl: state.audience.googleAuthUrl,
    platform_type: state.audience.platform_type,
    selectedGoogleAdAcc: state.audience.selected_google_ad_acc,
    selectedMetaAdAcc: state.audience.selected_meta_ad_acc,
    xAuthUrl: state.audience.xAuthUrl,
    selectedXAdAcc: state.audience.selected_x_ad_acc,
    tiktokAuthUrl: state.audience.tiktokAuthUrl,
    selectedTiktokAdAcc: state.audience.selected_tiktok_ad_acc,
  }));

  const countryDb = useSelector((state: RootState) => state.home.countryDb);
  const platformInfo = useSelector((state: RootState) => ({
    google: state.platform.google,
    meta: state.platform.meta,
    x: state.platform.x,
    tiktok: state.platform.tiktok,
  }));

  const [error, setError] = useState("");
  const country = useSelector((state: RootState) => state.home.country);

  useEffect(() => {
    const currentUrlSearchParams = queryString.parse(location.search);

    const google_scope = Array.isArray(currentUrlSearchParams["scope"])
      ? currentUrlSearchParams["scope"][0]
      : currentUrlSearchParams["scope"];
    const code = Array.isArray(currentUrlSearchParams["code"])
      ? currentUrlSearchParams["code"][0]
      : currentUrlSearchParams["code"];
    const state = Array.isArray(currentUrlSearchParams["state"])
      ? currentUrlSearchParams["state"][0]
      : currentUrlSearchParams["state"];

    const oauthVerifier = Array.isArray(currentUrlSearchParams["oauth_verifier"])
      ? currentUrlSearchParams["oauth_verifier"][0]
      : currentUrlSearchParams["oauth_verifier"];

    const oauthToken = Array.isArray(currentUrlSearchParams["oauth_token"])
      ? currentUrlSearchParams["oauth_token"][0]
      : currentUrlSearchParams["oauth_token"];

    const auth_code = Array.isArray(currentUrlSearchParams["auth_code"])
    ? currentUrlSearchParams["auth_code"][0]
    : currentUrlSearchParams["auth_code"];

    if (google_scope && code) {
      const loadGoogleAccessTok = async () => {
        const res = await vaticAxiosGet("/api/v1/ads/google/refresh-token", {
          code: code,
          redirect_uri:
            REACT_APP_BASE_URL + "/dashboard/audience/create/platforms",
          db: countryDb,
          platform_type: audience.platform_type,
        });
        const load = async () => {
          const googleConnectedAccRes = await vaticAxiosGet(
            "/api/v1/ads/google/connected-accounts"
          );
          if (googleConnectedAccRes && googleConnectedAccRes.data) {
            dispatch(platformSet({ google: googleConnectedAccRes.data }));
          }
        };
        if (res && res.statusCode === 200) load();
      };
      loadGoogleAccessTok();
    } else if (state === metaState) {
      const loadMetaAccessToken = async () => {
        await getMetaAccessToken(
          code || "",
          countryDb,
          "/dashboard/audience/create/platforms"
        );
        const load = async () => {
          const metaConnectedAccRes = await vaticAxiosGet(
            "/api/v1/ads/meta/connected-accounts"
          );
          if (metaConnectedAccRes && metaConnectedAccRes.data) {
            dispatch(platformSet({ meta: metaConnectedAccRes.data }));
          }
        };
        load();
      };
      loadMetaAccessToken();
    } else if(oauthToken && oauthVerifier) {
      const loadXAccessToken = async () => {
        await vaticAxiosPost("/api/v1/ads/x/access-token", {
          oauth_verifier: oauthVerifier,
          oauth_token: oauthToken,
          token_secret: localStorage.getItem('xAuthSecret') || '',
          db: countryDb,
        });
        const load = async () => {
          const xConnectedAccRes = await vaticAxiosGet(
            "/api/v1/ads/x/connected-accounts"
          );
          if (xConnectedAccRes && xConnectedAccRes.data) {
            dispatch(platformSet({ x: xConnectedAccRes.data }));
          }
        };
        load();
      };
      loadXAccessToken();
    } else if(auth_code) {
      const loadTiktokAccessToken = async () => {
        await vaticAxiosPost("/api/v1/ads/tiktok/access-token", {
          auth_code: auth_code,
          code: code,
          db: countryDb,
        });
        const load = async () => {
          const tiktokConnectedAccRes = await vaticAxiosGet(
            "/api/v1/ads/tiktok/connected-accounts"
          );
          if (tiktokConnectedAccRes && tiktokConnectedAccRes.data) {
            dispatch(platformSet({ tiktok: tiktokConnectedAccRes.data }));
          }
        };
        load();
      };
      loadTiktokAccessToken();
    }
  }, [location]);

  const handleGoogleAdAccSel = (e: any) => {
    let temp = { ...audience.selectedGoogleAdAcc };
    const val = parseInt(e.target.value);
    if (val in temp) {
      temp = { ...temp, [val]: !temp[val] };
    } else {
      temp = { ...temp, [val]: true };
    }
    dispatch(set({ selected_google_ad_acc: temp }));
  };

  const handleMetaAdAccSel = (e: any) => {
    let temp = { ...audience.selectedMetaAdAcc };
    const val = e.target.value;
    if (val in temp) {
      temp = { ...temp, [val]: !temp[val] };
    } else {
      temp = { ...temp, [val]: true };
    }
    dispatch(set({ selected_meta_ad_acc: temp }));
  };

  const handleXAdAccSel = (e: any) => {
    let temp = { ...audience.selectedXAdAcc };
    const val = e.target.value;
    if (val in temp) {
      temp = { ...temp, [val]: !temp[val] };
    } else {
      temp = { ...temp, [val]: true };
    }
    dispatch(set({ selected_x_ad_acc: temp }));
  };

  const handleTiktokAdAccSel = (e: any) => {
    let temp = { ...audience.selectedTiktokAdAcc };
    const val = e.target.value;
    if (val in temp) {
      temp = { ...temp, [val]: !temp[val] };
    } else {
      temp = { ...temp, [val]: true };
    }
    dispatch(set({ selected_tiktok_ad_acc: temp }));
  };


  useEffect(() => {
    dispatch(set({ activeTab: 0 }));
    setHeading(["Audience", country.charAt(0) + country.substring(1).toLowerCase(), "Create", "Platform"]);
  }, []);

  return (
    <StyledPlatform>
      <div className="container">
        <div className="platforms">Platforms</div>

        <div className="connect-and-choose">
          Connect and choose platform for your audience.
        </div>
        <div className="select-platforms-1-container">
          <span>Select platforms </span>
          <span className="span">(1)</span>
        </div>

        <div className="rectangle-div">
          <div className="platform-container">
            <div className="platform-icon-container">
              <img className="platform-icon" src={fb_icon}></img>
            </div>

            <div className="w-100">
              <div className="platform-google-container">
                <div className="platform-name">
                  <div>
                    {" "}
                    Facebook Ads Account <sup className="superscript">
                      1{" "}
                    </sup>{" "}
                    <span className="asterisk">&nbsp;*</span>
                  </div>
                </div>

                <div
                  className="platform-connect-container"
                  onClick={() => {
                    const temp = localStorage.getItem("new_audience") || "{}";
                    const obj = JSON.parse(temp);

                    const new_obj = { ...obj, active_tab: 2 };
                    localStorage.setItem(
                      "new_audience",
                      JSON.stringify(new_obj)
                    );
                  }}
                >
                  <a className="connect" href={audience.metaAuthUrl}>
                    CONNECT
                  </a>
                </div>
              </div>

              {platformInfo.meta.map((val: meta_ads_cred, ind) => (
                <div className="platform-google-container" key={ind}>
                  <div className="user-name" key={ind}>
                    <input
                      type="checkbox"
                      value={val.id}
                      onClick={handleMetaAdAccSel}
                      checked={audience.selectedMetaAdAcc[val.id]}
                    />
                    @{val.name}
                  </div>
                  <div key={ind} className="connected-ctn">
                    <div className="connected">CONNECTED</div>
                    <img src={connected_icon} className="connected-icon"></img>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="rectangle-div">
          <div className="platform-container">
            <div className="platform-icon-container">
              <img className="platform-icon" src={google_social_icon}></img>
            </div>

            <div className="w-100">
              <div className="platform-google-container">
                <div className="platform-name">
                  <div>
                    {" "}
                    Google Ads Account <sup className="superscript">
                      1{" "}
                    </sup>{" "}
                    <span className="asterisk">&nbsp;*</span>
                  </div>
                </div>

                <div
                  className="platform-connect-container"
                  onClick={() => {
                    const temp = localStorage.getItem("new_audience") || "{}";
                    const obj = JSON.parse(temp);

                    const new_obj = { ...obj, active_tab: 2 };
                    localStorage.setItem(
                      "new_audience",
                      JSON.stringify(new_obj)
                    );
                  }}
                >
                  <a className="connect" href={audience.googleAuthUrl}>
                    CONNECT
                  </a>
                </div>
              </div>

              {platformInfo.google.map((val: google_ads_cred, ind) => (
                <div className="platform-google-container" key={ind}>
                  <div className="user-name" key={ind}>
                    <input
                      type="checkbox"
                      value={val.id}
                      onClick={handleGoogleAdAccSel}
                      checked={audience.selectedGoogleAdAcc[val.id]}
                    />
                    @{val.name}
                  </div>
                  <div key={ind} className="connected-ctn">
                    <div className="connected">CONNECTED</div>
                    <img src={connected_icon} className="connected-icon"></img>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="rectangle-div">
          <div className="platform-container">
            <div className="platform-icon-container">
              <img className="platform-icon" src={x_icon}></img>
            </div>

            <div className="w-100">
              <div className="platform-google-container">
                <div className="platform-name">
                  <div>
                    {" "}
                    Twitter(X) <sup className="superscript">
                      1{" "}
                    </sup>{" "}
                    <span className="asterisk">&nbsp;*</span>
                  </div>
                </div>

                <div
                  className="platform-connect-container"
                  onClick={() => {
                    const temp = localStorage.getItem("new_audience") || "{}";
                    const obj = JSON.parse(temp);

                    const new_obj = { ...obj, active_tab: 2 };
                    localStorage.setItem(
                      "new_audience",
                      JSON.stringify(new_obj)
                    );
                  }}
                >
                  <a className="connect" href={audience.xAuthUrl}>
                    CONNECT
                  </a>
                </div>
              </div>

              {platformInfo.x.map((val: x_ads_cred, ind) => (
                <div className="platform-google-container" key={ind}>
                  <div className="user-name" key={ind}>
                    <input
                      type="checkbox"
                      value={val.id}
                      onClick={handleXAdAccSel}
                      checked={audience.selectedXAdAcc[val.id]}
                    />
                    @{val.name}
                  </div>
                  <div key={ind} className="connected-ctn">
                    <div className="connected">CONNECTED</div>
                    <img src={connected_icon} className="connected-icon"></img>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="rectangle-div">
          <div className="platform-container">
            <div className="platform-icon-container">
              <img className="platform-icon" src={tiktok_icon}></img>
            </div>

            <div className="w-100">
              <div className="platform-google-container">
                <div className="platform-name">
                  <div>
                    {" "}
                    Tik Tok <sup className="superscript">
                      1{" "}
                    </sup>{" "}
                    <span className="asterisk">&nbsp;*</span>
                  </div>
                </div>

                <div
                  className="platform-connect-container"
                  onClick={() => {
                    const temp = localStorage.getItem("new_audience") || "{}";
                    const obj = JSON.parse(temp);

                    const new_obj = { ...obj, active_tab: 2 };
                    localStorage.setItem(
                      "new_audience",
                      JSON.stringify(new_obj)
                    );
                  }}
                >
                  <a className="connect" href={audience.tiktokAuthUrl}>
                    CONNECT
                  </a>
                </div>
              </div>

              {platformInfo.tiktok.map((val: tiktok_ads_cred, ind) => (
                <div className="platform-google-container" key={ind}>
                  <div className="user-name" key={ind}>
                    <input
                      type="checkbox"
                      value={val.id}
                      onClick={handleTiktokAdAccSel}
                      checked={audience.selectedTiktokAdAcc[val.id]}
                    />
                    @{val.name}
                  </div>
                  <div key={ind} className="connected-ctn">
                    <div className="connected">CONNECTED</div>
                    <img src={connected_icon} className="connected-icon"></img>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="asterisk-text">
          {" "}
          <span className="asterisk1"> * </span>
          <span>
            When you provide consent through connecting your google account our
            platform will use it to obtain list of campaigns and then upload a
            user-list to your google ads platform and then attach it to the
            campaign of your choice.
          </span>
        </div>
        <div className="subscript">
          <sup>1 </sup>
          If you don't have a google ad account, go open one and then return
          here to connect it.
        </div>

        {error && <div className="error-msg">{error}</div>}
        <div className="continue-btn-container">
          <div
            className="continue-btn"
            onClick={() => {
              if (
                platformInfo.google.filter(i => audience.selectedGoogleAdAcc[i.id]).length + 
                platformInfo.meta.filter(i => audience.selectedMetaAdAcc[i.id]).length + 
                platformInfo.x.filter(i => audience.selectedXAdAcc[i.id]).length + 
                platformInfo.tiktok.filter(i => audience.selectedTiktokAdAcc[i.id]).length
                 === 0) {
                setError("You must connect atleast one Ad account");
                return;
              }
              navigate("/dashboard/audience/create/campaign");
            }}
          >
            Continue
          </div>
        </div>
      </div>
    </StyledPlatform>
  );
};

export default Platform;
