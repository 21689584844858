import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { set } from "services/reducer/new_audience";
import StyledExplore from "./styled";
import { vaticAxiosGet } from "utils/axios_helper";
import { RootState } from "services/store";
import BarChart from "components/Chart/BarChart";
import SignalCategory from "components/SignalCategory";
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import Histogram from "components/Chart/Histogram";

import Modal from "react-modal";
import BullsEye from "components/Chart/BullsEye";
import StackedBarChart from "components/Chart/StackedBarChart";
import CompareBarChart from "components/Chart/CompareBarChart";
import downArrow from "assets/toggle-down-arrow.svg";
import { useLocation, useNavigate } from "react-router-dom";
import audience from "services/reducer/audience";
import estimate from "services/reducer/estimate";

interface BodyInterface {
  setHeading: (val: string[]) => void;
}

interface SignalInterface {
  id: number;
  name: string;
  audienceScore: number;
  normScore: number;
  graph_label: string;
}

interface DimensionInterface {
  id: number;
  name: string;
  audienceScore: number;
  normScore: number;
  graph_label: string;
  selected: boolean;
}

const Explore: React.FunctionComponent<BodyInterface> = ({ setHeading }) => {
  const nav = useNavigate();
  const [signals, setSignals] = useState<Array<SignalInterface>>([]);
  const [signalScore, setSignalScore] = useState<Array<DimensionInterface>>([]);
  const [filteredDimensions,  setFilteredDimensions] = useState<{[key: number]: boolean}>({});

  const countryDb = useSelector((state: RootState) => state.home.countryDb);
  const selectedCountry = useSelector((state: RootState) => state.home.country);
  const userId = useSelector((state: RootState) => state.login.user_id);
  const dispatch = useDispatch();

  const [labels, setLabels] = useState<Array<{id: number, value: string}>>([]);
  const [norms, setNorms] = useState<number[]>([]);
  const [audienceScore, setAudienceScore] = useState<number[]>([]);
  const [absoluteDiff, setAbsoluteDiff] = useState<{id: number, value: number}[]>([]);
  const [relativeDiff, setRelativeDiff] = useState<{id: number, value: number}[]>([]);
  const [maidCount, setMaidCount] = useState(0);
  const [urlCount, setUrlCount] = useState(0);

  const [qscoreVal, setQscoreVal] = useState(0);
  const [recency, setRecency] = useState(0);
  const [frequency, setFrequency] = useState(0);
  const [intent, setIntent] = useState(0);
  const [similarity, setSimilarity] = useState(0);
  const [currAudienceId, setCurrentaudienceId] = useState<number>(-1);
  const [currMyAudienceId, setCurrentMyAudienceId] = useState<number>(-1);

  const [mySignals, setMySignals] = useState([
    { id: -1, name: "Select Signal", audienceScore: -1, normScore: -1 },
  ]);

  const [signalType, setSignalType] = useState(1);
  const marks = {
    5: '1',
    4: '2',
    3: '3',
    2: '4',
    1: '5',
  };
  const [isModelOpen, setIsModelOpen] = useState(false);
  const [historgramResData, setHistogramResData] = useState<
  {
    [key: string]: {
        [dimension_id: string]: {
            name: string,
            val: number
        }
    }}>({});

  const [initAbsoluteDiff, setInitAbsoluteDiff] = useState<{ [key: number | string]: {id: number, value: number}[] }>({});
  const [initRelativeDiff, setInitRelativeDiff] = useState<{ [key: number | string]: {id: number, value: number}[] }>({});
  let selectedSignalName = '';

  const segmentColors = ['#B02026', '#E24623', '#F8CA20', '#88B947', '#3A9048'];
        
  useEffect(() => {
    setHeading(["Calibrate", selectedCountry.charAt(0) + selectedCountry.substring(1).toLowerCase()]);
    const load = async () => {
      const res = await vaticAxiosGet("/signals_explore_page", {
        country: countryDb,
      });

      if (res && res.data) {
        const data = res.data;
        setSignals(data);
        // setLabels([]);
        // setNorms([]);
        // setAudienceScore([]);
        // setAbsoluteDiff([]);
        // setRelativeDiff([]);
      } else {
        setSignals([]);
        setLabels([]);
        setNorms([]);
        setAudienceScore([]);
        setAbsoluteDiff([]);
        setRelativeDiff([]);
      }

      // for the user defineed audience
      const resUser = await vaticAxiosGet("/audience_explore_page", {
        country: countryDb,
        user_id: userId,
      });

      if (resUser && resUser.data) {
        const data = resUser.data;
        setMySignals(data);
        // setLabels([]);
        // setNorms([]);
        // setAudienceScore([]);
        // setAbsoluteDiff([]);
        // setRelativeDiff([]);
      } else {
        setMySignals([]);
        setLabels([]);
        setNorms([]);
        setAudienceScore([]);
        setAbsoluteDiff([]);
        setRelativeDiff([]);
      }
    };

    load();
    let temp = localStorage.getItem("callibrate_page") || "{}";
    let obj = JSON.parse(temp);
    if (obj.id) { 
      setQscoreVal(obj.qscoreVal);
      setRecency(obj.recency);
      setSimilarity(obj.similarity);
      setFrequency(obj.frequency);
    }
    return () => {
      dispatch(set({ active_tab: 5 }));
    };
  }, [countryDb]);

  const changeSingalName = (id: number, vatic = true) => {
    if(vatic) {
      signals.forEach((val: SignalInterface) => {
        if (val.id == id ) {
          selectedSignalName = val.name;
        }
      })
    } else {
      mySignals.forEach((val: any) => {
        if(val.id == id) {
          selectedSignalName = val.name;
        }
      })
    }
    if(selectedSignalName)
      setHeading(["Calibrate", selectedCountry.charAt(0) + selectedCountry.substring(1).toLowerCase(), selectedSignalName]);
  }

  const handleSignalSelect = async (segment_id: number) => {
    setCurrentaudienceId(segment_id);
    changeSingalName(segment_id);

    let temp = localStorage.getItem("callibrate_page") || "{}";
    let obj = JSON.parse(temp);

    if (obj.id) {
      const new_obj = { ...obj, id: segment_id };
      localStorage.setItem("callibrate_page", JSON.stringify(new_obj));
    } else { 
      obj = {
        id: segment_id,
        is_vatic: true,
        qscoreVal: qscoreVal,
        recency: recency,
        frequency: frequency,
        similarity: similarity
      }
      localStorage.setItem("callibrate_page", JSON.stringify(obj));
    }

    const res_charts = await vaticAxiosGet("/get_audience_score", {
      country: countryDb,
      segment_id: segment_id,
      qscore: qscoreVal,
      frequency: frequency,
      recency: recency,
      similarity: similarity,
      intent: intent,
    });

    if (res_charts && res_charts.data) {
      const data_arr = res_charts.data;
      const labels_arr: any[] = [];
      const norms_arr: any[] = [];
      const audienceScore_arr: any[] = [];
      const absoluteDiff_arr: any[] = [];
      const relativeDiff_arr: any[] = [];
      const signalInterface_arr: any[] = [];
      let urlCountSum = 0;
      for (const key in data_arr) {
        labels_arr.push({id: data_arr[key].dim_id, value: data_arr[key].graph_label ? data_arr[key].graph_label : data_arr[key].dim_name});
        norms_arr.push(data_arr[key].norm_score);
        audienceScore_arr.push(data_arr[key].audience_score);
        absoluteDiff_arr.push({id: data_arr[key].dim_id, value: data_arr[key].absolute_difference});
        relativeDiff_arr.push({id: data_arr[key].dim_id, value: data_arr[key].relative_difference});
        signalInterface_arr.push({
          id: data_arr[key].dim_id,
          name: data_arr[key].dim_name,
          audienceScore: data_arr[key].audience_score,
          normScore: data_arr[key].norm_score,
          graph_label: data_arr[key].graph_label,
          selected: true,
        });
        urlCountSum += data_arr[key].url_hash_count;
        if(filteredDimensions[data_arr[key].dim_id] === undefined) {
          setFilteredDimensions(prev => {return {...prev, [data_arr[key].dim_id]: true}})
        }
      }
      setLabels(labels_arr);
      setNorms(norms_arr);
      setAudienceScore(audienceScore_arr);
      setAbsoluteDiff(absoluteDiff_arr);
      setRelativeDiff(relativeDiff_arr);
      setUrlCount(urlCountSum);
      setSignalScore(signalInterface_arr);

      setInitAbsoluteDiff((prevData) => {
        if (segment_id in prevData) return prevData; 
        return { ...prevData, [segment_id]: absoluteDiff_arr }; 
      });
      setInitRelativeDiff((prevData) => {
        if (segment_id in prevData) return prevData;
        return { ...prevData, [segment_id]: relativeDiff_arr };
      });
    }

    const res = await vaticAxiosGet("/get_signal_maid_count", {
      country: countryDb,
      segment_id: segment_id,
      qscore: qscoreVal,
      frequency: frequency,
      recency: recency,
      similarity: similarity,
      intent: intent,
    });
    if (res && res.data) {
      setMaidCount(res.data.maid_count);
    } else {
      setMaidCount(0);
    }
  };

  const handleMySignalSelect = async (audience_id: number) => {
    setCurrentMyAudienceId(audience_id);
    changeSingalName(audience_id, false);

    const res_charts = await vaticAxiosGet("/get_my_audience_score", {
      country: countryDb,
      audience_id: audience_id,
      qscore: qscoreVal,
      frequency: frequency,
      recency: recency,
      similarity: similarity,
      intent: intent,
    });

    if (res_charts && res_charts.data) {
      const data_arr = res_charts.data;
      const myLabels_arr: any[] = [];
      const myNorms_arr: any[] = [];
      const myAudienceScore_arr: any[] = [];
      const myAbsoluteDiff_arr: any[] = [];
      const myRelativeDiff_arr: any[] = [];
      const mySignalInterface_arr: any[] = [];
      let urlCountSum = 0;
      for (const key in data_arr) {
        myLabels_arr.push({id: data_arr[key].dim_id, value: data_arr[key].dim_name});
        myNorms_arr.push(data_arr[key].norm_score);
        myAudienceScore_arr.push(data_arr[key].audience_score);
        myAbsoluteDiff_arr.push({id: data_arr[key].dim_id, value: data_arr[key].absolute_difference});
        myRelativeDiff_arr.push({id: data_arr[key].dim_id, value: data_arr[key].relative_difference});
        mySignalInterface_arr.push({
          id: data_arr[key].dim_id,
          name: data_arr[key].dim_name,
          audienceScore: data_arr[key].audience_score,
          normScore: data_arr[key].norm_score,
          selected: true,
        });
        urlCountSum += data_arr[key].url_hash_count;
        if(!filteredDimensions[data_arr[key].dim_id]) {
          setFilteredDimensions(prev => {return {...prev, [data_arr[key].dim_id]: true}})
        }
      }
      setLabels(myLabels_arr);
      setNorms(myNorms_arr);
      setAudienceScore(myAudienceScore_arr);
      setAbsoluteDiff(myAbsoluteDiff_arr);
      setRelativeDiff(myRelativeDiff_arr);
      setUrlCount(urlCountSum);
      setSignalScore(mySignalInterface_arr);

      setInitAbsoluteDiff((prevData) => {
        if ("user_" + audience_id in prevData) return prevData; 
        return { ...prevData, ["user_" + audience_id]: myAbsoluteDiff_arr };
      });
      setInitRelativeDiff((prevData) => {
        if ("user_" + audience_id in prevData) return prevData; 
        return { ...prevData, ["user_" + audience_id]: myRelativeDiff_arr }; 
      });
    }

    const res = await vaticAxiosGet("/get_audience_maid_count", {
      country: countryDb,
      audience_id: audience_id,
      qscore: qscoreVal,
      frequency: frequency,
      recency: recency,
      similarity: similarity,
      intent: intent,
    });
    if (res && res.data) {
      setMaidCount(res.data.maid_count);
    } else {
      setMaidCount(0);
    }
  };

  const handleSelectDimension = (ind: number) => {
      setFilteredDimensions(prev => {return {...prev, [ind]: !prev[ind]}})
  }

  const getShowmeData = async (flag: string) => {
    if(signalType === 1) {
      const histogramRes = await vaticAxiosGet("/api/v1/calibrators-data", {segment_id: currAudienceId, flag: flag, country:  countryDb});
      if(histogramRes && histogramRes.data) {
        setHistogramResData(histogramRes.data);
      }
    } else {
      const histogramRes = await vaticAxiosGet("/api/v1/user-calibrators-data", {audience_id: currMyAudienceId, flag: flag, country:  countryDb});
      if(histogramRes && histogramRes.data) {
        setHistogramResData(histogramRes.data);
      }
    }
    
  }

  useEffect(() => {
    let temp = localStorage.getItem("callibrate_page") || "{}";
    let obj = JSON.parse(temp);
    let currId = -1;
    if (obj.id) {
      currId = obj.id;

      const new_obj = { ...obj, qscoreVal:qscoreVal, recency:recency, frequency:frequency, similarity:similarity };
      localStorage.setItem("callibrate_page", JSON.stringify(new_obj));

      if (signalType === 1) {
        handleSignalSelect(currId);
        setCurrentaudienceId(currId);
      } else {
        handleMySignalSelect(currId);
        setCurrentMyAudienceId(currId);
      }
    } else { 
      if (signalType === 1) {
        if (currAudienceId) handleSignalSelect(currAudienceId);
      } else {
        if (currMyAudienceId) handleMySignalSelect(currMyAudienceId);
      }
    }
    
  }, [qscoreVal, frequency, recency, similarity, intent]);

  useEffect(() => {
    let temp = localStorage.getItem("callibrate_page") || "{}";
    let obj = JSON.parse(temp);
    let currId = -1;
    if (obj.id) {
      currId = obj.id;
      const new_obj = { ...obj, qscoreVal:qscoreVal, recency:recency, frequency:frequency, similarity:similarity };
      localStorage.setItem("callibrate_page", JSON.stringify(new_obj));
      if (signalType === 1) {
        handleSignalSelect(currId);
        setCurrentaudienceId(currId);
      } else {
        handleMySignalSelect(currId);
        setCurrentMyAudienceId(currId);
      }
    } else { 
      if (signalType === 1) {
        if (currAudienceId) handleSignalSelect(currAudienceId);
      } else {
        if (currMyAudienceId) handleMySignalSelect(currMyAudienceId);
      }
    }
  }, [signalType]);

  const handleSelectAudience = () => {
      setCurrentMyAudienceId(currMyAudienceId);
  };

  // const handleSelectSignal = () => {
  //   const temp = signals.filter((item: any) => item.is_dim == 0);
  //   if (temp.length > 0) {
  //     setCurrentaudienceId(temp[0].id);
  //   }
  // };

  const location = useLocation();
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('id');
    if (id) {
      setSignalType(2);
      setCurrentMyAudienceId(parseInt(id));
      handleMySignalSelect(parseInt(id));
    }
  }, [location.search]);

  const [open, setOpen] = useState(false);
  
  const handleGenerateAudience = async () => { 
    let estimate_id = currMyAudienceId;
    let is_internal = false;
    if (signalType == 1) { 
      estimate_id = currAudienceId;
      is_internal = true;
    }
    const res = await vaticAxiosGet("/api/v1/generate-audience-service", {
      country: countryDb,
      estimate_id: estimate_id,
      is_internal: is_internal,
      qscore: qscoreVal,
      frequency: frequency,
      recency: recency,
      similarity: similarity,
    });
    nav('/dashboard/audience');
  }

  return (
    <StyledExplore>
      <div className="ctn1">
        <div className="ctn-gc">
          <div className="graph-ctn">
            <div className="ctn-head">
              <div className="ctn3">
                <span className="text1">Audience Size : </span>{" "}
                <span style={{ fontWeight: "400" }}>
                  {parseInt(String(maidCount)).toLocaleString()}
                </span>
              </div>
              <div className="ctn3">
                <span className="text1">Url Count : </span>{" "}
                <span style={{ fontWeight: "400" }}>
                  {parseInt(String(urlCount)).toLocaleString()}
                </span>
              </div>
              <div
                className="ctn4"
                onClick={() => {
                  setSignalType(1);
                  // handleSelectSignal();
                }}
              >
                <div className="text1">Vatic Audiences</div>
                {/* <select
                  onChange={(e) => {
                    handleSignalSelect(parseInt(e.target.value));
                  }}
                  className={signalType === 1 ? "select" : "select op-4"}
                >
                  {signals
                    .filter((item: any) => item.is_dim == 0)
                    .map((item, index) => (
                      <option key={index} value={item.id} className="select">
                        {item.name}
                      </option>
                    ))}
                </select> */}
                <div >
                  {!open ? (
                    <div onClick={() => setOpen((prev) => !prev)} className="head">
                      <span className="text">Categories</span>
                      <img src={downArrow} className="img" />
                    </div>
                    ): (<SignalCategory
                    level={0}
                    parentId={0}
                    categoryName="Categories"
                    handleSignalSelect={handleSignalSelect}
                    currAudienceId={currAudienceId?currAudienceId:-1}
                    setCurrAudienceId={setCurrentaudienceId}
                    setOpenParent={setOpen}
                  />)}
                </div>
              </div>
              <div
                className="ctn4"
                onClick={() => {
                  setSignalType(2);
                  handleSelectAudience();
                }}
              >
                <div className="text1">My Audiences</div>
                <select
                  onChange={(e) => {
                    handleMySignalSelect(parseInt(e.target.value));
                  }}
                  className={signalType === 2 ? "select" : "select op-4"}
                  style={{marginLeft: "10px"}}
                >
                  {mySignals.map((item, index) => (
                    <option key={index} value={item.id} className="select" selected = {currMyAudienceId == item.id}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            
            <div className="graph-ctn2">
              <div className="grpah-item">
                <div className="text1">Absolute Difference</div>
                <div className="graph">
                {
                  qscoreVal == 0 && recency == 0 && frequency == 0 && intent == 0 && similarity == 0 ? (
                    <BarChart dataVal={absoluteDiff} labels={labels} label="Absolute Difference" filter={signalScore.filter(item => filteredDimensions[item.id]).map(item => item.id)}/>
                  ): (
                    <CompareBarChart labels={labels} currVal={absoluteDiff} initVal={initAbsoluteDiff[signalType == 1 ? currAudienceId || 1 : "user_" + currMyAudienceId || 1]} label="Absolute Difference" filter={signalScore.filter(item => filteredDimensions[item.id]).map(item => item.id)}/>
                  )
                }
                </div>
              </div>
              <div className="grpah-item">
                <div className="text1">Relative Differences</div>
                <div className="graph">
                {
                  qscoreVal == 0 && recency == 0 && frequency == 0 && intent == 0 && similarity == 0 ? (
                    <BarChart dataVal={relativeDiff} labels={labels} label="Relative Difference" filter={signalScore.filter(item => filteredDimensions[item.id]).map(item => item.id)}/>
                  ):
                  (
                    <CompareBarChart labels={labels} currVal={relativeDiff} initVal={initRelativeDiff[signalType == 1 ? currAudienceId || 1: "user_" + currMyAudienceId || 1]} label="Relative difference" filter={signalScore.filter(item => filteredDimensions[item.id]).map(item => item.id)}/>
                  )
                }
                </div>
              </div>
            </div>
            {/* <div className="ctn5">
              <div className="ctn4">
                <div className="text1">Dimensions</div>
              </div>
              <div className="dim-container">
                {signalScore.filter(item => item.selected).map((item, index) => (
                  <div key={index}>
                    <div className="dimension-card" key={index}>
                      <div>{item.name} {"(" + item.graph_label + ")"}</div>
                      <div>Audience Score: {item.audienceScore.toFixed(2)}</div>
                      <div>
                        Dimension Norm Score: {item.normScore.toFixed(2)}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div> */}
          </div>
          <div className="ctn-6">
            <div className="text1 text-center">Calibrators</div>
            <div className="calibrator-ctn">
              <div>
                <div className="show-btn-ctn"><span>Keyword Match Level</span> <span className="show-btn" onClick={() => {getShowmeData("similarity"); setIsModelOpen(true)}}>Show</span></div>
                <Slider min={1} max={5} step={1} defaultValue={5} value={5-10*(similarity)} marks={marks} onChange={(val: any) => setSimilarity((5 - val) / 10)} reverse style={{zIndex: 0}}/>
              </div>

              <div>
                <div className="show-btn-ctn"><span>Activity</span> <span className="show-btn" onClick={() => {getShowmeData('intent'); setIsModelOpen(true)}}>Show</span></div>
                <Slider min={1} max={5} step={1} defaultValue={5} value={5-10*(intent)} marks={marks} onChange={(val: any) => setIntent((5 - val) / 10)} reverse style={{zIndex: 0}}/>
              </div>

              <div>
                <div className="show-btn-ctn"><span>Recency</span> <span className="show-btn" onClick={() => {getShowmeData('recency'); setIsModelOpen(true)}}>Show</span></div>
                <Slider min={1} max={5} step={1} defaultValue={5} value={5-10*(recency)} marks={{
                        5: '∞',
                        4: '3M',
                        3: '1M',
                        2: '2W',
                        1: '1W',
                      }} 
                      onChange={(val: any) => setRecency((5 - val) / 10)} reverse style={{zIndex: 0}}/>
              </div>

              <div>
                <div className="show-btn-ctn"><span>Frequency</span> <span className="show-btn" onClick={() => {getShowmeData('frequency'); setIsModelOpen(true)}}>Show</span></div>
                <Slider min={1} max={5} step={1} defaultValue={5} value={5-10*(frequency)} marks={marks} onChange={(val: any) => setFrequency((5 - val) / 10)} reverse style={{zIndex: 0}}/>
              </div>

              <div>
                <div className="show-btn-ctn"><span>Credit Qualifier</span> <span className="show-btn" onClick={() => {getShowmeData('qscore'); setIsModelOpen(true)}}>Show</span></div>
                <Slider min={1} max={5} step={1} defaultValue={5} value={5-10*(qscoreVal)} marks={marks} onChange={(val: any) => setQscoreVal((5 - val) / 10)} reverse style={{zIndex: 0}}/>
              </div>     
              
              <div>
                <div className="generate-audience-btn" onClick={()=> handleGenerateAudience()}>
                  Generate Audience
                </div>
              </div>
            </div>
            <div className="bullyeye-ctn">
              <BullsEye calibratorsData={[similarity, intent, recency, frequency, qscoreVal]}/>
            </div>
            <div className="dimension-select">
              <div className="text1 text-center">Dimensions</div>
              {
                signalScore.map((item, ind) => (
                  <div>
                    <input type="checkbox" checked = {filteredDimensions[item.id] === true} onClick={() => handleSelectDimension(item.id)}/>
                    <span>{item.graph_label ? item.graph_label : item.name}</span>
                    </div>
                ))
              }
              
            </div>
          </div>
        </div>
      </div>

      <Modal
        isOpen={isModelOpen}
        onRequestClose={() => setIsModelOpen(false)}
        style={{
          content: {
            width: '90%',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)', // Centers the modal vertically
            border: 'none',
            padding: '0',
            overflow: 'auto',
          },
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)', // Optional: Background color of the overlay
          }
        }}
      >
        <div>
          <div style={{display: "flex", justifyContent: "flex-end"}}>
            <span style={{padding: "5px 10px", background: "cyan", cursor: "pointer"}} onClick={() => setIsModelOpen(false)}>Close</span>
          </div>
          <div style={{display: "flex", justifyContent: "space-around", alignItems: "center", flexWrap: "wrap", overflowY: "auto"}}>
            {
              Object.keys(historgramResData).sort().map((outerKey, index) => (
                  <div >
                     <div style={{display: "flex", justifyContent: "center"}}>{outerKey}</div>
                     <Histogram values={Object.keys(historgramResData[outerKey]).sort().map(innerKey => { return {label: historgramResData[outerKey][innerKey].name.substring(0, 5), val: historgramResData[outerKey][innerKey].val}})} hexColor={segmentColors[index % 5]}/>
                  </div>
              ))
            }
          </div>
        </div>
      </Modal>
    </StyledExplore>
  );
};

export default Explore;
