import styled from "styled-components";

const StyledDefine = styled.div`
  width: 475px;
  .welcome-title {
    margin-top: 24px;
    //width: 241px;
    position: relative;
    font-size: 32px;
    line-height: 32px;
    display: inline-block;
    font-family: Inter;
    color: #000;
    text-align: left;
    margin-bottom: 16px;
  }

  .tell-us-about {
    width: 480px;
    position: relative;
    font-size: 14px;
    font-family: Inter;
    color: #000;
    text-align: left;
  }

  .input-title {
    position: relative;
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    font-family: Inter;
    color: #000;
    text-align: left;
    white-space: nowrap;
    display: inline-block;
  }

  .powered-by-AI {
    color: #7c818b;
    font-size: 14px;
    margin-top: 32px;
  }

  .describe-up-arrow {
    position: absolute;
    margin-top: -45px;
    margin-left: 430px;
    width: 32px;
    height: 32px;
    cursor: pointer;
  }

  .rectangle-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 12px;
    gap: 12px;
    cursor: pointer;
  }

  .img {
    width: 150px;
    height: 150px;
    object-fit: cover;
  }

  .active {
    border: 2px solid #4485f4;
    border-radius: 16px;
  }

  .space-between {
    display: flex;
    justify-content: space-between;
  }

  .generated-industry-container {
    width: 100%;
  }

  .generated-industry-container div {
    background-color: #f7f7f7;
  }

  .generating-industry-div-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .textarea-div {
    width: 100%;
    position: relative;
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #4485f4;
    box-sizing: border-box;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px;
    gap: 12px;
    padding-right: 50px;
  }

  .create-method-container {
    margin-top: 8px;
    display: flex;
    gap: 16px;
  }

  .create-method-title {
    position: relative;
    font-size: 16px;
    font-weight: 500;
    font-family: Inter;
    color: #3a76dc;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .create-method-title div {
    white-space: nowrap;
  }

  .industry-option-container {
    display: flex;
    justify-content: space-between;
  }

  .continue-btn-container {
    display: flex;
    justify-content: flex-end;
    gap: 20px;
    margin-top: 20px;
  }

  .continue-btn {
    border-radius: 8px;
    background-color: #4485f4;
    padding: 5px 0px;
    font-size: 16px;
    color: #fff;
    font-family: Inter;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0px;
    width: 110px;
  }

  .continue-btn:hover {
    opacity: 0.8;
  }

  .back-btn {
    border-radius: 8px;
    background-color: #b5b6b9;
    padding: 5px 0px;
    font-size: 16px;
    color: #000;
    font-family: Inter;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0px;
    width: 110px;
  }

  .back-btn: hover {
    opacity: 0.8;
  }

  .option {
    height: 40px;
    background: none;
  }

  .custom-separator {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 5px;
  }

  .custom-separator::before,
  .custom-separator::after {
    content: "";
    flex: 1;
  }

  .input-container {
    display: flex;
    width: 445px;
    flex-direction: column;
    margin-top: 20px;
  }

  .input-textarea {
    height: 70px;
    border-radius: 10px;
    padding: 12px;
    outline: none;
    box-shadow: none;
    margin-top: 8px;
    border-radius: 8px;
    border: 1px solid #cdd1d8;

    color: #000;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    box-shadow: none;
    outline: none;
    width: 100%;
  }

  .custom-url-input {
    height: 450px;
  }

  .input-textarea::placeholder {
    color: #82868f;
  }

  .aud-name {
    margin-top: 32px;
    color: #000;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 150% */
  }

  .aud-name-inp {
    margin-top: 8px;
    border-radius: 8px;
    border: 1px solid #cdd1d8;
    background: #fff;
    height: 48px;
    display: flex;
    align-items: center;
  }
  .aud-name-input::placeholder {
    color: #82868f;
  }

  .aud-name-input {
    color: #000;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-left: 12px;
    border: none;
    box-shadow: none;
    outline: none;
    width: 100%;
  }

  .method-ctn {
    display: flex;
    gap: 20px;
    margin-top: 32px;
  }

  .method-item {
    display: flex;
    gap: 5px;
  }

  .error {
    border: 1px solid red;
  }

  .error-msg {
    color: red;
    font-size: 14px;
  }

  .input-dropdown {
    width: 471px;
    height: 40px;
    border-radius: 5px;
    background-color: #fff;
    font-size: 16px;
    outline: none;
    box-shadow: none;
  }

  .lang-option {
    background-color: #fff;
  }

  .image-container {
    position: relative;
    display: inline-block; /* Adjust as needed for layout */
  }
  .image-container::before {
    content: "";
    position: absolute;
    top: 8%; /* Adjust for how much space to hide */
    right: 8%;
    bottom: 9%;
    left: 9%;
    border-radius: 5px;
    border: 5px solid #4485f4; /* Your border style */
    pointer-events: none; /* So the border doesn't interfere with clicking on the image */
  }
`;

export default StyledDefine;
