import styled from "styled-components";
import userImg from "assets/campaign/user.png";

const StyledHeader = styled.div`
  height: 72px;
  display: flex;
  align-items: center;

  .container {
    margin: 24px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .arrow {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }

  .t1 {
    margin-left: 16px;
    color: #000;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    display: flex;
    align-items: center;
    flex-direction: row;
    width: auto;
  }

  .ctn {
    display: flex;
    gap: 16px;
    flex-direction: row;
    align-items: center;
  }

  .icon {
    width: 32px;
    height: 32px;
    cursor: pointer;
  }

  .user-img {
    width: 32px;
    height: 32px;
    border-radius: 48px;
    background: url(${userImg}) lightgray -27.175px -10.479px / 248.243%
      165.495% no-repeat;
    cursor: pointer;
  }

  .profile-img {
    width: 32px;
    height: 32px;
    border-radius: 48px;
    cursor: pointer;
  }

  .t2 {
    color: #82868f;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 10px;
  }

  .profile-menu-btn-cntnr {
    position: absolute;
    top: 50px;
    background-color: #f5f5f5;
    width: 100px;
    height: 30px;
    right: 10px;
    display: flex;
    justify-content: center;
    border: 0.5px solid #4485f4;
    border-radius: 10px;
  }

  .logout-btn {
    background-color: transparent;
    border: none;
    cursor: pointer;
  }

  .invisible {
    display: none;
  }

  .setting {
    position: relative;
  }

  .setting-opt {
    position: absolute;
    padding: 10px;
    right: 0px;
    top: 33px;
    border: 1px solid transparent;
    background: #dcf3f7;
    border-radius: 8px;
    width: 200px;
    z-index: 5;
  }

  .platform-type {
    display: flex;
    font-size: 12px;
    align-items: center;
    gap: 2px;
  }

  input[type="radio" i] {
    margin: 0px !important;
  }

  .breadcrum {
    width: 25px;
  }

  .breadcrum-ctn {
    display: flex;
    flex-direction: row;
    gap: 10px;
  }

  .setting-option {
    color: #fff;
    background: #40484a;
    padding: 8px;
    border: 1px solid transparent;
    border-radius: 8px;
    cursor: pointer;
    font-size: 14px;
  }

  .setting-option:hover {
    background: #525c5e;
  }
`;

export default StyledHeader;
