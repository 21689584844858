import React, { useEffect, useState } from "react";
import StyledOrganization from "./styled";
import { vaticAxiosGet, vaticAxiosPost } from "utils/axios_helper";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import { RootState } from "services/store";
import { useNavigate } from "react-router";

interface IInviteData {
    email: string;
    accepted: boolean;
}

const Organization = () => {
    const login = useSelector((state: RootState) => ({organization: state.login.organization}));
    const navigate = useNavigate();

    const [organizationName, setOrganizationName] = useState('');
    const [error, setError] = useState({
        name: '',
        email: ''
    });
    const [inviteData, setInviteData] = useState<Array<IInviteData>>([]);

    const [invitationEmail, setInvitationEmail] = useState<Array<string>>([]);
    let [email, setEmail] = useState('');

    const handleCreateOrganization = async () => {
        if(!organizationName.trim()) {
            setError((p) => {
                return {...p, name: 'Enter a valid name'}
            });
        } else {
            const res = await vaticAxiosPost('/api/v1/organization-create', {
                'name': organizationName
            });
            if(res && res.statusCode == 200) {
                toast.success(res.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 1000,
                    });
                window.location.reload();
            } else {
                toast.error(res.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 1000,
                });
            }
        }
    }

    const validateEmail = (email: string) => {
        const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return re.test(String(email).toLowerCase());
    };

    const handleEmailAdd = () => {
        let tmpError = '';
        if(!email.trim()) {
            tmpError = 'email must not be empty';   
        } else if (!validateEmail(email)) {
            tmpError = 'invalid email';
        } else if(invitationEmail.includes(email)) {
            tmpError = 'already included added this email in list, you need to click on invite button'
        } else {
            setInvitationEmail(p => {return [...p, email]});
            setEmail('');
        }
        setError(p => {
            return {...p, email: tmpError}
        });
    }

    const handleEmailRemove = (ind: number) => {
        let tmp = [...invitationEmail];
        tmp.splice(ind, 1);
        setInvitationEmail(tmp);
    }

    const handleSendInvite = async () => {
        const response = await vaticAxiosPost('/api/v1/invite-email', {
            emails: invitationEmail.map(item => item.trim())
        });
        if(response && response.statusCode === 200) {
            toast.success('Invited', {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 1000,
            });
            setInvitationEmail([]);
            getInvites();
        } else {
            toast.error(response.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 1000,
            });
        }
    }

    const getInvites = async () => {
        const response = await vaticAxiosGet('/api/v1/invites');
        if (response && response.statusCode === 200) {
            setInviteData(response.data);
        } else {
            setInviteData([]);
        }
    }

    useEffect(() => {
        getInvites();
    }, []);

    return (
        <StyledOrganization>
            <div className="dashboard"><span className="create-btn" onClick={() => navigate('/dashboard')}>Dashboard</span></div>
            {login.organization ?
            (
                <div>
                    <div className="heading">{login.organization} </div>
                    <table border={1}>
                        <thead>
                            <tr>
                                <th>Email</th>
                                <th>Status</th>
                            </tr>
                        </thead>

                        <tbody>
                            {
                                inviteData.map((val, ind) => (
                                    <tr key={ind}>
                                        <td>{val.email}</td>
                                        <td>{val.accepted ? 'Accepted': 'Pending'}</td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>

                    <div className="invite-ctn">
                        <div className="heading">Invite</div>
                        <div>
                            {invitationEmail?.map((val, ind) => (
                                <div className="email-box" key={ind}><span>{val}</span> <span className="remove" onClick={() => {handleEmailRemove(ind)}}>x</span></div>
                            ))}
                        </div>
                        <div className="label-ctn">
                            <input onChange={(e) => {setEmail(e.target.value); setError(p => {return {...p, email: ''}})}} value={email} placeholder="email"/>
                            <div className="create-btn add" onClick={handleEmailAdd}>Add</div>
                        </div>
                        {error.email && <div className="error"> {error.email}</div>}
                        <div className="create-btn invite" onClick={handleSendInvite}>Send Invitation</div>
                    </div>
                </div>
            ): (
            <div className="create-org-ctn">
                <div>Create Organization</div>
                <div className="label-ctn">
                    <span>Name</span>
                    <input placeholder="Organization Name" onChange={(e) => {setOrganizationName(e.target.value); setError(p => {return {...p, name: ''}})}}></input>
                </div>
                {error.name && <div className="error">You must enter a valid name</div>}
                <div className="create-btn" onClick={handleCreateOrganization}>Create</div>
            </div>
            )}
            

            
        </StyledOrganization>
    )
}

export default Organization;