import styled from 'styled-components'

const StyledForgot = styled.div`

.forgot-container{
    margin-left:48px;
    // margin-top:224px;
}

.back-ctn {
    margin-top: 28px;
    width: 40px;
    height: 40px;
    cursor: pointer;
    margin-bottom: 50px;
}

.forgot-password {
    width: 281px;
    position: relative;
    font-size: 32px;
    line-height: 32px;
    display: flex;
    font-family: Inter;
    color: #000;
    text-align: left;
    margin-bottom: 45px;
}

.please-provide-the {
    margin: 0;
}
.please-provide-the-container {
    width: 440px;
    position: relative;
    font-size: 14px;
    line-height: 20px;
    font-family: Inter;
    color: #000;
    text-align: left;
    display: inline-block;
    margin-bottom:60px;
}

.rectangle-div {
    width: 100%;
    position: relative;
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #cdd1d8;
    box-sizing: border-box;
    height: 48px;
    padding: 12px;
    box-shadow: none;
    outline: none;
}

.send-reset-password {
    position: relative;
    font-size: 18px;
    font-weight: 500;
    font-family: Inter;
    background-color:transparent;
    border:none;
    color:#fff;
    text-align: left;
} 

.reset-btn-container {
    width: 100%;
    margin-top:45px;
    position: relative;
    border-radius: 8px;
    background-color: #4485f4;
    border: 1px solid rgba(220, 227, 227, 0);
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 13px 12px;
    text-align: left;
    font-size: 18px;
    color: #fff;
    font-family: Inter;
    cursor: pointer;
}

.email-title {
    width: 42px;
    position: relative;
    font-size: 12px;
    display: inline-block;
    font-family: Inter;
    color: #000;
    text-align: left;
}
    
    .form-section {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    .error-msg {
        color: red;
        margin-top: 10px;
        font-size: 15px;
    }


`

export default StyledForgot;