import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { RootState } from "services/store";
import landing_image from "../../assets/Background.png";
import point_icon from "../../assets/Group_icon.png";
import logo from "../../assets/VaticLogo/logo.png";
import eclipse_light from "../../assets/dark.png";
import home from "../../assets/landing_home.png";
import eclipse_dark from "../../assets/light.png";
import person_image from "../../assets/nicolas.png";
import star_icon from "../../assets/star.png";
import StyledLanding from "./styled";
import FullStory from "react-fullstory";
import {FULLSTORY_ORG_ID} from "../../utils/axios_helper";
 

const Landing = () => {
    const navigate = useNavigate();
    const isAuthenticated = useSelector((state: RootState) => (state.login.is_authenticated));

    const redirect = (path: string) => {
        navigate(path);
    }

    useEffect(() => {
        if (isAuthenticated) {
            redirect('/dashboard');
        }
    }, [])

    return (
        <StyledLanding>
            <div className="landing-container">
                <img className="landing-img" src={landing_image} alt="Landing"/>
                <div className="overlay">
                    <nav className="nav">
                        <img className="landing-img" src={logo} alt="logo"/>
                        <div className="button">
                            <div className="login-button" onClick={() => redirect('auth/login')}>Log in</div>
                            <button className="signup-button" onClick={() => redirect('auth/signup')}>Sign up</button>
                        </div>
                    </nav>
                </div>

                <div className="landing-text-container">
                    <p className="landing-text">Qscore Audience as a Service</p>
                </div>

                <div className="landing-subtext-container">
                    <div className="landing-subtext">Create precise campaign audience segments in a few clicks,
                        utilizing our predictive financial qualification capabilities.
                    </div>
                </div>

                <div className="landing-points-container">
                    <div className="landing-points">
                        <div className="point">
                            <div className="point-content">
                                <img className="point-icon" src={point_icon} alt="Point Icon"/>
                                <p>Generate pre-qualified financial audiences</p>
                            </div>
                            <div className="point-content">
                                <img className="point-icon" src={point_icon} alt="Point Icon"/>
                                <p>Maintain full control over your strategies </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="landing-point-container">
                    <div className="landing-point">
                        <div className="points">
                            <div className="points-content">
                                <img className="point-icon" src={point_icon} alt="Point Icon"/>
                                <p>Enhance campaign results</p>
                            </div>
                            <div className="points-content">
                                <img className="point-icon" src={point_icon} alt="Point Icon"/>
                                <p>Multichannel integrations</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="get-started-container">
                    <button className="get-started-button" onClick={() => redirect('auth/signup')}>Get started</button>
                </div>

                <div className="dark-grey-div">
                    <div className="reviews-container">
                        <p className="review-text">"Using Qscore's Audience as a Service has been a game-changer for our
                            marketing efforts. The platform's ease of use and the precision of audience targeting have
                            significantly enhanced our campaign performances. It's rare to find a tool that delivers
                            such immediate and impactful results."</p>
                        <div className="review-author">
                            <img className="author-image" src={person_image} alt="Person"/>
                            <div className="author-info">
                                <p className="author-name">Jordan Smith</p>
                                <p className="author-designation">Digital Marketing Manager, NextGen Innovations</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="below-reviews-container">
                    <div className="eclipse-container">
                        <img className="eclipse-image" src={eclipse_light} alt="Dark Eclipse"/>
                        <img className="eclipse-image" src={eclipse_dark} alt="Light Eclipse"/>
                        <img className="eclipse-image" src={eclipse_dark} alt="Dark Eclipse"/>
                        <img className="eclipse-image" src={eclipse_dark} alt="Light Eclipse"/>
                        <img className="eclipse-image" src={eclipse_dark} alt="Dark Eclipse"/>
                        <img className="eclipse-image" src={eclipse_dark} alt="Light Eclipse"/>
                    </div>
                </div>


                <div className="trusted-container">
                    <div className="star-rating">
                        <span className="star-icon">5.0</span>
                        <img className="star-image" src={star_icon} alt="Star"/>
                        <img className="star-image" src={star_icon} alt="Star"/>
                        <img className="star-image" src={star_icon} alt="Star"/>
                        <img className="star-image" src={star_icon} alt="Star"/>
                        <img className="star-image" src={star_icon} alt="Star"/>
                    </div>
                    <p className="trusted-text">Trusted by hundreds of companies</p>
                </div>

                <div className="landing-home-container">
                    <img className="landing-home-img" src={home} alt="Landing Home"/>
                </div>
            </div>
            <div className="privacy"><span className='privacy-text'
                                           onClick={() => redirect('/privacy')}>PRIVACY POLICY</span></div>
            <div>
                <FullStory org={FULLSTORY_ORG_ID || ''}/>
            </div>
        </StyledLanding>
    )
}

export default Landing;