import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface campaignInterface {
  id: number;
  audience_id: number;
  name: string;
  platform: string;
  created_at: any;
}

interface CampaignClick {
  campaign_name: string;
  campaign_id: number | string;
  clicks: number | string;
  impressions: number | string;
  campaign_goal: number | string;
  date: string;
}

interface AdSetclick {
  adset_id: number | string;
  adset_name: string;
  date: string;
  impressions: string | number;
  clicks: string | number;
  campaign_goal: string | number;
}

export interface HomeState {
  campaigns: campaignInterface[];
  campaignsClick: CampaignClick[];
  adSetsClick: AdSetclick[];
  countryDb: string;
  country: string;
}

const temp = localStorage.getItem("home") || "{}";
let obj = JSON.parse(temp);

const initialState: HomeState = {
  campaigns: obj.campaigns || [],
  campaignsClick: obj.campaignsClick || [],
  adSetsClick: obj.adSetsClick || [],
  countryDb: obj.countryDb || "algorithm",
  country: obj.country || "India",
};

export const homeSlice = createSlice({
  name: "home",
  initialState,
  reducers: {
    set: (state, action: PayloadAction<Partial<HomeState>>) => {
      const t0 = JSON.parse(localStorage.getItem("home") || "{}");

      localStorage.setItem(
        "home",
        JSON.stringify({
          ...t0,
          ...action.payload,
        })
      );

      return {
        ...state,
        ...action.payload,
      };
    },
    unset: (state) => {
      return { ...initialState };
    },
  },
});

export const { set, unset } = homeSlice.actions;
export default homeSlice.reducer;
