import styled from "styled-components";

const StyledUsers = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px;
  gap: 20px;

  table {
    border: 1px solid grey;
    border-collapse: collapse;
  }
  th,
  td {
    padding: 10px 20px;
    text-align: left;
  }

  td {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }

  tr:nth-child(even) {
    background: rgba(25, 25, 254, 0.1);
  }
`;

export default StyledUsers;
