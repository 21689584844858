import { PayloadAction, createSlice } from '@reduxjs/toolkit'

export interface SignupState {
    company_name: string;
    first_name: string;
    last_name: string;
    email: string;
    password: string;
}


const initialState: SignupState = {
    company_name: '',
    first_name: '',
    last_name: '',
    email: '',
    password: '',
}

export const signupSlice = createSlice({
    name: 'signup',
    initialState,
    reducers: {
        set: (state, action: PayloadAction<SignupState>) => {
            state.company_name = action.payload.company_name;
            state.first_name = action.payload.first_name;
            state.last_name = action.payload.last_name;
            state.email = action.payload.email;
            state.password = action.payload.password;
        },
        unset: (state) => {
            state.company_name = '';
            state.first_name = '';
            state.last_name = '';
            state.email = '';
            state.password = '';
        }
    },
})

export const { set, unset } = signupSlice.actions
export default signupSlice.reducer