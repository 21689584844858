import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { act } from "react-dom/test-utils";

export interface Campaign {
  name: string;
  client: string;
  platforms: string[];
  checked: boolean;
  id: number | string;
  acc_id: number;
}

export interface Keyword {
  candi_keyword: string;
  score: number;
  selected: boolean;
}

export interface AudienceState {
  method: number;
  activeTab: number;
  googleAuthUrl: string;
  metaAuthUrl: string;
  campaigns: Campaign[];
  aud_name: string;
  custom_keywords: string;
  t_region: string;
  awareness: boolean;
  promoting: string;
  t_audience: string;
  updating: boolean;
  curr_audience_id: number;
  prompt_id: number;
  keywords: Keyword[];
  keywords_fetched: boolean;
  language: string;
  urls: string;
  platform_type: string;
  selected_google_ad_acc: { [key: number]: boolean };
  selected_meta_ad_acc: { [key: string]: boolean };
  size: number;
  xAuthUrl: string;
  selected_x_ad_acc: { [key: string]: boolean };
  tiktokAuthUrl: string;
  selected_tiktok_ad_acc: { [key: string]: boolean };
}

const temp = localStorage.getItem("new_audience") || "{}";
let obj = JSON.parse(temp);

const initialState: AudienceState = {
  method: obj.method || 1,
  activeTab: obj.activeTab || 0,
  googleAuthUrl: "",
  metaAuthUrl: "",
  campaigns: obj.campaigns || [],
  aud_name: obj.aud_name || "",
  custom_keywords: obj.custom_keywords || "",
  t_region: obj.t_region || "INDIA",
  awareness: obj.goal || "",
  promoting: obj.promoting || "",
  t_audience: obj.t_audience || "",
  updating: false,
  curr_audience_id: obj.curr_audience_id || -1,
  prompt_id: obj.prompt_id || -1,
  keywords: obj.keywords || [],
  keywords_fetched: obj.keywords_fetched || false,
  language: obj.language || "English",
  urls: obj.urls || "",
  platform_type: obj.platform_type || "",
  selected_google_ad_acc: obj.selected_google_ad_acc || {},
  selected_meta_ad_acc: obj.selected_meta_ad_acc || {},
  size: obj.size || 0,
  xAuthUrl: "",
  selected_x_ad_acc: obj.selected_meta_ad_acc || {},
  tiktokAuthUrl: "",
  selected_tiktok_ad_acc: obj.selected_meta_ad_acc || {},
};

export const audienceSlice = createSlice({
  name: "audience",
  initialState,
  reducers: {
    set: (state, action: PayloadAction<Partial<AudienceState>>) => {
      const t0 = JSON.parse(localStorage.getItem("new_audience") || "{}");

      localStorage.setItem(
        "new_audience",
        JSON.stringify({
          ...t0,
          ...action.payload,
        })
      );

      return {
        ...state,
        ...action.payload,
      };
    },
    unset: (state) => {
      try {
        localStorage.removeItem("new_audience");
      } catch {
        console.log("error");
      }
      const new_initial_state: AudienceState = {
        method: 1,
        activeTab: 0,
        googleAuthUrl: "",
        metaAuthUrl: "",
        campaigns: [],
        aud_name: "",
        custom_keywords: "",
        t_region: "",
        awareness: true,
        promoting: "",
        t_audience: "",
        updating: false,
        curr_audience_id: -1,
        prompt_id: -1,
        keywords: [],
        keywords_fetched: false,
        language: "English",
        urls: "",
        platform_type: "brand",
        selected_google_ad_acc: {},
        selected_meta_ad_acc: {},
        size: 0,
        xAuthUrl: "",
        selected_x_ad_acc: {},
        tiktokAuthUrl: "",
        selected_tiktok_ad_acc: {},
      };

      return {
        ...new_initial_state,
      };
    },
  },
});

export const { set, unset } = audienceSlice.actions;
export default audienceSlice.reducer;
