import styled from "styled-components";

const StyledPlatform = styled.div`
  max-width: 500px;
  .container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
  }
  .platforms {
    margin-top: 35px;
    width: 153px;
    position: relative;
    font-size: 32px;
    line-height: 32px;
    display: inline-block;
    font-family: Inter;
    color: #000;
    text-align: left;
    font-weight: 600;
  }

  .connect-and-choose {
    width: 480px;
    position: relative;
    font-size: 14px;
    font-family: Inter;
    color: #000;
    text-align: left;
    display: inline-block;
  }

  .span {
    color: #82868f;
  }

  .select-platforms-1-container {
    margin-top: 24px;
    width: 152px;
    position: relative;
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    font-family: Inter;
    text-align: left;
    display: inline-block;
    color: #000;
  }

  .rectangle-div {
    width: 100%;
    position: relative;
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #cdd1d8;
    box-sizing: border-box;
    //height: 64px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .platform-icon {
    height: 32px;
    width: 32px;
    padding-right: 12px;
  }

  .connected {
    width: 64px;
    position: relative;
    font-size: 10px;
    font-weight: 600;
    font-family: Inter;
    color: #09814a;
    text-align: left;
    display: inline-block;
  }

  .platform-connect-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
  }

  .platform-container {
    display: flex;
  }

  .platform-name {
    // width: 75px;
    position: relative;
    font-size: 16px;
    font-family: Inter;
    color: #000;
    text-align: left;
    // display: flex;
  }

  .user-name {
    //width: 68px;
    position: relative;
    font-size: 12px;
    font-family: Inter;
    color: #000;
    text-align: left;
  }

  .connect {
    width: 100%;
    position: relative;
    border-radius: 8px;
    background-color: #4485f4;
    border: 1px solid rgba(220, 227, 227, 0);
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 6.5px 12px;
    text-align: left;
    font-size: 14px;
    color: #fff;
    font-family: Inter;
    cursor: pointer;
  }

  .connected-ctn {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .connected-icon {
    width: 10px;
    height: 10px;
    margin: 5px;
  }

  .flex-ver-center {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .platform-google-container {
    display: flex;
    justify-content: space-between;
  }

  .w-100 {
    width: 430px;
  }

  .asterisk {
    // display: flex;
    // flex-direction: row;
    // align-items: center;
    // justify-content: center;
    // // height: 30px;
    font-size: 12px;
    font-style: italic;
  }

  .asterisk-text {
    width: 500px;
    font-size: 12px;
    font-family: Inter;
    display: flex;
    gap: 5px;
    font-style: italic;
  }

  .continue-btn-container {
    position: relative;
    font-weight: 500;
    margin-top: 100px;
    cursor: pointer;
    display: flex;
    gap: 16px;
    fill: #fff;
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.16);
    width: 477px;
    height: 50px;
    flex-shrink: 0;
    padding: 12px 16px;
    border-radius: 8px;
    background: #fff;
  }

  .continue-btn {
    width: 100%;
    position: relative;
    border-radius: 8px;
    background-color: #4485f4;
    border: 1px solid rgba(220, 227, 227, 0);
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 13px 12px;
    text-align: left;
    font-size: 18px;
    color: #fff;
    font-family: Inter;
    cursor: pointer;
  }

  .back-btn {
    border-radius: 8px;
    border: 1px solid #cdd1d8;
    background: #fff;
    display: flex;
    width: 80px;
    padding: 13px 12px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    color: #000;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .subscript {
    font-size: 12px;
    font-style: italic;
  }

  .error {
    border: 1px solid red;
  }

  .error-msg {
    color: red;
    font-size: 14px;
  }

  .superscript {
    font-size: 12px;
    font-style: italic;
  }
`;

export default StyledPlatform;
