import React, { useEffect, useState } from "react";
import plusImg from "assets/campaign/plus.svg";
import StyledEstimateDetails from "./styled";
import emptyImg from "assets/campaign/empty.svg";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "services/store";
import { useNavigate } from "react-router";
import { unset } from "services/reducer/estimate";
import { vaticAxiosGet, vaticAxiosPost } from "utils/axios_helper";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { set as audienceSet } from "services/reducer/audience";
import ReactGA from "react-ga4";
import { REACT_APP_MEASUREMENT_ID } from "utils/axios_helper";
import ProgressBar from "@ramonak/react-progress-bar";
import infoIcon from "assets/info alert.svg";

interface estimateDataInterface {
  id: number;
  estimate_name: String;
  user_id: number;
  created_at: string;
  count: number;
  status: boolean;
  predicted_size: number;
  countryName: string;
  db: string;
}

interface EstimateDetailProps {
  setShowEstimateDetailsPage: (val: boolean) => void;
}

interface EstimateDetailInterface {
  estimate_id: number;
  estimate_name: string;
  user_id: number;
  creation_method: string;
  defined_audience: string;
  industry: string;
  devices: number;
  credit_qualification: string;
  audience_size: number;
  completed_processing: boolean;
  object_of_interest: string;
  target_audience: string;
  language: string;
  keyword: string;
  db: string;
}

interface CountryList {
  name: string;
  short_name: string;
  db: string;
}

const EstimateDetail: React.FunctionComponent<EstimateDetailProps> = ({
  setShowEstimateDetailsPage,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  ReactGA.initialize(REACT_APP_MEASUREMENT_ID || "");

  const countryDb = useSelector((state: RootState) => state.home.countryDb);
  const country = useSelector((state: RootState) => state.home.country);

  const [estimateData, setEstimateData] = useState<
    Array<estimateDataInterface>
  >([]);
  const [countIntervalId, setCountIntervalId] = useState<any>();
  const [isModelOpen, setIsModelOpen] = useState(false);
  const [estimateDetail, setEstimateDetail] =
    useState<EstimateDetailInterface>();
  const [progressPercent, setProgressPercent] = useState(0);
  const [countries, setCountries] = useState<Array<CountryList>>([]);

  const handleCreateNew = () => {
    setShowEstimateDetailsPage(false);
    dispatch(unset());
    navigate("/dashboard/estimate/create");
  };

  const fetchEstimateInfo = async (estimateId: number, db: string) => {
    const res = await vaticAxiosGet("/api/v1/ads/estimate-detail", {
      estimate_id: estimateId,
    });
    if (res.statusCode === 200) {
      let data = res.data;
      data = { ...data, db: db };
      setEstimateDetail(data);
      setIsModelOpen(true);

      const check_estimate_audience_count_interval_method = async () => {
        const response = await vaticAxiosGet(
          "/api/v1/check_estimate_audience_count_pubsub/" + estimateId,
          {
            db: db,
          }
        );
        const resData = response.data;
        if (response.statusCode === 200) {
          const newAudienceSize = parseInt(resData.estimate_count);
          const completed_flag = resData.completed;
          if (
            newAudienceSize !== -1 &&
            newAudienceSize !== data.audience_size
          ) {
            data = {
              ...data,
              audience_size: newAudienceSize,
              completed_processing: completed_flag,
            };
            setEstimateDetail(data);
          }
          if (newAudienceSize !== -1 && completed_flag) {
            data = {
              ...data,
              audience_size: newAudienceSize,
              completed_processing: completed_flag,
            };
            setEstimateDetail(data);
            clearInterval(countInterval);
          }
        }

        const progressRes = await vaticAxiosGet(
          "/api/v1/audience-populate-progress",
          { audience_id: estimateId, db: db }
        );
        const res = progressRes.data;

        if (!res) {
          return;
        }

        const t2 = res.processed_url_count;
        const t4 = res.total_url_count;

        // not started yet
        if (t2 === -1 && t4 === -1) {
          setProgressPercent(0);
          return;
        }

        // getting total url rows in process
        if (t2 === 0 && t4 === 0) {
          setProgressPercent(15);
          return;
        }

        // total url count is fetched make it and all the other values now will come as 0 not -1
        if (t2 === 0 && t4 !== 0) {
          setProgressPercent(35);
          return;
        }

        // having total_url count as well as processed url count
        if (t2 !== 0 && t4 !== 0) {
          setProgressPercent(35 + (t2 / t4) * 65);
          return;
        }
      };

      check_estimate_audience_count_interval_method();
      const countInterval = setInterval(
        check_estimate_audience_count_interval_method,
        5000
      );
      setCountIntervalId(countInterval);
    }
  };

  let intervalIds: any[] = [];

  const loadStatus = () => {
    const temp = estimateData;
    temp.forEach((estimate, index) => {
      const tf = async () => {
        const response = await vaticAxiosGet(
          `/api/v1/check_estimate_audience_count_pubsub/${estimate.id}`,
          {
            db: estimate.db,
          }
        );

        if (response && response.statusCode === 200) {
          const resData = response.data;
          temp[index].count = resData.estimate_count;
          temp[index].status = resData.completed;
          temp[index].predicted_size = resData.predicted_size;
          setEstimateData([...temp]);
        }
      };
      tf();
    });

    temp.forEach((estimate, index) => {
      const tmpInterval = setInterval(async () => {
        const response = await vaticAxiosGet(
          `/api/v1/check_estimate_audience_count_pubsub/${estimate.id}`,
          {
            db: estimate.db,
          }
        );

        if (response && response.statusCode === 200) {
          const resData = response.data;
          temp[index].count = resData.estimate_count;
          temp[index].status = resData.completed;
          temp[index].predicted_size = resData.predicted_size;
          setEstimateData([...temp]);

          // Clear interval if the status is complete
          if (resData.completed) {
            clearInterval(tmpInterval);
          }
        }
      }, 5000);
      intervalIds.push(tmpInterval);
    });
  };

  const loadEstimates = async (db: string, countryName: string) => {
    const res = await vaticAxiosGet("/api/v1/ads/estimates", {
      country: db,
    });

    if (res && res.data) {
      const temp: estimateDataInterface[] = res.data.map((i: any) => {
        return {
          ...i,
          count: -1,
          status: false,
          predicted_size: -1,
          countryName: countryName,
          db: db,
        };
      });
      setEstimateData((prev) => {
        return [...prev, ...temp];
      });
    }
  };

  useEffect(() => {
    loadEstimates(countryDb, country);
    return () => {
      intervalIds.forEach((ids) => {
        try {
          clearInterval(ids);
        } catch {}
      });
    };
  }, [countries]);

  useEffect(() => {
    intervalIds.forEach((ids) => {
      try {
        clearInterval(ids);
      } catch {}
    });
    intervalIds = [];
    loadStatus();

    return () => {
      intervalIds.forEach((ids) => {
        try {
          clearInterval(ids);
        } catch {}
      });
    };
  }, [estimateData.length]);

  const handleCreateAudience = async () => {
    if (!estimateDetail?.completed_processing) {
      toast.error("Processing Estimate size please wait", {
        autoClose: 1000,
      });
      return;
    }

    dispatch(
      audienceSet({
        aud_name: estimateDetail?.estimate_name,
        curr_audience_id: estimateDetail?.estimate_id,
        method: parseInt(estimateDetail.creation_method),
        size: estimateDetail.audience_size,
        promoting: estimateDetail.object_of_interest,
        language: estimateDetail.language,
        t_audience: estimateDetail.target_audience,
        urls: estimateDetail.keyword,
        custom_keywords: estimateDetail.keyword,
      })
    );
    ReactGA.event({
      category: "Button",
      action: "Click",
      label: "Create Audience",
    });

    const res = await vaticAxiosPost("/api/v1/estimate/audience", {
      db: estimateDetail.db,
      estimate_id: estimateDetail?.estimate_id,
      audience_name: estimateDetail?.estimate_name,
      campaigns: [],
    });

    if (res && res.statusCode == 200) {
      toast.success("Successfully Created Audience");
      dispatch(unset());
      navigate("/dashboard/audience");
    } else {
      navigate("/dashboard/audience");
    }
  };

  const handleDiscardAudience = async () => {
    const res = await vaticAxiosGet("/discard_estimate", {
      country: estimateDetail?.db,
      estimate_id: estimateDetail?.estimate_id,
    });
    if (res && res.statusCode === 200) {
      toast.success("Successfully Discarded", {
        autoClose: 1000,
      });
    } else {
      toast.error("Error");
    }
  };

  const handleDiscardAudienceAndSaveKeywords = async () => {
    const res = await vaticAxiosGet("/discard_estimate_keep_keywords", {
      estimate_id: estimateDetail?.estimate_id,
      country: estimateDetail?.db,
    });
    if (res && res.statusCode === 200) {
      toast.success("Successfully Discarded and Saved Keywords", {
        autoClose: 1000,
      });
    } else {
      toast.error("Error");
    }
  };

  const handleNotifyEstimate = async () => {
    const res = await vaticAxiosGet("/estimate/notify", {
      estimate_id: estimateDetail?.estimate_id,
      country: estimateDetail?.db,
    });
    if (res && res.statusCode === 200) {
      toast.success("You will be Notified on your email", {
        autoClose: 1000,
      });
    } else {
      toast.error("Error");
    }
  };

  return (
    <StyledEstimateDetails>
      <div className="head">
        <div className="aud-type">
          <div className="aud-type-item1">
            Your Private Audiences &nbsp;
            <span className="aud-type-item2">({estimateData.length})</span>
          </div>
        </div>
        <div className="create-btn-ctn" onClick={handleCreateNew}>
          <img src={plusImg} alt="+" className="plus" />
          <div className="plus-text">Create New Audience</div>
        </div>
        <div></div>
      </div>
      <div className="hor-line"></div>
      <div className="t-container">
        <div className="t-head">
          {/* <div className="t-head-font">Action</div> */}
          <div className="t-head-font">Name</div>
          <div className="t-head-font">Creation date</div>
          <div className="t-head-font">Status</div>
          <div className="t-head-font">Predicted Size</div>
          <div className="t-head-font">Callibrate</div>
        </div>
        <div className="hor-line"></div>
        {estimateData.length > 0 && (
          <div className="t-body">
            {estimateData.map((val, ind) => (
              <div className={ind % 2 === 0 ? "t-row-odd" : "t-row"} key={ind}>
                <div
                  className="t-data1"
                  onClick={() => fetchEstimateInfo(val.id, val.db)}
                >
                  {val.estimate_name}
                </div>
                <div className="t-data3">{val.created_at}</div>
                <div className="progress-ctn">
                  {val.status ? "Ready" : "Calculating"}
                </div>
                <div>
                  {val.predicted_size
                    ? val.predicted_size.toLocaleString()
                    : "Loading"}
                </div>
                <div
                  onClick={
                    val.status
                      ? () => navigate("/dashboard/explore?id=" + val.id)
                      : undefined
                  }
                  className={`create-btn-ctn ${
                    val.status ? "" : "disabled-btn"
                  }`}
                  style={{ cursor: val.status ? "pointer" : "not-allowed" }}
                >
                  {val.status ? "Calibrate" : "Calculating"}
                </div>
              </div>
            ))}
          </div>
        )}

        {estimateData.length == 0 && (
          <div className="t-body-empty">
            <img src={emptyImg} alt="?" className="empty-img" />
            <div className="empty-h1">
              No Estimate for{" "}
              {country.charAt(0) + country.slice(1).toLocaleLowerCase()} yet
            </div>
            <div className="empty-text">
              Create your first audience by clicking the button above.
            </div>
          </div>
        )}
      </div>
      {isModelOpen && (
        <div className="custom-modal-overlay">
          <div
            className="custom-modal-content"
            onClick={(e) => e.stopPropagation()}
          >
            <div>
              <div className="loaded-summary-box">
                <div className="f-spbt">
                  <div>
                    <div className="t4">Estimate Name</div>
                    <div className="t5">
                      {estimateData
                        .filter(
                          (item) => item.id === estimateDetail?.estimate_id
                        )
                        .map((item) => item.estimate_name)}
                    </div>
                  </div>
                </div>
                <div className="hor-line"></div>
                <div className="f-spbt">
                  <div className="div-w">
                    <div className="t4">Estimate Size</div>
                    {!estimateDetail?.completed_processing ? (
                      <div className="t5">
                        <div>
                          In Progress, Current Size :{" "}
                          {estimateDetail?.audience_size == -1
                            ? "Loading"
                            : estimateDetail?.audience_size?.toLocaleString()}
                        </div>
                        <div className="progress-bar-ctn-detail">
                          <ProgressBar
                            height="15px"
                            bgColor="#007bff"
                            labelSize="12px"
                            className="progress-bar-detail"
                            completed={progressPercent.toFixed(0)}
                            maxCompleted={100}
                          />
                        </div>
                        <div
                          onClick={() => {
                            handleNotifyEstimate();
                          }}
                          className="create-btn-ctn"
                        >
                          Notify Me
                        </div>
                        <div
                          className="notify-msg-ctn"
                          style={{ fontSize: "12px" }}
                        >
                          <div style={{ display: "flex", gap: "5px" }}>
                            <div>
                              <img
                                style={{ width: "25px" }}
                                src={infoIcon}
                              ></img>
                            </div>
                            <div>Processing Estimate size. Please Wait</div>
                          </div>
                          <div className="notify-red-line"></div>
                        </div>
                      </div>
                    ) : (
                      <div className="t5">
                        {estimateDetail.audience_size.toLocaleString()}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="create-audience-btn-ctn">
              <div
                className="close-button"
                onClick={() => {
                  setIsModelOpen(false);
                  clearInterval(countIntervalId);
                }}
              >
                Close
              </div>
              <div
                onClick={() => {
                  handleDiscardAudience();
                }}
                className="create-btn-ctn"
              >
                Discard Audience
              </div>

              <div
                onClick={() => {
                  handleDiscardAudienceAndSaveKeywords();
                }}
                className="create-btn-ctn"
              >
                Discard Audience & <br />
                Save Keywords
              </div>
              <div
                onClick={() => {
                  handleCreateAudience();
                }}
                className="create-btn-ctn"
              >
                Create Audience
              </div>
            </div>
          </div>
        </div>
      )}
    </StyledEstimateDetails>
  );
};

export default EstimateDetail;
