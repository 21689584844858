import React, { useEffect, useState } from "react";
import StyledAudienceDetail from "./styled";
import plusImg from "assets/campaign/plus.svg";
import dropArrow from "assets/campaign/bottomArrow.svg";
import deleteImg from "assets/campaign/delete.svg";
import extendImg from "assets/campaign/extend.svg";
import emptyImg from "assets/campaign/empty.svg";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "services/store";
import { FULLSTORY_ORG_ID, vaticAxiosGet } from "utils/axios_helper";

import help from "assets/help.svg";
import speedometerLow from "assets/speedo-meter-reverse.svg";
import speedometerMedium from "assets/speedo-meter-medium.svg";
import speedometerHigh from "assets/speedo-meter-high.svg";
import { useNavigate } from "react-router";
import { set,  unset } from "services/reducer/audience";
import googlePlatformIcon from "assets/platform_google-1.svg";
import facebookPlatformIcon from "assets/platform_facebook.svg";
import xPlatformIcon from "assets/platform_x.svg";
import snapPlatformIcon from "assets/platform_snapchat.svg";
import tiktokPlatformIcon from "assets/platform_tiktok.svg";
import editImg from "assets/edit-audience.svg";
import FullStory from "react-fullstory";
import SmallBullsEye from "components/Chart/SmallBullsEye";

interface audienceDataInterface {
  id: number;
  audience_name: String;
  user_id: number;
  created_at: string;
  platforms: string[];
  qscore: number;
  audience_size: number;
  recency:number;
  frequency: number;
  similarity: number;
}

interface AudienceDetailProps {
  setShowAudienceDetailsPage: (val: boolean) => void;
}

interface CampaignResInterface {
  campaign_id: string;
  campaign_name: string;
  platform: string;
}

interface AudienceDetailInterface {
  audience_id: number;
  audience_name: string;
  user_id: number;
  creation_method: string;
  defined_audience: string;
  industry: string;
  devices: number;
  credit_qualification: string;
  audience_size: number;
  campaigns: CampaignResInterface[];
  completed_processing: boolean;
}

const AudienceDetail: React.FunctionComponent<AudienceDetailProps> = ({
  setShowAudienceDetailsPage,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const user_id = useSelector((state: RootState) => state.login.user_id);
  const countryDb = useSelector((state: RootState) => state.home.countryDb);
  const country = useSelector((state: RootState) => state.home.country);

  const [audienceData, setAudienceData] = useState<
    Array<audienceDataInterface>
  >([]);
  const [isModelOpen, setIsModelOpen] = useState(false);
  const [audienceDetail, setAudienceDetail] =
    useState<AudienceDetailInterface>();

  const [progessText, setProgressText] = useState<Array<string>>([]);
  const [progressPercent, setProgressPercent] = useState<Array<number>>([]);
  const [progressComplete, setProgressComplete] = useState<Array<boolean>>([]);

  const [intervalId, setIntervalId] = useState<any>();

  useEffect(() => {
    const load = async () => {
      const res = await vaticAxiosGet("/api/v1/ads/audiences", {
        user_id: user_id,
        country: countryDb,
      });
      if (res && res.data) {
        setAudienceData(res.data);

        const tempProgressPercent: number[] = [];
        const tempProgressText: string[] = [];
        const tempProgressComplete: boolean[] = [];

        for (let i = 0; i < res.data.length; i++) {
          tempProgressPercent.push(15);
          tempProgressText.push("15% in progress");
          tempProgressComplete.push(false);
        }

        setProgressPercent(tempProgressPercent);
        setProgressText(tempProgressText);
        setProgressComplete(tempProgressComplete);

        const intervalMethod = async () => {
          for (let i = 0; i < res.data.length; i++) {
            if (tempProgressComplete[i]) {
              continue;
            }

            if (tempProgressPercent[i] == 100) continue;

            const progressRes = await vaticAxiosGet(
              "/api/v1/audience-populate-progress",
              { audience_id: res.data[i].id, db: countryDb }
            );
            const resData = progressRes.data;

            if (!resData) {
              continue;
            }

            const t1 = resData.processed_audience_count;
            const t2 = resData.processed_url_count;
            const t3 = resData.total_audience_count;
            const t4 = resData.total_url_count;
            const t5 = resData.campaign_audience_failed;
            const t6 = resData.campaign_audience_populated;
            const t7 = resData.total_campaign;

            // getting total url rows in process
            if (
              t1 === -1 &&
              t2 === -1 &&
              t3 === -1 &&
              t4 === -1 &&
              t5 === -1 &&
              t6 === -1 &&
              t7 === -1
            ) {
              setProgressPercent((prev) => {
                let newArr = [...prev];
                newArr[i] = 15;
                return newArr;
              });
              tempProgressPercent[i] = 15;

              setProgressText((prev) => {
                let newArr = [...prev];

                newArr[i] = `${15}% in progress`;

                return newArr;
              });

              tempProgressText[i] = `${15}% in progress`;
              continue;
            }

            // total url count is fetched make it and all the other values now will come as 0 not -1
            if (
              t1 === 0 &&
              t2 === 0 &&
              t3 === 0 &&
              t4 !== 0 &&
              t5 === 0 &&
              t6 === 0 &&
              t7 === 0
            ) {
              setProgressPercent((prev) => {
                let newArr = [...prev];
                newArr[i] = 35;
                return newArr;
              });

              tempProgressPercent[i] = 35;

              setProgressText((prev) => {
                let newArr = [...prev];

                newArr[i] = `${35}% in progress`;

                return newArr;
              });

              tempProgressText[i] = `${35}% in progress`;
              continue;
            }

            // having total_url count as well as processed url count
            if (
              t1 === 0 &&
              t2 !== 0 &&
              t3 === 0 &&
              t4 !== 0 &&
              t5 === 0 &&
              t6 === 0 &&
              t7 === 0
            ) {
              setProgressPercent((prev) => {
                let newArr = [...prev];
                newArr[i] = 35 + parseInt((t2 / t4).toFixed(0)) * 55;
                return newArr;
              });

              tempProgressPercent[i] = 35 + parseInt((t2 / t4).toFixed(0)) * 55;

              setProgressText((prev) => {
                let newArr = [...prev];

                newArr[i] = `${parseInt(
                  (35 + (t2 / t4) * 55).toFixed(0)
                )}% in progress`;

                return newArr;
              });

              tempProgressText[i] = `${parseInt(
                (35 + (t2 / t4) * 55).toFixed(0)
              )}% in progress`;
              continue;
            }

            // if campaign population is failed -> campaign_audience_failed -> t5 != 0

            if (t5 != 0) {
              setProgressComplete((prev) => {
                let temp = [...prev];
                temp[i] = true;
                return temp;
              });
              setProgressText((prev) => {
                let temp = [...prev];
                temp[i] = "You need to manually attach campaign!";
                return temp;
              });
              setProgressPercent((prev) => {
                let temp = [...prev];
                temp[i] = -1;
                return temp;
              });

              tempProgressPercent[i] = -1;
              tempProgressComplete[i] = false;
              tempProgressText[i] = "You need to manually attach campaign!";

              continue;
            }

            // no failed campaign t5 = 0

            if (t7 === 0) {
              setProgressComplete((prev) => {
                let temp = [...prev];
                temp[i] = true;
                return temp;
              });
              setProgressText((prev) => {
                let temp = [...prev];
                temp[i] = "No campaign was selected!";
                return temp;
              });
              setProgressPercent((prev) => {
                let temp = [...prev];
                temp[i] = -1;
                return temp;
              });
              tempProgressPercent[i] = -1;
              tempProgressComplete[i] = true;
              tempProgressText[i] = "No campaign was selected!";

              continue;
            }

            const t = t2 / t4;
            let per = (t * 100 * 75) / 100 + 15 + (t6 / t7) * 10;
            per = parseInt(per.toFixed(0));
            setProgressPercent((prev) => {
              let newArr = [...prev];
              newArr[i] = per;
              return newArr;
            });

            setProgressText((prev) => {
              let newArr = [...prev];
              if (per === 100) {
                newArr[i] = `Ready`;
              } else {
                newArr[i] = `${per}% in progress`;
              }

              return newArr;
            });

            if (per == 100) {
              tempProgressText[i] = "Ready";
              tempProgressPercent[i] = 100;
              tempProgressComplete[i] = true;
            } else {
              tempProgressText[i] = `${per}% in progress`;
              tempProgressComplete[i] = false;
              tempProgressPercent[i] = per;
            }
          }
        };
        // intervalMethod();
        // const tempIntervalId = setInterval(intervalMethod, 30000);
        // setIntervalId(tempIntervalId);
      } else {
        setAudienceData([]);
      }
    };
    load();
  }, [countryDb]);

  useEffect(() => {
    const totalStatus = progressComplete.reduce((a, b) => {
      return a && b;
    }, true);
    if (totalStatus) {
      clearInterval(intervalId);
    }
  }, [progressComplete]);

  const [countIntervalId, setCountIntervalId] = useState<any>();

  const fetchAudienceInfo = async (audience_id: number) => {
    const res = await vaticAxiosGet("/api/v1/ads/audience-detail", {
      audience_id: audience_id,
    });
    if (res.statusCode == 200) {
      let data = res.data;

      setAudienceDetail(data);
      setIsModelOpen(true);

      const check_audience_count_interval_method = async () => {
        const response = await vaticAxiosGet(
          "/api/v1/get_generated_audience_maid_count/" + audience_id,
          {
            db: countryDb,
          }
        );
        const resData = response.data;
        if (response.statusCode === 200) {
          console.log(resData);
          const newAudienceSize = parseInt(resData.audience_size);
          const completed_flag = resData.completed;
          console.log(newAudienceSize, completed_flag, data.audience_size);
          if (
            newAudienceSize !== -1 &&
            newAudienceSize !== data.audience_size
          ) {
            data = {
              ...data,
              audience_size: newAudienceSize,
              completed_processing: completed_flag,
            };
            setAudienceDetail(data);
          }
          if (newAudienceSize !== -1 && completed_flag) {
            console.log("Clearing check pubsub interval", completed_flag);
            data = {
              ...data,
              audience_size: newAudienceSize,
              completed_processing: completed_flag,
            };
            setAudienceDetail(data);
            clearInterval(countInterval);
          }
        }
      };
      check_audience_count_interval_method();
      const countInterval = setInterval(
        check_audience_count_interval_method,
        5000
      );
      setCountIntervalId(countInterval);
    }
  };

  useEffect(() => {
    return () => {
      clearInterval(intervalId);
    };
  }, [intervalId]);

  useEffect(() => {
    return () => {
      clearInterval(countIntervalId);
    };
  }, [countIntervalId]);

  // const campaignData: campaignDataInterface[] = [];

  const platformIcon = (platform: string) => {
    if (platform === "google") {
      return googlePlatformIcon;
    } else if (platform === "meta") {
      return facebookPlatformIcon;
    } else if (platform === "x") {
      return xPlatformIcon;
    } else if (platform === "snap") {
      return snapPlatformIcon;
    } else if (platform === "tiktok") {
      return tiktokPlatformIcon;
    }
  };

  const [platformAudienceStatus, setPlatformAudienceStatus] = useState({});

  const getPlatformStatus = async (audience_id: number) => {
    const res = await vaticAxiosGet("/platform/google/audience-status", {
      audience_id: audience_id,
      db: countryDb,
    });
    setPlatformAudienceStatus((prev) => {
      return { ...prev, [audience_id]: res.data };
    });
  };

  useEffect(() => {
    // audienceData.map((i) => getPlatformStatus(i.id));
  }, [audienceData]);

  const handleUploadAudience = (audience_id: number, name: string, size: number) => {
    setShowAudienceDetailsPage(false);
    dispatch(set({curr_audience_id: audience_id, aud_name: name, size: size}));
    navigate("/dashboard/audience/create");
  };

  return (
    <StyledAudienceDetail>
      <div className="head">
        <div className="aud-type">
          <div className="aud-type-item1">
            QScore Audiences &nbsp;
            <span className="aud-type-item2">({audienceData.length})</span>
          </div>
        </div>
      </div>
      <div className="hor-line"></div>
      <div className="t-container">
        <div className="t-head">
          {/* <div className="t-head-font">Action</div> */}
          <div className="t-head-font">Name</div>
          <div className="t-head-font">Creation date</div>
          <div className="t-head-font">Status</div>
          <div className="t-head-font">Platforms</div>
          <div className="t-head-font">Platform Status</div>
          <div className="t-head-font">Upload</div>
        </div>
        <div className="hor-line"></div>
        {audienceData.length > 0 && (
          <div className="t-body">
            {audienceData.map((val, ind) => (
              <div className={ind % 2 === 0 ? "t-row-odd" : "t-row"} key={ind}>
                {/* <div className="t-data">
                  <img className="delete-img" src={editImg} alt="delete" />
                  <img className="delete-img" src={deleteImg} alt="delete" />
                </div> */}
                <div
                  className="t-data1"
                  onClick={() => fetchAudienceInfo(val.id)}
                >
                  <div>{val.audience_name} </div>
                  <SmallBullsEye calibratorsData={[val.similarity, 0, val.recency, val.frequency, val.qscore]}/>
                </div>
                <div className="t-data3">{val.created_at}</div>
                <div className="progress-ctn">
                  <div
                    className={
                      progressPercent[ind] === -1
                        ? "progress-text-error"
                        : "progress-text"
                    }
                  >
                    {progessText[ind]}
                  </div>
                  <div className="progress-bar-ctn">
                    <div className="progress-base"></div>
                    {progressPercent[ind] === -1 ? (
                      <div className="progress-error"></div>
                    ) : (
                      <div
                        className="progress"
                        style={{ width: `${progressPercent[ind]}%` }}
                      ></div>
                    )}
                  </div>
                </div>
                <div className="t-data2">
                  {val.platforms.map((p, ind) => (
                    <img
                      src={platformIcon(p)}
                      key={ind}
                      className="platform-icon"
                    />
                  ))}
                </div>
                <div className="t-data2">
                  {platformAudienceStatus &&
                    (platformAudienceStatus as { [key: number]: any })[val.id]}
                </div>
                <div onClick={(e) => { 
                  handleUploadAudience(val.id, val.audience_name.toString(), 0);
                }} className="t-data2" style={{cursor: "pointer"}}>
                  Upload
                </div>
              </div>
            ))}
          </div>
        )}

        {audienceData.length == 0 && (
          <div className="t-body-empty">
            <img src={emptyImg} alt="?" className="empty-img" />
            <div className="empty-h1">
              No Audience for{" "}
              {country.charAt(0) + country.slice(1).toLocaleLowerCase()} yet
            </div>
            <div className="empty-text">
              Create your first audience by clicking the button above.
            </div>
          </div>
        )}
      </div>
      {isModelOpen && (
        <div className="custom-modal-overlay">
          <div
            className="custom-modal-content"
            onClick={(e) => e.stopPropagation()}
          >
            <button
              className="close-button"
              onClick={() => {
                setIsModelOpen(false);
                clearInterval(countIntervalId);
              }}
            >
              Close
            </button>
            <div>
              <div className="loaded-summary-box">
                <div className="f-spbt">
                  <div>
                    <div className="t4">Audience Name</div>
                    <div className="t5">
                      {audienceData
                        .filter(
                          (item) => item.id === audienceDetail?.audience_id
                        )
                        .map((item) => item.audience_name)}
                    </div>
                  </div>
                </div>
                <div className="hor-line"></div>
                <div className="f-spbt">
                  <div className="div-w">
                    <div className="t4">Devices</div>
                    {!audienceDetail?.completed_processing ? (
                      <div className="t5">
                        In Progress, Current Size :{" "}
                        {audienceDetail?.audience_size == -1
                          ? "Loading"
                          : audienceDetail?.audience_size.toLocaleString()}
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 100 100"
                          xmlns="http://www.w3.org/2000/svg"
                          style={{ marginLeft: "10px" }}
                        >
                          <circle
                            cx="50"
                            cy="50"
                            r="40"
                            fill="none"
                            stroke="lightblue"
                            stroke-width="10"
                            stroke-linecap="round"
                            stroke-dasharray="188 62"
                          >
                            <animateTransform
                              attributeName="transform"
                              type="rotate"
                              from="0 50 50"
                              to="360 50 50"
                              dur="1s"
                              repeatCount="indefinite"
                            />
                          </circle>
                        </svg>
                      </div>
                    ) : (
                      <div className="t5">
                        {Math.floor(
                          (audienceDetail?.audience_size ?? 0) -
                            (audienceDetail?.audience_size ?? 0) * 0.2
                        ).toLocaleString()}{" "}
                        -
                        {Math.floor(
                          (audienceDetail?.audience_size ?? 0) +
                            (audienceDetail?.audience_size ?? 0) * 0.2
                        ).toLocaleString()}
                      </div>
                    )}
                  </div>
                </div>
                <div className="hor-line"></div>
                <div className="f-spbt">
                  <div className="div-w speedo-meter-p">
                    <div>
                      <div className="t4">
                        {" "}
                        Audience size{" "}
                        <img src={help} alt="help" className="help" />
                      </div>
                      {!audienceDetail?.completed_processing ? (
                        <div className="t5">
                          In Progress, Current Size :{" "}
                          {audienceDetail?.audience_size == -1
                            ? "Loading"
                            : audienceDetail?.audience_size.toLocaleString()}
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 100 100"
                            xmlns="http://www.w3.org/2000/svg"
                            style={{ marginLeft: "10px" }}
                          >
                            <circle
                              cx="50"
                              cy="50"
                              r="40"
                              fill="none"
                              stroke="lightblue"
                              stroke-width="10"
                              stroke-linecap="round"
                              stroke-dasharray="188 62"
                            >
                              <animateTransform
                                attributeName="transform"
                                type="rotate"
                                from="0 50 50"
                                to="360 50 50"
                                dur="1s"
                                repeatCount="indefinite"
                              />
                            </circle>
                          </svg>
                        </div>
                      ) : (
                        <div className="t5">
                          {Math.floor(
                            (audienceDetail?.audience_size ?? 0) -
                              (audienceDetail?.audience_size ?? 0) * 0.2
                          ).toLocaleString()}{" "}
                          -
                          {Math.floor(
                            (audienceDetail?.audience_size ?? 0) +
                              (audienceDetail?.audience_size ?? 0) * 0.2
                          ).toLocaleString()}
                        </div>
                      )}
                    </div>
                    <div className="speedo-meter-ctn">
                      {audienceDetail?.credit_qualification === "Low" && (
                        <img
                          src={speedometerLow}
                          alt=""
                          className="speedo-meter"
                        />
                      )}
                      {audienceDetail?.credit_qualification === "Medium" && (
                        <img
                          src={speedometerMedium}
                          alt=""
                          className="speedo-meter"
                        />
                      )}
                      {audienceDetail?.credit_qualification === "High" && (
                        <img
                          src={speedometerHigh}
                          alt=""
                          className="speedo-meter"
                        />
                      )}
                      {/* <img src={needle} alt="" className="needle" style={{ transform: "rotateZ(-50deg)" }} /> */}
                    </div>
                  </div>
                </div>
                <div className="hor-line-neg"></div>
                <div className="campaign-row">
                  <div className="campaign-head">Campaign</div>
                  <div className="campaign-head">Platform</div>
                </div>
                {audienceDetail?.campaigns.map(
                  (campaign: CampaignResInterface, ind: number) => (
                    <div className="campaign-row">
                      <div className="campaign-name">
                        {campaign.campaign_name}
                      </div>
                      <div className="campaign-platform">
                        {campaign.platform}
                      </div>
                    </div>
                  )
                )}
                {/* <div className="keyword-25">{audDefInfo.keywords_list.filter(i => i.isSelected).length} keywords</div>
                        <div className="keyword-table-ctn">
                            <div className="keywrod-table-h">
                                <div className="candidate-keyword">Related keyword</div>
                                <div className="keyword-score">Score</div>
                                <div className="keyword-score">Included keyword</div>
                                <div className="keyword-score">Score</div>
                            </div>
                            <div className="hor-line"></div>
                            <div className="keyword-table-body">
                                {
                                    audDefInfo.keywords_list.map((item, index) => (
                                        item.isSelected && (<div className={index % 2 === 0 ? "keyword-table-row-even" : "keyword-table-row"} key={index}>
                                            <div className="keyword-item">{item.related_candi_keyword}</div>
                                            <div className="keyword-item-score">{item.related_score.toFixed(2)}</div>
                                            <div className="keyword-item">{item.included_candi_keyword}</div>
                                            <div className="keyword-item-score">{item.included_score.toFixed(2)}</div>
                                        </div>)
                                    ))
                                }
                            </div>
                        </div> */}
              </div>
            </div>
          </div>
        </div>
      )}
      <div>
        <FullStory org={FULLSTORY_ORG_ID || ""} />
      </div>
    </StyledAudienceDetail>
  );
};

export default AudienceDetail;
