import styled from "styled-components";

const StyledCreateCampaign = styled.div`
    margin-top: 50px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 16px;
    flex-direction: row;
    background: #F3F4F7;

    .ctn1 {
        display: flex;
        flex-direction: column;
        background: #FFF;
        width: 200px;
        border-radius: 8px;
        height: auto;
    }

    .ctn1-row {
        margin: 16px;
        display: flex;
        gap: 5px;
        align-items: center;
    }

    .num-circle-active {
        width: 32px;
        height: 32px;
        background: #4485F4;
        border-radius: 50%;
        color: #FFF;
        text-align: center;
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    .num-circle {
        background: #F3F4F7;
        width: 32px;
        height: 32px;
        color: #82868F;
        text-align: center;
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
    }

    .circle-title-active {
        color: #000;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    .circle-title {
        color: #82868F;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    .ctn2 {
        background: #FFF;
        width: 560px;
        border-radius: 8px;
        // height: 453px;
    }

    .ctn2-heading {
        padding: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #000;
        text-align: center;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    .hor-line {
        background: #E3E5E9;
        height: 1px;
    }

    .ctn2-body {
        margin: 32px;
    }

    .ctn2-body-row {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    .t1 {
        color: #000;
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    .t1-input {
        display: inline-flex;
        padding: 15.5px 12px;
        align-items: center;
        gap: 8px;
        border-radius: 8px;
        border: 1px solid #CDD1D8;
        background: #FFF;
        outline: none;
        box-shadow: none;
    }

    .m-16 {
        margin-top: 16px;
    }

    .ctn2-body-row2 {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 10px;
    }

    .next-btn {
        margin-top: 32px;
        cursor: pointer;

        color: #FFF;
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;

        border-radius: 8px;
        border: 1px solid rgba(220, 227, 227, 0.00);
        background: #4485F4;
        display: flex;
        width: 496px;
        padding: 17px 0px;
        justify-content: center;
        align-items: center;
        gap: 8px;
    }

    .next-btn-disabled {
        margin-top: 32px;
        cursor: pointer;

        color: #FFF;
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;

        border-radius: 8px;
        border: 1px solid rgba(220, 227, 227, 0.00);
        background: #B5B6B9;
        display: flex;
        width: 496px;
        padding: 17px 0px;
        justify-content: center;
        align-items: center;
        gap: 8px;
    }

    .grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }

    .h-active {
        color: #4485F4;
        border-bottom: 1px solid #4485F4;
    }

    .reg-select {
        border-radius: 8px;
        background: #F0F0F0;
        width: 496px;
        height: 48px;
        padding: 10px;
        outline: none;
        border: none;
        box-shadow: none;
    }

    .reg-option {
        color: #000;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .select-region {
        border-radius: 8px;
        background: #FFF;
        width: 496px;
        height: 48px;
        padding: 10px;
        outline: none;
        border: none;
        box-shadow: none;

        border-radius: 8px;
        border: 1px solid #CDD1D8;
        background: #FFF;
    }

    .ctn2-h1 {
        padding: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #82868F;
        text-align: center;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    .platform-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 8px;
        margin: 32px;
    }

    .rectangle-div {
        background: #FFF;
        width: 100%;
        flex-shrink: 0;
        position: relative;
        border-radius: 8px;
        border: 1px solid #CDD1D8;
        box-sizing: border-box;
        height: 60px;
        display:flex;
        align-items: center;
        justify-content:space-between;
        padding-left:12px;
        padding-right:12px;
    }
    
    .platform-icon{
        height:32px;
        width:32px;
        padding-right:12px;
    }
    
    .connected {
        width: 64px;
        position: relative;
        font-size: 10px;
        font-weight: 600;
        font-family: Inter;
        color: #09814a;
        text-align: left;
        display: inline-block;
    }
    
    .platform-connect-container{
        display:flex;
        align-items:center;
        justify-content:center;
        gap:16px;
    }
    
    .platform-container{
        display: flex;
    }
    
    .platform-name{
        width: 75px;
        position: relative;
        font-size: 16px;
        font-family: Inter;
        color: #000;
        text-align: left;
        display: inline-block; 
    }
    
    .user-name{
        width: 68px;
        position: relative;
        font-size: 12px;
        font-family: Inter;
        color: #000;
        text-align: left;
    }
    
    .connect {
        width: 100%;
        position: relative;
        border-radius: 8px;
        background-color: #4485f4;
        border: 1px solid rgba(220, 227, 227, 0);
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding: 6.5px 12px;
        text-align: left;
        font-size: 14px;
        color: #fff;
        font-family: Inter; 
        cursor:pointer;
    }
    
    .ctn-summary {
        height: 400px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        margin: 32px;
    }

    .entered-data {
        margin: 16px;
        color: #000;
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

`;

export default StyledCreateCampaign;