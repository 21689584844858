import React, { useEffect, useState } from "react";
import StyledLeftPanel from "./styled";
import homeImg from "assets/campaign/home.svg";
import campaign from "assets/campaign/campaign.svg";
import platforms from "assets/campaign/platforms.svg";
import audience from "assets/campaign/audience.svg";
import estimate from "assets/campaign/estimate.svg";
import explore from "assets/campaign/explore.svg";
import { useNavigate } from "react-router";
import FullStory from "react-fullstory";
import { FULLSTORY_ORG_ID, vaticAxiosGet } from "../../../utils/axios_helper";
import { useDispatch, useSelector } from "react-redux";
import { set } from "services/reducer/home";
import {
  set as audienceSet,
  unset as audienceUnset,
} from "services/reducer/audience";
import {
  set as estimateSet,
  unset as estimateUnset,
} from "services/reducer/audience";
import { RootState } from "services/store";

interface LeftPanelInterface {
  heading: String[];
  setHeading: (val: String[]) => void;
  isExtended: boolean;
  activeTab: number;
  setActiveTab: (val: number) => void;
}

interface CountryList {
  name: string;
  short_name: string;
  db: string;
}

const LeftPanel: React.FunctionComponent<LeftPanelInterface> = ({
  isExtended,
  heading,
  setHeading,
  activeTab,
  setActiveTab,
}) => {
  const navigate = useNavigate();
  const [countries, setCountries] = useState<Array<CountryList>>([]);
  const dispatch = useDispatch();
  const t_region = useSelector((state: RootState) => state.audience.t_region);
  const home = useSelector((state: RootState) => ({
    country: state.home.country,
  }));

  useEffect(() => {
    const load = async () => {
      const res = await vaticAxiosGet("/api/v1/country-list");
      if (res && res.data) {
        setCountries(res.data);
      } else {
        setCountries([]);
      }
    };
    load();
  }, []);

  const handleCountryChange = (e: any) => {
    for (let i = 0; i < countries.length; i++) {
      if (countries[i].db === e.target.value) {
        dispatch(
          set({
            countryDb: e.target.value.toLowerCase(),
            country: countries[i].name,
          })
        );
        dispatch(
          audienceSet({
            t_region: countries[i].name.toLocaleLowerCase(),
          })
        );
        dispatch(
          estimateSet({
            t_region: countries[i].name.toLocaleLowerCase(),
          })
        );
      }
    }
    window.location.reload();
  };
  return (
    <StyledLeftPanel maxwidth={isExtended ? "220px" : "72px"}>
      <div className={isExtended ? "logo1" : "logo2"} onClick={() => navigate("/dashboard/home")}/>
      <div className="hor-line"></div>
      <div className="region-ctn">
        <div className="region-h">Region</div>
        <div className="region-box">
          {/* <div className="region-text">{isExtended ? "INDIA" : "IN"}</div> */}
          <select className="custom-select" onChange={handleCountryChange}>
            {countries.map((val, ind) => (
              <option
                className="region-text"
                value={val.db}
                selected={
                  val.name.toLowerCase() == home.country.toLocaleLowerCase()
                }
                key={ind}
              >
                {isExtended ? val.name : val.short_name}
              </option>
            ))}
          </select>
          {/* <img src={downArrow} alt="|" className="region-arrow" /> */}
        </div>
      </div>
      <div className="menu-box">
        <div
          className={`menu-row ${heading[0] === "Home" ? "active_tab" : ""}`}
          onClick={() => {
            // setHeading(['Home']); setActiveTab(1);
            navigate("/dashboard/home");
          }}
        >
          <div className="icon">
            <svg
              width="24px"
              height="24px"
              viewBox="0 0 24 24"
              fill={`${heading[0] === "Home" ? "#FFFFFF" : "#B8B8B8"}`}
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="Group 42">
                <g id="Group 41">
                  <path
                    id="Vector (Stroke)"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M8.51425 15.0232H15.4874V21.3023H14.0921V16.4185H9.90882L9.90609 21.3027L8.51074 21.3019L8.51425 15.0232Z"
                  />
                  <path
                    id="Vector (Stroke)_2"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M11.6015 2.12609C11.8418 1.95796 12.1615 1.95797 12.4017 2.12612L21.704 8.63775C21.9294 8.79549 22.041 9.07093 21.989 9.34106L19.6634 21.4341C19.6002 21.7626 19.3127 22 18.9782 22H5.02478C4.6903 22 4.40288 21.7626 4.33967 21.4342L2.0126 9.34114C1.96061 9.07097 2.07225 8.79547 2.29766 8.63771L11.6015 2.12609ZM3.46827 9.52157L5.601 20.6047H18.402L20.5334 9.52156L12.0016 3.54927L3.46827 9.52157Z"
                  />
                </g>
              </g>
            </svg>
          </div>
          {isExtended && (
            <div
              className={`${
                heading[0] === "Home" ? "icon-text-active" : "icon-text"
              }`}
            >
              Home
            </div>
          )}
        </div>

        <div
          className={`menu-row ${
            heading[0] === "Connect" ? "active_tab" : ""
          }`}
          onClick={() => {
            // setHeading(['Platforms']); setActiveTab(4);
            navigate("/dashboard/platform");
          }}
        >
          <div className="icon">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill={`${heading[0] === "Connect" ? "#FFFFFF" : "#B8B8B8"}`}
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="Icon">
                <g id="Group 46">
                  <path
                    id="Vector (Stroke)"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M4.21422 2.83804C4.94389 2.24882 5.85828 2 6.57074 2H19.3642V3.3846H6.57074C6.13727 3.3846 5.53974 3.54734 5.08411 3.91527C4.65939 4.25824 4.33509 4.79328 4.3979 5.64698C4.46174 6.5146 4.83543 6.95003 5.23453 7.19635C5.67232 7.46656 6.20575 7.54409 6.57074 7.54409H15.9082V6.13681H6.45494V4.75221H16.5913C16.9765 4.75221 17.2928 5.06403 17.2928 5.45372V7.54409H20.0317V10.0654H18.6471V8.9287H6.57074C6.05999 8.9287 5.24207 8.8281 4.5073 8.3746C4.47111 8.35226 4.43525 8.32914 4.39977 8.30523V17.9865L4.39574 18.0237C4.35498 18.4001 4.4156 18.9936 4.71877 19.4866C4.99509 19.9359 5.51148 20.3695 6.54637 20.4391H11.9688V21.8237H6.50254L6.48109 21.8224C5.00481 21.7306 4.06481 21.0663 3.53936 20.2119C3.04972 19.4158 2.9576 18.5187 3.01517 17.9142V5.72213C2.92639 4.40707 3.45871 3.44814 4.21422 2.83804Z"
                    fill={`${
                      heading[0] === "Connect" ? "#FFFFFF" : "#B8B8B8"
                    }`}
                  />
                  <path
                    id="Vector (Stroke)_2"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M19.9323 12.2154C20.3949 11.9323 21 12.26 21 12.8137V21.1417C21 21.694 20.3893 22.0321 19.921 21.7329M19.9196 21.732L16.4162 19.4952H13.848C13.4651 19.4952 13.1465 19.1857 13.1465 18.7936V15.1041C13.1465 14.7166 13.4606 14.4025 13.848 14.4025H16.4204L19.9323 12.2154M19.6154 14.0438L16.8163 15.7871H14.5311V18.1106H16.8205L19.6154 19.8951V14.0438Z"
                    fill={`${
                      heading[0] === "Connect" ? "#FFFFFF" : "#B8B8B8"
                    }`}
                  />
                </g>
              </g>
            </svg>
          </div>
          {isExtended && (
            <div
              className={`${
                heading[0] === "Connect" ? "icon-text-active" : "icon-text"
              }`}
            >
              Connect
            </div>
          )}
        </div>

        <div
          className={`menu-row ${
            heading[0] === "Create" ? "active_tab" : ""
          }`}
          onClick={() => {
            // setHeading(['Audience']); setActiveTab(2);
            dispatch(estimateUnset());
            navigate("/dashboard/estimate");
          }}
        >
          <div className="icon">
            <svg
              fill={`${heading[0] === "Create" ? "#FFFFFF" : "#B8B8B8"}`}
              height="24px"
              width="24px"
              version="1.1"
              id="Layer_1"
              viewBox="0 0 385.072 385.072"
            >
              <g>
                <g>
                  <g>
                    <path
                      d="M288.824,99.842c-0.001-0.001-0.003-0.001-0.004-0.002c-59.388-34.366-133.172-34.368-192.561-0.003
				c-0.003,0.002-0.007,0.003-0.01,0.005C38.527,133.231,0,195.607,0,266.603c0,4.143,3.358,7.5,7.5,7.5h141.278
				c3.576,20.924,21.831,36.904,43.758,36.904c22.02,0,39.793-15.259,43.698-36.904h141.338c4.142,0,7.5-3.357,7.5-7.5
				C385.073,195.579,346.522,133.217,288.824,99.842z M148.776,259.103H15.158c2.515-59.71,34.459-112.003,82.184-142.38
				l66.824,115.741C155.961,239.294,150.533,248.928,148.776,259.103z M192.537,296.007c-16.214,0-29.405-13.19-29.405-29.404
				c0-16.191,13.198-29.407,29.407-29.407c15.852,0,29.188,12.725,29.396,29.015C221.868,281.12,210.877,296.007,192.537,296.007z
				 M207.924,224.951c-9.574-3.541-20.619-3.758-30.775,0l-66.823-115.74c51.35-26.866,113.072-26.866,164.421,0L207.924,224.951z
				 M233.704,249.64c-1.333-3.229-3.06-6.304-5.168-9.162l20.29-5.295L233.704,249.64z M245.514,259.102l34.574-33.056
				c5.568-5.325,0.386-14.622-7.077-12.678l-48.365,12.622l63.086-109.268c47.719,30.373,79.669,82.663,82.184,142.38H245.514z"
                    />
                  </g>
                </g>
              </g>
            </svg>
          </div>
          {isExtended && (
            <div
              className={`${
                heading[0] === "Create" ? "icon-text-active" : "icon-text"
              }`}
            >
              Create
            </div>
          )}
        </div>

        <div
          className={`menu-row ${heading[0] === "Calibrate" ? "active_tab" : ""}`}
          onClick={() => {
            // setHeading(['Audience']); setActiveTab(2);
            dispatch(estimateUnset());
            navigate("/dashboard/explore");
          }}
        >
          <div className="icon">
            <svg
              height="24px"
              width="24px"
              version="1.1"
              id="Capa_1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 415.59 415.59"
            >
              <g>
                <path
                  fill="none"
                  d="M207.795,24C106.451,24,24,106.45,24,207.793c0,101.346,82.451,183.797,183.795,183.797
		c101.344,0,183.795-82.451,183.795-183.797C391.59,106.45,309.139,24,207.795,24z M322.508,109.027l-0.098,0.181
		c-0.137,0.283-0.287,0.558-0.445,0.83l-72.305,134.701c-1.115,2.073-2.814,3.773-4.889,4.887l-135.131,72.572
		c-0.048,0.026-0.096,0.053-0.145,0.078l-0.051,0.027l-0.002-0.003c-3.391,1.735-7.548,1.836-11.156-0.105
		c-1.06-0.569-2-1.275-2.809-2.085c-3.648-3.648-4.648-9.389-2.08-14.166l72.596-135.034c1.114-2.073,2.813-3.773,4.887-4.888
		l135.065-72.625c5.838-3.139,13.113-0.952,16.252,4.887c1.861,3.463,1.836,7.427,0.299,10.738L322.508,109.027z"
                />
                <polygon
                  fill="none"
                  points="133.371,282.278 219.172,236.141 179.502,196.47 	"
                />
                <path
                  fill={`${heading[0] === "Calibrate" ? "#FFFFFF" : "#B8B8B8"}`}
                  d="M207.795,0C93.217,0,0,93.216,0,207.793C0,322.372,93.217,415.59,207.795,415.59
		c114.578,0,207.795-93.218,207.795-207.797C415.59,93.216,322.374,0,207.795,0z M207.795,391.59
		C106.451,391.59,24,309.139,24,207.793C24,106.45,106.451,24,207.795,24C309.139,24,391.59,106.45,391.59,207.793
		C391.59,309.139,309.139,391.59,207.795,391.59z"
                />
                <polygon
                  fill={`${heading[0] === "Calibrate" ? "#FFFFFF" : "#B8B8B8"}`}
                  points="236.153,219.179 282.282,133.371 196.48,179.507 	"
                />
                <path
                  fill={`${heading[0] === "Calibrate" ? "#FFFFFF" : "#B8B8B8"}`}
                  d="M322.198,98.284c-3.139-5.839-10.414-8.025-16.252-4.887l-135.065,72.625
		c-2.073,1.115-3.773,2.814-4.887,4.888L93.398,305.945c-2.568,4.777-1.568,10.518,2.08,14.166c0.809,0.81,1.748,1.516,2.809,2.085
		c3.608,1.94,7.766,1.84,11.156,0.105l0.002,0.003l0.051-0.027c0.049-0.025,0.097-0.052,0.145-0.078l135.131-72.572
		c2.074-1.114,3.773-2.813,4.889-4.887l72.305-134.701c0.158-0.272,0.309-0.547,0.445-0.83l0.098-0.181l-0.012-0.004
		C324.034,105.711,324.059,101.747,322.198,98.284z M133.371,282.278l46.131-85.808l39.67,39.671L133.371,282.278z M236.153,219.179
		l-39.672-39.672l85.801-46.136L236.153,219.179z"
                />
              </g>
            </svg>
          </div>
          {isExtended && (
            <div
              className={`${
                heading[0] === "Calibrate" ? "icon-text-active" : "icon-text"
              }`}
            >
              Calibrate
            </div>
          )}
        </div>
        
        
        <div
          className={`menu-row ${
            heading[0] === "Audience" ? "active_tab" : ""
          }`}
          onClick={() => {
            // setHeading(['Audience']); setActiveTab(2);
            dispatch(audienceUnset());
            navigate("/dashboard/audience");
          }}
        >
          <div className="icon">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="Icon">
                <g id="Group 48">
                  <path
                    id="Vector (Stroke)"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M12 1.53488C6.77256 1.53488 2.53488 5.77256 2.53488 11C2.53488 16.2274 6.77256 20.4651 12 20.4651C17.2274 20.4651 21.4651 16.2274 21.4651 11C21.4651 5.77256 17.2274 1.53488 12 1.53488ZM1 11C1 4.92487 5.92487 0 12 0C18.0751 0 23 4.92487 23 11C23 17.0751 18.0751 22 12 22C5.92487 22 1 17.0751 1 11Z"
                    fill={`${
                      heading[0] === "Audience" ? "#FFFFFF" : "#B8B8B8"
                    }`}
                  />
                  <path
                    id="Vector (Stroke)_2"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M11.9914 6.65109C10.9994 6.65109 10.1971 7.45385 10.1971 8.44179C10.1971 9.42972 10.9994 10.2325 11.9914 10.2325C12.9834 10.2325 13.7857 9.42972 13.7857 8.44179C13.7857 7.45386 12.9834 6.65109 11.9914 6.65109ZM8.66217 8.44179C8.66217 6.60409 10.1538 5.11621 11.9914 5.11621C13.829 5.11621 15.3206 6.60409 15.3206 8.44179C15.3206 10.2795 13.829 11.7674 11.9914 11.7674C10.1538 11.7674 8.66217 10.2795 8.66217 8.44179ZM16.0404 16.6363C13.9466 14.3645 10.0486 14.4487 7.95048 16.6462L6.84033 15.5862C9.5141 12.7858 14.4396 12.6346 17.169 15.5961L16.0404 16.6363Z"
                    fill={`${
                      heading[0] === "Audience" ? "#FFFFFF" : "#B8B8B8"
                    }`}
                  />
                </g>
              </g>
            </svg>
          </div>
          {isExtended && (
            <div
              className={`${
                heading[0] === "Audience" ? "icon-text-active" : "icon-text"
              }`}
            >
              Audience
            </div>
          )}
        </div>

        <div
          className={`menu-row ${
            heading[0] === "Campaign" ? "active_tab" : ""
          }`}
          onClick={() => {
            // setHeading(['Campaign']); setActiveTab(3);
            navigate("/dashboard/campaign");
          }}
        >
          <div className="icon">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="Icon">
                <g id="Group 44">
                  <path
                    id="Vector (Stroke)"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M15.5924 4.42856C14.9308 5.75168 14.5 7.63205 14.5 9.75C14.5 11.868 14.9308 13.7483 15.5924 15.0714C16.2764 16.4394 17.0826 17 17.75 17C18.4174 17 19.2236 16.4394 19.9076 15.0714C20.5692 13.7483 21 11.868 21 9.75C21 7.63205 20.5692 5.75168 19.9076 4.42856C19.2236 3.06057 18.4174 2.5 17.75 2.5C17.0826 2.5 16.2764 3.06057 15.5924 4.42856ZM14.2508 3.75774C15.0145 2.23029 16.2083 1 17.75 1C19.2917 1 20.4855 2.23029 21.2492 3.75774C22.0354 5.33004 22.5 7.44967 22.5 9.75C22.5 12.0503 22.0354 14.17 21.2492 15.7423C20.4855 17.2697 19.2917 18.5 17.75 18.5C16.2083 18.5 15.0145 17.2697 14.2508 15.7423C13.4646 14.17 13 12.0503 13 9.75C13 7.44967 13.4646 5.33004 14.2508 3.75774Z"
                    fill={`${
                      heading[0] === "Campaign" ? "#FFFFFF" : "#B8B8B8"
                    }`}
                  />
                  <path
                    id="Vector (Stroke)_2"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M11.1305 2.40837C13.5827 1.63117 16.0684 1 17.75 1V2.5C16.3285 2.5 14.0467 3.05764 11.5837 3.83827C9.15095 4.60932 6.63849 5.56527 4.79935 6.30018C3.40245 6.85836 2.5 8.22434 2.5 9.75C2.5 11.2757 3.40245 12.6416 4.79936 13.1999L4.52105 13.8963L4.79936 13.1999C6.63851 13.9347 9.15095 14.8907 11.5837 15.6617C14.0467 16.4424 16.3285 17 17.75 17V18.5C16.0684 18.5 13.5827 17.8688 11.1305 17.0916C8.64814 16.3049 6.09768 15.334 4.24276 14.5928L4.24274 14.5928C2.25639 13.799 1 11.8677 1 9.75C1 7.6323 2.25639 5.701 4.24275 4.90726L4.51178 5.58052L4.24275 4.90726C6.09768 4.16606 8.64814 3.19515 11.1305 2.40837Z"
                    fill={`${
                      heading[0] === "Campaign" ? "#FFFFFF" : "#B8B8B8"
                    }`}
                  />
                  <path
                    id="Vector (Stroke)_3"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M6.53979 14.8421C6.27822 16.9538 7.32391 19.0079 9.1684 20.0232L9.16842 20.0232L11.1117 21.0929L10.3883 22.4069L8.44508 21.3372C8.44508 21.3372 8.44507 21.3372 8.44507 21.3372C6.05803 20.0233 4.71486 17.3728 5.05116 14.6577L6.53979 14.8421Z"
                    fill={`${
                      heading[0] === "Campaign" ? "#FFFFFF" : "#B8B8B8"
                    }`}
                  />
                </g>
              </g>
            </svg>
          </div>
          {isExtended && (
            <div
              className={`${
                heading[0] === "Campaign" ? "icon-text-active" : "icon-text"
              }`}
            >
              Campaign
            </div>
          )}
        </div>
      
        <div
          className={`menu-row ${heading[0] === "Report" ? "active_tab" : ""}`}
          onClick={() => {
            navigate("/dashboard/report");
          }}
        >
          <div className="icon">
            <svg
              fill={`${heading[0] === "Report" ? "#FFFFFF" : "#B8B8B8"}`}
              version="1.1"
              id="Capa_1"
              width="20px"
              height="20px"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 192.287 192.287"
            >
              <g>
                <path
                  d="M122.901,0H19.699v192.287h152.889v-142.6L122.901,0z M146.981,45.299h-19.686V25.612L146.981,45.299z M34.699,177.287V15
		h77.596v37.799c0,4.142,3.357,7.5,7.5,7.5h37.793v116.988H34.699z"
                />
                <rect x="53.141" y="149.004" width="86.006" height="10" />
                <rect x="53.141" y="55.101" width="51.058" height="10" />
                <polygon
                  points="121.248,86.935 126.79,86.935 105.371,108.353 88.623,91.605 51.597,128.634 58.667,135.706 88.623,105.748 
		105.371,122.495 133.861,94.005 133.861,99.535 143.861,99.535 143.861,76.935 121.248,76.935 	"
                />
                <rect x="53.141" y="33.283" width="51.058" height="10" />
              </g>
            </svg>
          </div>
          {isExtended && (
            <div
              className={`${
                heading[0] === "Report" ? "icon-text-active" : "icon-text"
              }`}
            >
              Reports
            </div>
          )}
        </div>
      </div>
      <div>
        <FullStory org={FULLSTORY_ORG_ID || ""} />
      </div>
    </StyledLeftPanel>
  );
};

export default LeftPanel;
