import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartOptions,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

interface HorizontalBarChartProps {
  label: string;
  labels: {id: number, value: string}[];
  dataVal: {id: number, value: number}[];
  filter: number[];
}

const HorizontalBarChart: React.FC<HorizontalBarChartProps> = ({
  label,
  labels: tmpLabels,
  dataVal: dataValues,
  filter
}) => {
  const dataVal = dataValues.filter(item => filter.includes(item.id) ).map(item => item.value);
  const labels = tmpLabels.filter(item => filter.includes(item.id)).map(item => item.value);
  const combinedData = labels.map((label, index) => ({
    label,
    value: dataVal[index],
  }));

  // Sort combinedData by value in descending order
  combinedData.sort((a, b) => b.value - a.value);

  // Separate the sorted data back into labels and dataVal
  const sortedLabels = combinedData.map((item) =>
    item.label.length > 30 ? item.label.substring(0, 30) + ".." : item.label
  );
  const sortedDataVal = combinedData.map((item) => item.value);

  const data = {
    labels: sortedLabels,
    datasets: [
      {
        label: label,
        data: sortedDataVal,
        backgroundColor: ["#FF6384"],
        // borderColor: [
        //   "rgba(255, 99, 132, 1)",
        //   "rgba(54, 162, 235, 1)",
        //   "rgba(255, 206, 86, 1)",
        //   "rgba(75, 192, 192, 1)",
        //   "rgba(153, 102, 255, 1)",
        //   "rgba(255, 159, 64, 1)",
        //   "rgba(255, 99, 132, 1)",
        // ],
        borderWidth: 0,
        barThickness: 10,
      },
    ],
  };

  const options: ChartOptions<"bar"> = {
    indexAxis: "y",
    scales: {
      x: {
        beginAtZero: true,
        ticks: {
          callback: function (value) {
            return label !== 'Absolute Difference' ? `${((value as number) * 100).toFixed(0)}%`: (value as number).toFixed(2); // Convert the value to percentage
          },
        },
      },
      y: {
        grid: {
          display: false, // Remove horizontal grid lines
        },
        ticks: {
          autoSkip: false,
        },
      },
    },
    plugins: {
      legend: {
        display: false, // Disable the legend display
      },
    },
    maintainAspectRatio: false,
  };

  return (
    <div
      style={{
        height: `${Math.max(labels.length * 15 + 50, 0)}px`,
        minWidth: "250px",
      }}
    >
      <Bar data={data} options={options} />
    </div>
  );
};

export default HorizontalBarChart;
