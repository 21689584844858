import React, { useEffect, useState } from "react";
import StyledMethod from "./styled";
import { set } from "services/reducer/estimate";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "services/store";
import diyOffImg from "assets/create-estimate/DIY OFF-min.png";
import diyOnImg from "assets/create-estimate/DIY on-min.png";
import aiOffImg from "assets/create-estimate/AI Off-min.png";
import aiOnImg from "assets/create-estimate/AI on-min.png";
import urlOffImg from "assets/create-estimate/URL off-min.png";
import urlOnImg from "assets/create-estimate/URL on-min.png";

interface Props {
  setHeading: (val: String[]) => void;
}

const Method: React.FunctionComponent<Props> = ({setHeading}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const estimate = useSelector((state: RootState) => ({
    method: state.estimate.method,
    estimateName: state.estimate.estimateName,
  }));
  const loginInfo = useSelector((state: RootState) => ({
    first_name: state.login.first_name,
  }));

  const [error, setError] = useState("");
  const country = useSelector((state: RootState) => state.home.country);

  const handleNext = () => {
    if (!estimate.estimateName) {
      setError("Please enter a valid estimate name");
      return;
    }
    dispatch(set({ activeTab: 1 }));
    navigate("/dashboard/estimate/create/define");
  };

  useEffect(() => {
    setHeading(["Create", country.charAt(0) + country.substring(1).toLowerCase(), "Method"]);

  }, []);

  return (
    <StyledMethod>
      <div>
        <b className="welcome-title">Welcome {loginInfo.first_name}</b>

        <div className="tell-us-about">
          Let's get started, Tell us about your estimate and we'll tailor the
          output to your specification.
        </div>
        <div className="aud-name">Name your estimate </div>
        <div className={error ? "aud-name-inp error" : "aud-name-inp"}>
          <input
            type="text"
            className="aud-name-input"
            value={estimate.estimateName}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              dispatch(set({ estimateName: e.target.value }));
              setError("");
            }}
          />
        </div>
        {error && <div className="error-msg">{error}</div>}
      </div>

      <div>
        <div className="aud-name">
          How would you like to create your estimate?{" "}
        </div>
        <div className="img-ctn">
          <div className={estimate.method === 1 ? "image-container" : ""}>
            <img
              src={estimate.method === 1 ? aiOnImg : aiOffImg}
              alt="AI"
              className={
                estimate.method === 1 ? "methodImg active-img" : "methodImg"
              }
              onClick={() => {
                dispatch(set({ method: 1 }));
              }}
            />
          </div>
          <div className={estimate.method === 2 ? "image-container" : ""}>
            <img
              src={estimate.method === 2 ? diyOnImg : diyOffImg}
              alt="DIY"
              className={
                estimate.method === 2 ? "methodImg active-img" : "methodImg"
              }
              onClick={() => {
                dispatch(set({ method: 2 }));
              }}
            />
          </div>
          <div className={estimate.method === 3 ? "image-container" : ""}>
            <img
              src={estimate.method === 3 ? urlOnImg : urlOffImg}
              alt="URL"
              className={
                estimate.method === 3 ? "methodImg active-img" : "methodImg"
              }
              onClick={() => {
                dispatch(set({ method: 3 }));
              }}
            />
          </div>
        </div>

        {estimate.method < 3 && (
          <div className="tooltip-ctn">
            <div className="img-icon-ctn">
              <img
                src={estimate.method === 1 ? aiOnImg : diyOnImg}
                alt=""
                className="img-icon"
              />
            </div>
            <div>
              {estimate.method === 1
                ? "Create your estimate from VaticAI's generated keywords"
                : "Provide your own list of keywords to identify a target estimate"}
            </div>
          </div>
        )}
      </div>

      <div className="continue-btn-container">
        <div className="continue-btn" onClick={handleNext}>
          Continue
        </div>
      </div>
    </StyledMethod>
  );
};

export default Method;
