import styled from "styled-components";

const StyledLeft = styled.div`
  padding-left:10px;
  .row {
    display: flex;
    align-items: center;
    height: 28px;
    cursor: pointer;
  }

  .num {
    width: 28px;
    height: 100%;
    border-radius: 50%;
    fill: #fff;
    stroke-width: 1px;
    stroke: #cdd1d8;
    border: 1px solid #cdd1d8;
    display: flex;
    justify-content: center;
    align-items: center;

    color: #82868f;
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-right: 10px;
  }

  .num-active {
    width: 28px;
    height: 100%;
    border-radius: 50%;
    fill: #fff;
    stroke-width: 1px;
    stroke: #cdd1d8;
    border: 1px solid #4485f4;
    display: flex;
    justify-content: center;
    align-items: center;

    color: #4485f4;
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-right: 10px;
  }

  .name {
    color: #82868f;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .name-active {
    color: #000;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .ver-line {
    width: 1px;
    height: 22px;
    background: #e1e6ef;
    margin-left: 14px;
  }

  .ver-line-active {
    width: 1px;
    height: 22px;
    background: #4485f4;
    margin-left: 14px;
  }
`;

export default StyledLeft;
