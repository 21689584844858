import styled from "styled-components";

const StyledExplore = styled.div`
  width: 95%;
  // max-width: 1500px;
  max-height: 80vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  padding: 10px 2%;
  gap: 10px;
  // justify-content: center;

  .dim-container {
    display: flex;
    flex-direction: row;
    gap: 10px;

    overflow-x: auto;
    width: 100%;
  }

  .dimension-card {
    background-color: #dcdcdc;
    width: fit-content;
    border-radius: 5px;
    word-break: break-word;
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;

    min-width: 200px;
  }

  .graph-ctn {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    // justify-content: center;
    width: 100%;
    align-items: flex-start;
  }

  .grpah-item {
    width: 45%;
    // margin: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    // align-items: center;
    gap: 10px;
    height: 100%;
  }

  .ctn1 {
    display: flex;
    flex-direction: column;
    gap: 10px;
    // align-items: center;
    width: 100%;
  }

  .ctn2 {
    width: 90%;
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
    gap: 10px;
  }

  .text1 {
    font-size: 16px;
    font-weight: 500;
    display: flex;
    justify-content: center;
  }

  .ctn3 {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  .ctn4 {
    display: flex;
    font-size: 14px;
    align-items: center;
    // width: 100%;
    // justify-content: center;
  }

  .select {
    padding: 5px;
    font-size: 14px;
    outline: none;
    box-shadow: none;
    border-radius: 5px;
    min-width: 250px;
  }

  .ctn5 {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    width: 90%;
  }

  .mb-0 {
    margin-bottom: 0px;
  }

  .op-4 {
    opacity: 0.4;
  }

  .ctn-gc {
    width: 99%;
    display: grid;
    grid-template-columns: 70% 30%;
    flex-wrap: wrap;
  }

  .calibrator-ctn {
    border: 1px solid #dcdcdc;
    padding: 20px 5% 30px 5%;
    display: flex;
    flex-direction: column;
    gap: 30px;
    border-radius: 5px;
  }

  .num-ctn {
    width: 100%;
    margin: 5px 0px;
  }

  .num {
    border: 1px solid black;
    border-right: none;
    padding: 2px 8px;
    cursor: pointer;
  }

  .border-right {
    border-right: 1px solid black;
  }

  .num:hover {
    opacity: 0.5;
  }

  .text-center {
    tex-align: center;
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 5px;
  }

  .num-active {
    background: #0865ff;
    color: #fff;
  }

  .graph-ctn2 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    width: 100%;
    margin: 10px 0;
  }

  .ctn-6 {
    width: 250px;
  }

  .show-btn-ctn {
    display: flex;
    justify-content: space-between;
  }

  .show-btn {
    padding: 5px 10px;
    background: cyan;
    cursor: pointer;
    border-radius: 5px;
    font-size: 12px;
  }

  .show-btn: hover {
    opacity: 0.8;
  }

  .bullyeye-ctn {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .dimension-select {
    margin-top: 25px;
  }

  .head {
    cursor: pointer;
    background: #c6dafd;
    padding: 5px 10px;
    display: flex;
    align-item: center;
    justify-content: space-between;
    font-size: 14px;
    font-weight: medium;
    margin-left: 5px;
  }

  .img {
    width: 20px;
    height: 20px;
  }

  .text {
    display: flex;
    align-items: center;
    color: black;
  }

  .ctn-head {
    display: grid;
    flex-wrap: wrap;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
}
  .generate-audience-btn {
    border-radius: 8px;
    font-size: 15px;
    border: 1px solid rgba(220, 227, 227, 0);
    background: #4485f4;
    display: inline-flex;
    padding: 8px 12px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    color: #fff;
  }
`;

export default StyledExplore;
