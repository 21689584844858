import React from "react";
import star from "assets/Star/star.svg";
import logo from "assets/VaticLogo/vatic.png";
import bankOfAmericaLogo from "assets/bankofAmerica_logo.png";
import citiBankLogo from "assets/citi_bank_logo.png";
import googleLogo from "assets/google_logo.png";
import hsbcLogo from "assets/hsbc_logo.png";
import tick from "assets/tick.svg";
import StyledAuthLeftScreen from "./styled";
import FullStory from 'react-fullstory';
import {FULLSTORY_ORG_ID} from "../../../utils/axios_helper";

const SignUpLeftPanel = () => {
  return (
    <StyledAuthLeftScreen>
      <div className="container">
        <div className="logo-cont">
          <img src={logo} alt="Qscore Logo" className="logo" />
        </div>
        <div className="right">
          <div className="quotes">
            “ Discover the power of precision with our Audience-as-a-Service (AaaS), a streamlined solution designed to enhance your advertising impact while maintaining flexibility and control. ”
          </div>
          <div className="rating-box">
            <div className="user-name">John Smith</div>
            <div className="star-box">
              <img src={star} className="star" />
              <img src={star} className="star" />
              <img src={star} className="star" />
              <img src={star} className="star" />
              <img src={star} className="star" />
            </div>
          </div>
          <div className="designation">Founder, AutoMeta LLC</div>
        </div>

        <div className="benefit-box">
          <div className="benefit-row">
            <img src={tick} alt="-" />
            <div className="benefit-text">Generate pre-qualified financial audiences</div>
          </div>
          <div className="benefit-row">
            <img src={tick} alt="-" />
            <div className="benefit-text">Maintain full control over your strategies</div>
          </div>
          <div className="benefit-row">
            <img src={tick} alt="-" />
            <div className="benefit-text">Enhance campaign results</div>
          </div>
          <div className="benefit-row">
            <img src={tick} alt="-" />
            <div className="benefit-text">Multichannel integrations</div>
          </div>
        </div>

        <div className="text">You’re in good company:</div>

        <div className="company-logo-ctn">
          <img src={googleLogo} alt="Google" />
          <img src={hsbcLogo} alt="HSBC" />
          <img src={citiBankLogo} alt="Citi Bank" />
        </div>
        <img src={bankOfAmericaLogo} alt="Bank Of America" />
      </div>
      <div>
        <FullStory org={FULLSTORY_ORG_ID || ''}/>
      </div>
    </StyledAuthLeftScreen>
  );
};

export default SignUpLeftPanel;
