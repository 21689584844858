import React from "react";
import StyledLeft from "./styled";
import { useSelector } from "react-redux";
import { RootState } from "services/store";

const Left = () => {
  const estimate = useSelector((state: RootState) => ({
    method: state.estimate.method,
    activeTab: state.estimate.activeTab,
  }));

  const divLabels1 = [
    {
      tabnum: 0,
      name: "Method",
    },
    {
      tabnum: 1,
      name: "Define",
    },
    {
      tabnum: 2,
      name: "Keywords",
    },
  ];

  const divLabels2 = [
    {
      tabnum: 0,
      name: "Method",
    },
    {
      tabnum: 1,
      name: "Define",
    },
  ];

  return (
    <StyledLeft>
      {estimate.method === 1
        ? divLabels1.map((item, ind) => (
            <div key={ind}>
              <div key={ind} className="row">
                <div
                  className={
                    estimate.activeTab >= item.tabnum ? "num-active" : "num"
                  }
                >
                  {ind + 1}
                </div>

                <span
                  className={
                    estimate.activeTab >= item.tabnum ? "name-active" : "name"
                  }
                >
                  {item.name}
                </span>
              </div>
              {item.tabnum + 1 < divLabels1.length && (
                <div
                  className={
                    estimate.activeTab >= item.tabnum
                      ? "ver-line-active"
                      : "ver-line"
                  }
                ></div>
              )}
            </div>
          ))
        : divLabels2.map((item, ind) => (
            <div key={ind}>
              <div key={ind} className="row">
                <div
                  className={
                    estimate.activeTab >= item.tabnum ? "num-active" : "num"
                  }
                >
                  {ind + 1}
                </div>

                <span
                  className={
                    estimate.activeTab >= item.tabnum ? "name-active" : "name"
                  }
                >
                  {item.name}
                </span>
              </div>
              {item.tabnum + 1 < divLabels2.length && (
                <div
                  className={
                    estimate.activeTab >= item.tabnum
                      ? "ver-line-active"
                      : "ver-line"
                  }
                ></div>
              )}
            </div>
          ))}
    </StyledLeft>
  );
};

export default Left;
