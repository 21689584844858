import styled from "styled-components";

const StyledOrganization = styled.div`
    width: 100%;
    height: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5% 0;

    .error {
        font-size: 10px;
        color: red;
    }

    .create-org-ctn {
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: center;
    }

    .label-ctn {
        display: flex;
        gap: 10px;
    }

    .create-btn {
        padding: 5px 10px;
        border: 1px solid transparent;
        background: #4484F3;
        color: #FFF;
        border-radius: 8px;
        cursor: pointer;
    }

    .create-btn:hover {
        opacity: 0.8;
    }

    table {
        width: 500px;
        margin: 10px;
        border-collapse: collapse;
    }

    th, td {
        border: none;
        text-align: center;
        border-bottom: 1px solid #b8bebf;
        padding: 5px 0px;
    }

    .invite-ctn {
        margin-top: 50px;
        display: flex;
        flex-direction: column;
        gap: 5px;
        align-items: center;
    }

    .invite {
        margin-top: 10px;
    }

    .add {
        padding-left: 20px;
        padding-right: 20px;
    }

    input {
        outline: none;
        box-shadow: none;
        padding: 5px 10px;
    }

    .email-box {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        gap: 30px;
    }

    .remove {
        color: red;
        border-radius: 50%;
        font-size: 12px;
        width: 20px;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        line-height: 14px;
        padding: 0px;
        margin: 0px;
        cursor: pointer;
    }

    .remove:hover {
        opacity: 0.8;
    }

    .heading {
        font-size: 18px;
        font-weight: 500;
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    .dashboard {
        display: flex;
        justify-content: flex-start;
        width: 50%;
        margin: 20px;
    }
`;

export default StyledOrganization;