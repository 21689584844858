import styled from "styled-components";

const StyledCreateAudience = styled.div`
  width: 100%;
  .container {
    display: grid;
    grid-template-columns: 1fr 3fr;
  }
`;

export default StyledCreateAudience;
