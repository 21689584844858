import StyledInviteTeam, { df } from "./styled";
import backImg from "assets/Back.svg";
import avtar from "assets/avtar.svg";
import downArr from "assets/down-arrow.svg";
import React from "react";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "services/store";
import { unset } from "services/reducer/signup";
import {FULLSTORY_ORG_ID, vaticAxiosPost} from "utils/axios_helper";
import { useNavigate } from "react-router";
import FullStory from "react-fullstory";

interface InviteTeamProps {
    setShowInvite: (val: boolean) => void;
}

const InviteTeam: React.FunctionComponent<InviteTeamProps> = ({ setShowInvite }) => {
    const signupInfo = useSelector((state: RootState) => ({ 'company_name': state.signup.company_name, 'first_name': state.signup.first_name, 'last_name': state.signup.last_name, 'email': state.signup.email, 'password': state.signup.password }))
    const dispatch = useDispatch()
    const navigate = useNavigate();

    const register = async () => {
        const referToken = localStorage.getItem('refer_token') || '';
        const res = await vaticAxiosPost("/api/auth/v1/register", {...signupInfo, refer_token: referToken});
        if (res.statusCode == 200) {
            toast.success(res.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 1000,
            });
            toast.success('Please verify your email', {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 1000,
            });
            dispatch(unset())
            navigate('/auth/login');
        } else {
            toast.error(`${res.message}`, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 1000
            })
        }
    }
    return (
        <div style={df}>
            <StyledInviteTeam>
                <div className="container">
                    <img src={backImg} alt="Back" className="back-ctn" onClick={() => setShowInvite(false)}/>
                    <div className="invite-heading">
                        Invite your team
                    </div>
                    <div className="people-ctn">
                        <div className="ctn-1">
                            <img src={avtar} alt="img" className="user-img"/>
                            <div className="ctn-user">
                                <div className="user-name">John Smith</div>
                                <div className="user-email">john@companyname.com</div>
                            </div>
                        </div>
                        <div className="user-level">
                            Owner
                        </div>
                    </div>
                    <div className="line"></div>
                    <div className="invite-txt">Invite</div>
                    <div className="email-ctn">
                        <input className="email" placeholder="Enter email address"/>
                        <div className="select-level-ctn">
                            <div className="user-level">Owner</div>
                            <img src={downArr} alt="level" className="down-arr"/>
                        </div>
                    </div>
                    <div className="btn" onClick={register}>
                        Create account
                    </div>
                    <div className="skip-btn">Skip</div>
                </div>
                <div>
                    <FullStory org={FULLSTORY_ORG_ID || ''}/>
                </div>
            </StyledInviteTeam>
        </div>
    )
}

export default InviteTeam;