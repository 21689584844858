import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface google_ads_cred {
  id: number;
  name: string;
}

export interface meta_ads_cred {
  id: string;
  name: string;
}

export interface x_ads_cred {
  id: string;
  name: string;
}

export interface tiktok_ads_cred {
  id: string;
  name: string;
}

export interface CampaignInterface {
  name: string;
  client: string;
  platforms: string[];
  checked: boolean;
  id: number | string;
  acc_id: number;
}

export interface PlatformState {
  google: google_ads_cred[];
  meta: meta_ads_cred[];
  x: x_ads_cred[];
  campaigns: CampaignInterface[];
  tiktok: tiktok_ads_cred[];
}

const initialState: PlatformState = {
  google: [],
  meta: [],
  x: [],
  campaigns: [],
  tiktok: [],
};

export const platformSlice = createSlice({
  name: "platform",
  initialState,
  reducers: {
    set: (state, action: PayloadAction<Partial<PlatformState>>) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    unset: (state) => {
      return {
        ...initialState,
      };
    },
  },
});

export const { set, unset } = platformSlice.actions;
export default platformSlice.reducer;
