import React, { useEffect, useState } from "react";
import StyledCampaign from "./styled";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { set } from "services/reducer/audience";
import { Campaign as CampaignsInterface } from "services/reducer/audience";
import { RootState } from "services/store";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { vaticAxiosPost } from "utils/axios_helper";

interface Props {
  setHeading: (val: String[]) => void;
}

const Campaign: React.FunctionComponent<Props> = ({setHeading}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const audience = useSelector((state: RootState) => ({
    campaigns: state.audience.campaigns,
    audience_id: state.audience.curr_audience_id,
    audienceName: state.audience.aud_name,
    selectedGoogleAdAcc: state.audience.selected_google_ad_acc,
    selectedMetaAdAcc: state.audience.selected_meta_ad_acc,
    selectedXAdAcc: state.audience.selected_x_ad_acc,
    selectedTiktokAdAcc: state.audience.selected_tiktok_ad_acc,
  }));
  const countryDb = useSelector((state: RootState) => state.home.countryDb);
  const country = useSelector((state: RootState) => state.home.country);

  const [selectAll, setSelectAll] = useState(false);

  const select_all_campaigns = () => {
    const newSelectAll = !selectAll;
    setSelectAll(newSelectAll);
    const updatedCampaigns = audience.campaigns
      .filter(
        (i) =>
          audience.selectedGoogleAdAcc[i.acc_id] ||
          audience.selectedMetaAdAcc[i.acc_id] ||
          audience.selectedXAdAcc[i.acc_id] ||
          audience.selectedTiktokAdAcc
      )
      .map((campaign) => ({
        ...campaign,
        checked: newSelectAll,
      }));
    dispatch(set({ campaigns: updatedCampaigns }));
  };

  const toggleCampaignSelection = (id: any) => {
    const updatedCampaigns = audience.campaigns.map((campaign) => {
      if (campaign.id === id) {
        return { ...campaign, checked: !campaign.checked };
      }
      return campaign;
    });
    dispatch(set({ campaigns: updatedCampaigns }));

    // const allSelected = updatedCampaigns.every(campaign => campaign.checked);
    // setSelectAll(allSelected);
  };

  const handle_next = async () => {
    const selected_campaigns = audience.campaigns.filter(
      (item) => item.checked
    );
    if (selected_campaigns.length === 0) {
      toast.error(`You must select atlease one campaign`, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
      return;
    }

    navigate("/dashboard/audience/create/summary");
  };

  useEffect(() => {
    dispatch(set({ activeTab: 1 }));
    setHeading(["Audience", country.charAt(0) + country.substring(1).toLowerCase(), "Create", "Campaign"]);
  }, []);

  return (
    <StyledCampaign>
      <div className="container">
        <b className="campaign-selection">Campaign Selection</b>

        <div className="connect-and-choose">
          Connect and choose platform for your created audience.
        </div>

        <div className="created-audience">Created audience:</div>

        <div className="banking-loans">{audience.audienceName}</div>

        <div className="select-campaigns-2-container">
          <span>Select campaigns &nbsp;</span>
          <span className="span">
            {
              audience.campaigns.filter(
                (i) =>
                  i.checked &&
                  (audience.selectedGoogleAdAcc[i.acc_id] ||
                    audience.selectedMetaAdAcc[i.acc_id] ||
                    audience.selectedXAdAcc[i.acc_id] || 
                    audience.selectedTiktokAdAcc[i.acc_id]
                  )
              ).length
            }
          </span>
        </div>

        <div className="keyword-table-ctn">
          <div className="campaign-table-h">
            <div className="campaign-table-select-all">
              <input
                type="checkbox"
                onClick={() => {
                  select_all_campaigns();
                }}
              ></input>
              <div className="campaign-table-title">All</div>
            </div>
            <div className="campaign-table-title">Name</div>
            <div className="campaign-table-title">Client</div>
            <div className="campaign-table-title">Platforms</div>
          </div>
          <div className="hor-line"></div>
          <div className="campaign-table-body">
            {audience.campaigns
              .filter(
                (i) =>
                  audience.selectedGoogleAdAcc[i.acc_id] ||
                  audience.selectedMetaAdAcc[i.acc_id] ||
                  audience.selectedXAdAcc[i.acc_id] ||
                  audience.selectedTiktokAdAcc[i.acc_id]
              )
              .map((item, index) => (
                <div
                  className={`campaign-table-row-even ${
                    index % 2 == 0 ? "little-dark-row" : ""
                  }`}
                  key={index}
                >
                  <div className="campaign-checkbox">
                    <input
                      type="checkbox"
                      onClick={() => {
                        toggleCampaignSelection(item.id);
                      }}
                      checked={item.checked}
                    ></input>
                  </div>
                  <div className="campaign-name">{item.name}</div>
                  <div className="campaign-client">{item.client}</div>
                  <div className="campaign-platform">{item.platforms}</div>
                </div>
              ))}
          </div>
        </div>

        <div className="continue-btn-container">
          <div
            className="back-btn"
            onClick={() => {
              navigate("/dashboard/audience/create/platforms");
            }}
          >
            Back
          </div>
          <button
            className="continue-btn"
            type="submit"
            onClick={() => {
              handle_next();
            }}
          >
            Continue
          </button>
        </div>
      </div>
    </StyledCampaign>
  );
};

export default Campaign;
