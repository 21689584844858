import styled from "styled-components";

const StyledLanding = styled.div`
    // display: flex;
    // flex-direction: column;
    // align-items: center;
    // margin: 50px;
    // gap: 20px;

    .nav {
        position: absolute;
        top: 3vh;
        display: flex;
        gap: 800px;
        height: 40px;
    }

    .nav div {
        cursor: pointer;
    }

    .button {
        display: flex;
        gap: 20px;
    }

    .login-button {
        padding: 10px 16px;
        white-space: nowrap;
    }

    .signup-button {
        display: flex; /* Use flexbox */
        align-items: center;
        border-radius: 8px;
        border: 1px solid rgba(220, 227, 227, 0.00);
        background: #4485F4;
        padding: 10px 16px; /* Adjust padding to reduce height */
        color: white;
        cursor: pointer;
        white-space: nowrap;
        line-height: 1; /* Maintain text in center */
    }

    .login-button:hover {
        color: blue; /* Change hover color for login button */
    }

    .signup-button:hover {
        color: green; /* Change hover color for signup button */
    }

    /* .nav div:hover {
        color: blue;
    } */


    .landing-container {
        position: relative;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;

    }

    .landing-text-container {
        position: absolute;
        top: 4%;
        left: 16%;
        width: 650px; /* Adjust width as needed */
    }

    .landing-text {
        color: #FFF;
        font-family: Inter;
        font-size: 52px;
        font-style: normal;
        font-weight: 700;
        line-height: 80px;
    }

    .landing-subtext-container {
        position: absolute;
        top: 32%;
        left: 16%;
        width: 650px;
    }

    .landing-subtext {
        color: #FFF;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
    }

    /* p1,p2 */

    .landing-points-container {
        position: absolute;
        top: 40%;
        left: 16%;
        width: 650px;
    }

    .landing-points {
        display: flex;
        flex-direction: column;
    }

    .point {
        margin-bottom: 10px; /* Adjust spacing between points */
    }

    .point-content {
        display: flex;
        align-items: center;
        margin-right: 10px;
        padding-top: 10px;
    }

    .point-icon {
        width: 15px;
        height: 15px;
        margin-right: 10px;
    }

    .landing-points p {
        color: #FFF;
        font-family: Inter;
        font-size: 13px;
        font-style: normal;
        font-weight: 250;
        line-height: 24px;
        margin: 0;
    }

    /* p3,p4 */

    .landing-point-container {
        position: absolute;
        top: 40%;
        left: 38%;
        width: 650px;
    }

    .landing-point {
        display: flex;
        flex-direction: column;
    }

    .points {
        margin-bottom: 10px; /* Adjust spacing between points */
    }

    .points-content {
        display: flex;
        align-items: center;
        margin-right: 10px;
        padding-top: 10px;
    }

    .points-icon {
        width: 5px;
        height: 5px;
        margin-right: 10px;
    }

    .landing-point p {
        color: #FFF;
        font-family: Inter;
        font-size: 13px;
        font-style: normal;
        font-weight: 250;
        line-height: 24px;
        margin: 0;
    }

    /* button */

    .get-started-container {
        position: absolute;
        top: 52%; /* Adjust vertical position as needed */
        left: 16%; /* Adjust horizontal position as needed */
    }

    .get-started-button {
        display: inline-flex;
        padding: 12px 40px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        border-radius: 8px;
        background: #4485F4;
        color: #fff;
        font-family: Inter;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        text-decoration: none;
        border: none;
        cursor: pointer;
        transition: background 0.3s ease;
    }

    .get-started-button:hover {
        background: #357ae8;
    }

    /* rating */

    .trusted-container {
        position: absolute;
        top: 53%; /* Adjust vertical position as needed */
        left: 34%; /* Adjust horizontal position as needed */
        transform: translateX(-50%);
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .star-rating {
        display: flex;
        align-items: center;
    }

    .star-icon {
        color: #fff;
        font-family: Inter;
        font-size: 13px;
        font-weight: 500;
        margin-right: 5px;
        margin-left: -65px;
    }

    .star-image {
        width: 9px;
        height: 9px;
        margin-right: 5px;
    }

    .trusted-text {
        color: #BDBDBD;
        font-family: Inter;
        font-size: 10px;
        font-weight: 400;
        line-height: 20px;
        margin-top: 5px;
    }


    .landing-home-container {
        position: absolute;
        top: 12%;
        right: -1%;
        height: 76%;
        border-radius: 21.947px;
        border-width: 4px 0px 4px 4px; /* Top, Right, Bottom, Left */
        border-style: solid;
        border-color: #808080;
        overflow: hidden;
    }

    /* reviews */

    .dark-grey-div {
        background-color: #303030;
        height: auto;
        position: absolute;
        top: 63%; /* Adjust the top position as needed */
        left: 16%;
        width: 540px; /* Adjust the width as needed */
        flex-shrink: 0;
        border-radius: 12px;
        opacity: 1;
    }

    .reviews-container {
        padding: 10px;
        text-align: center;
    }

    .review-text {
        color: #FFF;
        font-family: Inter;
        font-size: 13px;
        font-weight: 250;
        line-height: 21px;
        margin-left: 5px;
        text-align: left;
        margin-top: 6px;
    }

    .review-author {
        margin-top: 5px;
        display: flex;
        align-items: left;
        justify-content: left;
        margin-left: 5px;
    }

    .author-image {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        margin-right: 1px;
    }

    .author-name {
        color: #FFF;
        font-family: Inter;
        font-size: 12px;
        font-weight: 500;
        margin-top: 1px;
        margin-left: -147px;
    }

    .author-designation {
        color: #BDBDBD;
        font-family: Inter;
        font-size: 10px;
        font-weight: 400;
        margin-top: -8px;
        margin-left: 5px;
    }

    /* eclipse */

    .below-reviews-container {
        height: 40px;
        width: 130px;
        position: absolute;
        top: 88%;
        left: 16%;
        flex-shrink: 0;
        border-radius: 12px;
        opacity: 1;
    }

    .eclipse-container {
        display: flex;
        justify-content: space-between; /* This will evenly space the images */
    }

    .eclipse-image {
        margin-right: 1px; /* Adjust the margin as needed */
    }


    .landing-home-img {
        margin: 0;
        height: 100%;
        border-radius: 15px;
        object-fit: contain;
    }

    .landing-img {
        width: 100%;
        display: block;
    }

    .landing-img-logo {
        width: 100%;
        height: 56px;
        flex-shrink: 0;
        margin-left: 360px; /* Left margin of 360px */
        margin-top: 16px; /* Top margin of 16px */
    }


    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
        color: white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    .privacy {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
        margin: 10px 0;
    }
    
    .privacy-text {
        cursor: pointer;
    }
    
    .privacy-text:hover {
        color: #4485F4;
    }
`

export default StyledLanding;