import styled from "styled-components";
const StyledInviteTeam = styled.div`
    width: 1040px;
    height: 810px;
    background: linear-gradient(0deg, #FFF 0%, #FFF 100%), linear-gradient(357deg, #00276B 0%, #272727 100%);

    .container {
        display: flex;
        flex-direction: column;
        padding-left: 48px;
    }

    .back-ctn {
        margin-top: 28px;
        width: 40px;
        height: 40px;
        cursor: pointer;
    }

    .invite-heading {
        margin-top: 151px;
        color: #000;
        font-family: Inter;
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: 32px; /* 100% */
    }

    .people-ctn {
        margin-top: 40px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 440px;
    }

    .ctn-1 {
        display: flex;
        align-items: center;
        gap: 16px;
    }

    .user-img {
        width: 48px;
        height: 48px;
    }

    .ctn-user {
        display: flex;
        flex-direction: column;
        gap: 4px;
    }

    .user-name {
        color: #000;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    .user-email {
        color: #82868F;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .user-level {
        color: #000;
        text-align: right;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    .line {
        margin-top: 24px;
        width: 440px;
        height: 1px;
        background: #E3E5E9;
    }

    .invite-txt {
        margin-top: 32px;
        color: #000;
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    .email-ctn {
        margin-top: 4px;
        width: 440px;
        height: 48px;
        border-radius: 8px;
        border: 1px solid #CDD1D8;
        background: #FFF;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .email {
        color: #82868F;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-left: 12px;
        border: none;
        outline: none;
        box-shadow: none;
    }

    .select-level-ctn {
        margin-right: 12px;
        display: flex;
        gap: 2px;
    }

    .down-arr {
        width: 16px;
        height: 16px;
    }

    .btn {
        margin-top: 32px;
        display: inline-flex;
        padding: 13px 0px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        border-radius: 8px;
        border: 1px solid rgba(220, 227, 227, 0.00);
        background: #4485F4;
        width: 440px;
        color: #FFF;
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        cursor: pointer;
    }

    .skip-btn {
        margin-top: 32px;
        width: 440px;
        color: #82868F;
        text-align: center;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

`;

export default StyledInviteTeam;

export const df = {
    display: 'flex'
}