import styled from "styled-components";

const StyledSummary = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 500px;

  .header {
    display: flex;
    align-items: center;
    font-size: 14px;
  }

  .star {
    margin-top: 4px;
  }

  .hor-line-neg {
    //margin-top: -30px;
    height: 1px;
    width: 100%;
    background: #cdd1d8;
  }

  .campaign-row {
    display: grid;
    gap: 20px;
    grid-template-columns: 1fr 1fr;
  }

  .campaign-name {
    margin-top: 8px;
    color: #000;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .campaign-head {
    color: #000;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .campaign-platform {
    margin-top: 8px;
    color: #000;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .t4 {
    color: #000;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .t5 {
    color: #000;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .continue-btn-container {
    position: relative;
    font-weight: 500;
    margin-top: 100px;
    cursor: pointer;
    display: flex;
    gap: 16px;
    fill: #fff;
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.16);
    width: 510px;
    height: 50px;
    flex-shrink: 0;
    padding: 12px 16px;
    border-radius: 8px;
    background: #fff;
  }

  .continue-btn {
    width: 412px;
    position: relative;
    border-radius: 8px;
    background-color: #4485f4;
    border: 1px solid rgba(220, 227, 227, 0);
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 13px 12px;
    text-align: left;
    font-size: 18px;
    color: #fff;
    font-family: Inter;
    cursor: pointer;
  }

  .back-btn {
    border-radius: 8px;
    border: 1px solid #cdd1d8;
    background: #fff;
    display: flex;
    width: 80px;
    padding: 13px 12px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    color: #000;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .p-div {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 500px;
  }
`;

export default StyledSummary;
