import React from "react";
import StyledPrivacy from "./styled";
import {useNavigate} from "react-router";
import FullStory from "react-fullstory";
import {FULLSTORY_ORG_ID} from "../../utils/axios_helper";

const Privacy = () => {
    const navigate = useNavigate();

    return (
        <StyledPrivacy>
            <div className="header">
                <span className="privacy-text">Privacy Policy</span>
            </div>
            <div>
                <div className="text-bold">
                    Introduction
                </div>
                <div className="text">
                    This Privacy Policy explains how Vatic LLC ("we," "us," or "our") collects, uses, and discloses
                    information about you when you use our website
                    ("https://audience.vaticai.com") and related services (collectively, the "Services").
                    This Privacy Policy also addresses our compliance with Google's requirements for using Google Cloud
                    services, Google Limited Use requirements,
                    and the Google API Services User Data Policy.
                </div>
                <div className="text-bold">
                    Information We Collect
                </div>
                <div>
                    <div className="text">We may collect the following types of information about you when you use the
                        Services:
                    </div>
                    <ul>
                        <li>
                            <span className="text-bold-24">Personal Information:</span>
                            <span className="text">This includes information that can identify you, such as your name, email address, and any other information
                                you provide to us when you create an account or use the Services.</span>
                        </li>
                        <li>
                            <span className="text-bold-24">Usage Data:</span>
                            <span className="text">This includes information about how you use the Services, such as the pages you visit, the features you use, and the time and date of your activities.</span>
                        </li>
                        <li>
                            <span className="text-bold-24">Device Information:</span>
                            <span className="text">This includes information about the device you use to access the Services, such as your IP address, browser type, operating system, and device identifiers.</span>
                        </li>
                        <li>
                            <span className="text-bold-24">Log Information:</span>
                            <span className="text">This includes information that is automatically collected when you use the Services, such as your access times, browser type and language, and referring website addresses.</span>
                        </li>
                    </ul>
                </div>
                <div className="text-bold">
                    How We Use Your Information
                </div>
                <div>
                    <div className="text">We may use your information for the following purposes:</div>
                    <ul>
                        <li>
                            <span className="text-bold-24">To provide and maintain the Services:</span>
                            <span className="text">This includes using your information to create and manage your account, process your requests, and provide customer support.</span>
                        </li>
                        <li>
                            <span className="text-bold-24">To personalize your experience:</span>
                            <span className="text">This includes using your information to show you relevant content and recommendations, and to tailor the Services to your preferences.</span>
                        </li>
                        <li>
                            <span className="text-bold-24">To improve the Services:</span>
                            <span className="text">This includes using your information to analyze how the Services are used, diagnose technical issues, and develop new features and functionality.</span>
                        </li>
                        <li>
                            <span className="text-bold-24">To communicate with you:</span>
                            <span className="text">This includes sending you emails and other notifications about the Services, such as updates, announcements, and promotional offers.</span>
                        </li>
                        <li>
                            <span className='text-bold-24'>To comply with legal obligations:</span>
                            <span className="text">This includes using your information to comply with applicable laws and regulations, and to respond to legal requests.</span>
                        </li>
                    </ul>
                </div>
                <div className="text-bold">How We Share Your Information</div>
                <div>
                    <div className="text">We may share your information with the following parties:</div>
                    <ul>
                        <li>
                            <span className="text-bold-24">Service Providers:</span>
                            <span className="text">We may share your information with third-party service providers who help us operate the Services,
                                such as hosting providers, data storage providers, and analytics providers.</span>
                        </li>
                        <li>
                            <span className="text-bold-24">Business Partners:</span>
                            <span className="text">We may share your information with business partners who offer products or services that we believe may be of interest to you.</span>
                        </li>
                        <li>
                            <span className="text-bold-24">Google:</span>
                            <span className="text">We may share your information with Google in accordance with their requirements for using Google Cloud services and Google Limited Use requirements.
                                This includes sharing your information for the purpose of single sign-on authentication.</span>
                        </li>
                        <li>
                            <span className="text-bold-24">Other Parties:</span>
                            <span className="text">We may share your information with other parties when we have your consent or as required by law.</span>
                        </li>
                    </ul>
                </div>
                <div className="text-bold">Google Limited Use Requirements and Google API Services User Data Policy
                </div>
                <div className="text">
                    We comply with Google Limited Use requirements, which restrict our use of your information obtained
                    through Google services to the
                    purposes stated in this Privacy Policy and as permitted by Google's policies. We also adhere to the
                    Google API Services User Data Policy,
                    including the additional requirements for specific API scopes. Please refer to the Google API
                    Services User Data Policy for more information: &nbsp;
                    <a href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes">Google
                        API Service User Data Policy</a>
                </div>
                <div className="text-bold">Your Choices</div>
                <div>
                    <div className="text">You may have the following choices regarding your information:</div>
                    <ul>
                        <li>
                            <span className="text-bold-24">Access and Update:</span>
                            <span className="text">You may access and update your account information by logging into your account.</span>
                        </li>
                        <li>
                            <span className="text-bold-24">Opt-Out:</span>
                            <span className="text">You may opt out of receiving promotional emails from us by following the instructions in those emails.</span>
                        </li>
                    </ul>
                </div>
                <div className="text-bold">Security</div>
                <div className="text">We take reasonable measures to protect your information from unauthorized access,
                    use, or disclosure. However,
                    no method of transmission over the Internet or electronic storage is 100% secure, and we cannot
                    guarantee its absolute security.
                </div>
                <div className="text-bold">Changes to this Privacy Policy</div>
                <div className="text">
                    We may update this Privacy Policy from time to time. If we make material changes, we will notify you
                    by email or through the Services.
                </div>
                <div className="text-bold">
                    Contact Us
                </div>
                <div className="text">
                    If you have any questions about this Privacy Policy, please contact us at privacy@vaticai.com.
                </div>
                <div className="l-30"><span className="text-bold-24">Effective Date: &nbsp;</span><span
                    className='text'>Monday 13th May 2024</span></div>
            </div>
            <div className="footer">
                <div>GET STARTED</div>
                <div className="action-ctn">
                    <span className="action" onClick={() => navigate('/auth/login')}>Log in</span>
                    <span className="action" onClick={() => navigate('/auth/signup')}>Sign up</span>
                </div>
            </div>
            <div>
                <FullStory org={FULLSTORY_ORG_ID || ''}/>
            </div>
        </StyledPrivacy>
    )
}

export default Privacy;