import styled from "styled-components";
import logo from "assets/campaign/logo.png";
import logo2 from "assets/campaign/logo2.png";
import downArrow from "assets/campaign/downArrow.svg";

interface StyledLeftPanelProps {
  maxwidth?: string;
}

const StyledLeftPanel = styled.div<StyledLeftPanelProps>`
  width: ${(props) => props.maxwidth || "220px"};
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background: #303633;

  .logo1 {
    margin: 12px 24px;
    width: 74.667px;
    height: 48px;
    background: url(${logo}) -5.333px -18.133px / 114.286% 177.778% no-repeat;
    cursor: pointer;
  }

  .logo2 {
    margin: 27px 17px;
    width: 38px;
    height: 18px;
    background: url(${logo2}) -10.251px -15.09px / 166.32% 267.559% no-repeat;
    cursor: pointer;
  }

  .hor-line {
    opacity: 0.15;
    background: #fff;
    height: 1px;
  }

  .region-ctn {
    margin: 5px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 90%;
  }

  .region-h {
    color: #b8b8b8;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .region-box {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
  }

  .region-text {
    color: #fff;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .region-arrow {
    width: 16px;
    height: 16px;
    opacity: 0.6;
  }

  .menu-box {
    margin: 48px 8px;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .menu-row {
    height: 40px;
    display: flex;
    align-items: center;
    gap: 12px;
    cursor: pointer;
    width: 204px;
  }

  .icon {
    width: 24px;
    height: 24px;
    padding: 10px;
  }

  .icon-text {
    color: #b8b8b8;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .icon-text-active {
    color: #fff;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .active_tab {
    background-color: #4485f4;
    color: white !important;
    border-radius: 5px;
    width: 100%;
  }

  .custom-select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: url(${downArrow}) no-repeat right 0px center;
    background-size: 12px 12px;
    width: 100%;
    padding: 10px 10px;
    font-size: 16px;
    // border: 2px solid #4caf50;
    border-radius: 4px;
    // background-color: #f1f1f1;
    color: #fff;

    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  /* Customize the option tag */
  .custom-select option {
    padding: 10px;
    background-color: #303633;
    color: #fff;
  }

  /* Hover and focus effects for the select tag */
  .custom-select:hover,
  .custom-select:focus {
    // border-color: #3e8e41;
    outline: none;
  }

  /* Additional styles for the dropdown */
  .custom-select option:hover {
    background-color: #303633;
    color: red;
  }

  .icon-active {
    fill: #fff;
  }
`;

export default StyledLeftPanel;
