import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartOptions,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

interface CompareBarChartProps {
  label: string;
  labels: {id: number, value: string}[];
  initVal: {id: number, value: number}[];
  currVal: {id: number, value: number}[];
  filter: number[];
}

const CompareBarChart: React.FC<CompareBarChartProps> = ({
  label,
  labels: tmpLabels,
  initVal: initValues,
  currVal: currValues,
  filter
}) => {
  const initVal = initValues ? initValues.filter(item => filter.includes(item.id) ).map(item => item.value): [];
  const currVal = currValues ? currValues.filter(item => filter.includes(item.id) ).map(item => item.value): [];
  const labels = tmpLabels ? tmpLabels.filter(item => filter.includes(item.id)).map(item => item.value): [];

  const combinedData = labels.map((label, index) => ({
    label,
    originalValue: initVal[index],
    comparisonValue: currVal[index],
  }));

  // Sort combinedData by the originalValue in descending order
  combinedData.sort((a, b) => b.originalValue - a.originalValue);

  // Separate the sorted data back into labels, dataVal, and newDataVal
  const sortedLabels = combinedData.map((item) =>
    item.label.length > 30 ? item.label.substring(0, 30) + ".." : item.label
  );
  const sortedDataVal = combinedData.map((item) => item.originalValue);
  const sortedNewDataVal = combinedData.map((item) => item.comparisonValue);

  const data = {
    labels: sortedLabels,
    datasets: [
      {
        label: `initial value`,
        data: sortedDataVal,
        backgroundColor: "#FF6384",
        barThickness: 10,
      },
      {
        label: `current value`,
        data: sortedNewDataVal,
        backgroundColor: "#3E7CE5",
        barThickness: 10,
      },
    ],
  };

  const options: ChartOptions<"bar"> = {
    indexAxis: "y",
    scales: {
      x: {
        beginAtZero: true,
        ticks: {
          callback: function (value) {
            return label !== 'Absolute Difference' ? `${((value as number) * 100).toFixed(0)}%`: (value as number).toFixed(2); // Convert the value to percentage
          },
        },
      },
      y: {
        grid: {
          display: false,
        },
        ticks: {
          autoSkip: false,
        },
      },
    },
    plugins: {
      legend: {
        display: true, // Display legend for comparison
      },
    },
    maintainAspectRatio: false,
  };

  return (
    <div
      style={{
        height: `${Math.max(labels.length * 30 + 50, 0)}px`,
        minWidth: "350px",
      }}
    >
      <Bar data={data} options={options} />
    </div>
  );
};

export default CompareBarChart;