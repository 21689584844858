import styled from "styled-components";

const StyledCampaignDetail = styled.div`
    background: #FFF;
    width: 958px;
    height: 722px;

    .head {
        margin: 16px 24px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .create-btn-ctn {
        border-radius: 8px;
        border: 1px solid rgba(220, 227, 227, 0.00);
        background: #4485F4;
        display: inline-flex;
        padding: 7.5px 12px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        cursor: pointer;
    }

    .plus {
        width: 12px;
        height: 12px;
    }

    .plus-text {
        color: #FFF;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    .drop-down-ctn {
        border-radius: 8px;
        border: 1px solid #CDD1D8;
        background: #FFF;
        display: inline-flex;
        padding: 7.5px 12px;
        justify-content: center;
        align-items: center;
        gap: 8px;
    }

    .drop-down-opt {
        color: #000;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    .drop-down-arrow {
        width: 16px;
        height: 16px;
    }

    .hor-line {
        height: 1px;
        background: #E3E5E9;
    }

    // .t-container {
    //     padding: 0px 24px;
    // }

    .t-head {
        height: 40px;
        display: grid;
        grid-template-columns: 0.5fr 1.5fr 1fr 1fr;
        align-items: center;
        margin: 0px 24px;
    }

    .t-head-font {
        color: #5C5E62;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    .t-body {
        overflow-x: auto;
        overflow-y: auto;
    }

    .t-row {
        display: grid;
        grid-template-columns: 0.5fr 1.5fr 1fr 1fr;
        min-height: 48px;
        align-items: center;
        padding: 0px 24px;

    }

    .t-row-odd {
        display: grid;
        grid-template-columns: 0.5fr 1.5fr 1fr 1fr;
        min-height: 48px;
        background: #F7F7F7;
        align-items: center;
        padding: 0px 24px;

    }

    .delete-img {
        width: 28px;
        height: 28px;
        cursor: pointer;
    }

    .t-data1 {
        color: #3A76DC;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        word-wrap: break-word;
    }

    .t-data2 {
        color: #000;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        word-wrap: break-word;
    }

    .t-data3 {
        color: #000;
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        word-wrap: break-word;
    }

    .extend {
        width: 12px;
        height: 12px;
        cursor: pointer;
    }

    .t-body-empty {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 600px;
    }

    .empty-img {
        width: 140px;
        height: 140px;
    }

    .empty-h1 {
        margin-top: 16px;
        color: #000;
        text-align: center;
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    .empty-text {
        margin-top: 8px;
        color: #000;
        text-align: center;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
        width: 254px;
    }
`;

export default StyledCampaignDetail;