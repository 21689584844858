import styled from 'styled-components';

const StyledLogin = styled.div`
.login-container{
    margin-top: 72px;
    margin-left: 48px;
    gap:16px;
    width: 440px;
}

.login {
    width: 118px;
    position: relative;
    font-size: 32px;
    line-height: 32px;
    display: inline-block;
    font-family: Inter;
    color: #000;
    text-align: left;
    white-space: nowrap;
    margin-bottom:40px;
}

.form-section{
    margin-top:16px;
}

.rectangle-div {
    width: 100%;
    position: relative;
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #cdd1d8;
    box-sizing: border-box;
    height: 48px;
    padding:10px;
    margin-top:10px;
    outline: none;
    box-shadow: none;
}

.email-title {
    width: 42px;
    position: relative;
    font-size: 12px;
    display: inline-block;
    font-family: Inter;
    color: #000;
    white-space: nowrap;
    text-align: left;
}

.password {
    width: 68px;
    position: relative;
    font-size: 12px;
    display: inline-block;
    font-family: Inter;
    color: #000;
    white-space: nowrap;
    text-align: left;
}

.login-btn-container {
    position: relative;
    font-weight: 500;
    margin-top: 24px;
    cursor:pointer;
}

.login-btn {
    width: 100%;
    position: relative;
    border-radius: 8px;
    background-color: #4485f4;
    border: 1px solid rgba(220, 227, 227, 0);
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 13px 12px;
    text-align: left;
    font-size: 18px;
    color: #fff;
    font-family: Inter;
    cursor:pointer;
}

.google-signup-btn-container{
    width: 100%;
    cursor: pointer;
    margin-top: 24px;
    position: relative;
    border-radius: 8px;
    background-color: #fff;
    border: 2px solid #cdd1d8;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 13px 12px;
    gap: 8px;
    text-align: left;
    font-size: 18px;
    color: #000;
    font-family: Inter; 
}

.google-icon {
    width: 100%;
    position: relative;
    height: 20px;
    width:20px;
    object-fit: cover;
} 

.orline{
    display:flex;
    align-items: center;
    margin-top: 24px;
}

.or {
    width: 20px;
    position: relative;
    font-size: 14px;
    font-family: Inter;
    color: #6f747e;
    text-align: center;
    display: inline-block;
}

.line-div {
    width: 100%;
    position: relative;
    border-top: 1px solid #e3e5e9;
    box-sizing: border-box;
    height: 1px;
}

.dont-have-an-container {
    width: 100%;
    margin-top:26px;
    position: relative;
    font-size: 14px;
    font-weight: 500;
    font-family: Inter;
    text-align: center;
    display: inline-block;
    color: #82868f;
} 

.sign-up {
    color: #4485f4;
    cursor: pointer;
}

.forgot {
    width: 43px;
    position: relative;
    font-size: 12px;
    text-decoration: underline;
    font-family: Inter;
    color: #000;
    text-align: right;
    display: inline-block;
    cursor: pointer;
}
.password-container{
    display: flex;
    justify-content: space-between;
}

.error-message {
    color: red;
    font-size: 0.8rem;
}

.error {
    border-color: red;
}

    .privacy {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
        margin: 20px 0;
    }

    .privacy-text {
        cursor: pointer;
    }

    .privacy-text:hover {
        color: #4485F4;
    }

`

export default StyledLogin;