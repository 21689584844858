import React, { useEffect, useState } from "react";
import StyledHomeCards from "./styled";
import connect_icons from "../../../assets/connect-card.png";
import googleIcon from "assets/platform_google.svg";
import tiktokIcon from "assets/platform_tiktok.svg";
import metaIcon from "assets/meta/icons8-meta-30.svg";
import addIcon from "assets/addIcon.png";
import viewIcon from "assets/viewIcon.png";
import campaignIcon from "assets/campaign/campaign.svg";
import linkIcon from "assets/linkImg.png";
import { useNavigate } from "react-router";

import Modal from "react-modal";
import "./app.css";
import infoIcon from "assets/info.svg";
import closeTourCard from "assets/close_tour_card.svg";
import addWidget from "assets/add_widget.svg";
import bulbIcon from "assets/bulb_icon.svg";
import { useSelector } from "react-redux";
import { RootState } from "services/store";

Modal.setAppElement("#root");

interface HomeCardsProps {
  setHeading: (val: String[]) => void;
}

const HomeCards: React.FunctionComponent<HomeCardsProps> = ({ setHeading }) => {
  const navigate = useNavigate();
  const country = useSelector((state: RootState) => state.home.country);
  
  useEffect(() => {
    setHeading(["Home", country.charAt(0) + country.substring(1).toLowerCase()]);
  }, []);

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [divContent, setDivContent] = useState("");
  const [modalPosition, setModalPosition] = useState({ top: 0, left: 0 });

  const openModal = (content: any) => {
    setDivContent(content.outerHTML);
    const rect = content.getBoundingClientRect();
    setModalPosition({
      top: rect.top + window.scrollY,
      left: rect.left + window.scrollX,
    });
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };
  return (
    <StyledHomeCards>
      <div className="cards-container">
        <div className="home-card-tour card1">
          <div className="close-tour-card">
            <img src={closeTourCard} alt=""/>
          </div>
          <div className="tour-card-body">Get to know your dashboard</div>
          <div className="tour-card-footer">
            <div style={{width:'80%'}}>Take a Tour</div>
            <div className="outer-circle">
              <div className="inner-circle">

              </div>
            </div>
          </div>
        </div>
        <div
          className="home-card card1"
          onClick={(e) => openModal(e.currentTarget)}
        >
          <div
            className="home-card-title"
            onClick={() => navigate("/dashboard/platform")}
          >
            Connect
            <div>
              <img src={linkIcon} alt="&" className="icon" />
            </div>
          </div>
          <div className="media-accounts-ctn">
            <div className="media-img">
              <div>
                <img src={googleIcon} alt="Google" />
              </div>
              <div>
                <img src={metaIcon} alt="Meta" />
              </div>
              <div>
                <img src={tiktokIcon} alt="Tiktok" />
              </div>
            </div>
            <div className="text">Connect your media account</div>
          </div>
        </div>{" "}
        <div className="home-card-tour card2" onClick={() => navigate("/dashboard/estimate")}>
          <div className="home-card-title"
          >
            Create +
            <div>
              <img src={addIcon} alt="+" className="icon" />
            </div>
          </div>

          <div className="tour-card-body">Create a custom target audience</div>
          <div></div>
        </div>{" "}

        <div className="home-card-tour card3" 
            onClick={() => navigate("/dashboard/explore")}>
          <div className="home-card-title">
            Calibrate
            <div className="icon-bg icon">
              <img src={bulbIcon} />
            </div>
          </div>
          <div className="tour-card-body">Calibrate and adjust your target audiences</div>
          <div></div>
        </div>{" "}

        <div className="home-card-tour card4" onClick={() => navigate("/dashboard/audience")}>
          <div
            className="home-card-title"
          >
            Audience
            <div>
              <img src={viewIcon} alt="-" className="icon" />
            </div>
          </div>

          <div className="tour-card-body">Manage your private custom audiences</div>
          <div></div>
        </div>{" "}
        
        <div className="home-card-tour card5" onClick={() => navigate("/dashboard/campaign")}>
          <div className="home-card-title">
            Campaigns
            <div>
              <img src={campaignIcon} className="icon" />
            </div>
          </div>
          <div className="tour-card-body">See your all your campaigns</div>
          <div></div>
        </div>{" "}

        <div className="home-card-tour card5" onClick={() => navigate("/dashboard/report")}>
          <div className="home-card-title">
            Reports
            <div>
              <img src={campaignIcon} className="icon" />
            </div>
          </div>
          <div className="tour-card-body">See your current campaign performances</div>
          <div></div>
        </div>{" "}

        <div className="home-card-add-widget">
          <div className="">
            <img src={addWidget} />
          </div>
          <div style={{ color: "black" }}>Add Widget</div>
        </div>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        className="modal-home"
        overlayClassName="modal-overlay-home"
        style={{
          content: {
            top: `${modalPosition.top}px`,
            left: `${modalPosition.left}px`,
            transform: "translate(0, 0)", // Adjust if needed
          },
        }}
      >
        <div className="modal-content-home">
          <div dangerouslySetInnerHTML={{ __html: divContent }} />
          <div className="info-ctn">
            <img src={infoIcon} alt="!" />
            <div className="info-rctn">
              <div className="info-text">
                To get started, connect one of your media accounts that you will
                use in your campaign.
              </div>
              <div className="ok-btn" onClick={() => setModalIsOpen(false)}>
                OK
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </StyledHomeCards>
  );
};

export default HomeCards;
