import styled from "styled-components";

const StyledMethod = styled.div`
  .welcome-title {
    margin-top: 24px;
    //width: 241px;
    position: relative;
    font-size: 32px;
    line-height: 32px;
    display: inline-block;
    font-family: Inter;
    color: #000;
    text-align: left;
    margin-bottom: 16px;
  }

  .tell-us-about {
    width: 480px;
    position: relative;
    font-size: 14px;
    font-family: Inter;
    color: #000;
    text-align: left;
  }

  .input-title {
    margin-top: 32px;
    position: relative;
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    font-family: Inter;
    color: #000;
    text-align: left;
    white-space: nowrap;
    display: inline-block;
  }
  .aud-name {
    margin-top: 32px;
    color: #000;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 150% */
  }

  .aud-name-inp {
    width: 400px;
    margin-top: 8px;
    border-radius: 8px;
    border: 1px solid #cdd1d8;
    background: #fff;
    height: 48px;
    display: flex;
    align-items: center;
  }
  .aud-name-input::placeholder {
    color: #82868f;
  }

  .aud-name-input {
    color: #000;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-left: 12px;
    border: none;
    box-shadow: none;
    outline: none;
    width: 400px;
  }

  .method-ctn {
    display: flex;
    gap: 20px;
    margin-top: 32px;
  }

  .method-item {
    display: flex;
    gap: 5px;
    align-items: center;
  }

  .input-radio {
    margin-top: 5px;
    padding: 0px;
  }
  .continue-btn-container {
    font-weight: 500;
    margin-top: 50px;
    display: flex;
    justify-content: flex-end;
    fill: #fff;
    width: 480px;
  }

  .continue-btn {
    border-radius: 8px;
    background-color: #4485f4;
    border: 1px solid rgba(220, 227, 227, 0);
    padding: 5px 30px;
    font-size: 18px;
    color: #fff;
    font-family: Inter;
    cursor: pointer;
  }

  .subscript-cont {
    font-size: 12px;
    margin-top: 10px;
    padding-left: 5px;
    width: 450px;
    font-style: italic;
  }

  .subscript1 {
    font-weight: 500;
  }

  .error {
    border: 1px solid red;
  }

  .error-msg {
    color: red;
    font-size: 14px;
  }

  .superscript {
    font-size: 12px;
    font-style: italic;
  }

  .img-ctn {
    display: flex;
    width: 480px;
    gap: 20px;
    margin-top: 10px;
  }

  .methodImg {
    height: 160px;
    width: 150px;
    cursor: pointer;
  }

  .active-img {
  }

  .img-icon-ctn {
    width: 25px;
    height: 30px;
    overflow: hidden;
  }

  .img-icon {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
  }

  .tooltip-ctn {
    display: flex;
    padding: 5px;
    border: 1px solid #000;
    border-radius: 5px;
    width: 475px;
    align-items: center;
    margin-top: 20px;
    gap: 5px;
    font-size: 15px;
  }

  .image-container {
    position: relative;
    display: inline-block; /* Adjust as needed for layout */
  }
  .image-container::before {
    content: "";
    position: absolute;
    top: 2%; /* Adjust for how much space to hide */
    right: 13%;
    bottom: 2%;
    left: 13%;
    border-radius: 5px;
    border: 5px solid #4485f4; /* Your border style */
    pointer-events: none; /* So the border doesn't interfere with clicking on the image */
  }
`;

export default StyledMethod;
