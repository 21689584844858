import React, { useEffect, useState } from "react";
import StyledUsers from "./styled";
import { vaticAxiosGet } from "utils/axios_helper";

const Users = () => {
  const [users, setUsers] = useState<
    Array<{
      first_name: string;
      last_name: string;
      email: string;
      last_login_time: any;
    }>
  >();

  useEffect(() => {
    const load = async () => {
      const res = await vaticAxiosGet("/api/v1/admin/users");
      if (res && res.data) {
        setUsers(res.data);
      }
    };
    load();
  }, []);
  return (
    <StyledUsers>
      <div>Users</div>
      <table>
        <thead>
          <tr>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Email</th>
            <th>Last Login Time</th>
          </tr>
        </thead>

        <tbody>
          {users?.map((item, ind) => (
            <tr key={ind}>
              <td>{item.first_name}</td>
              <td>{item.last_name}</td>
              <td>{item.email}</td>
              <td>{item.last_login_time}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </StyledUsers>
  );
};

export default Users;
