import React, { useEffect, useState } from "react";
import StyledSummary from "./styled";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { set, unset } from "services/reducer/audience";
import { RootState } from "services/store";
import { vaticAxiosPost } from "utils/axios_helper";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { REACT_APP_MEASUREMENT_ID } from "utils/axios_helper";
import ReactGA from "react-ga4";

interface Props {
  setHeading: (val: String[]) => void;
}

const Summary: React.FunctionComponent<Props> = ({setHeading}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  ReactGA.initialize(REACT_APP_MEASUREMENT_ID || "");

  const audience = useSelector((state: RootState) => ({
    audienceName: state.audience.aud_name,
    goal: state.audience.awareness ? "Awareness" : "conversion",
    promotions: state.audience.promoting,
    targetAud: state.audience.t_audience,
    advertise_location: state.audience.t_region,
    method: state.audience.method,
    custom_keywords: state.audience.custom_keywords,
    audience_id: state.audience.curr_audience_id,
    campagins: state.audience.campaigns,
    keywords: state.audience.keywords,
    urls: state.audience.urls,
    campaigns: state.audience.campaigns,
    audienceSize: state.audience.size,
  }));
  const countryDb = useSelector((state: RootState) => state.home.countryDb);
  const country = useSelector((state: RootState) => state.home.country);

  const handleClose = async () => {
    const selected_campaigns = audience.campaigns.filter(
      (item) => item.checked
    );

    const temp: any[] = selected_campaigns.map((item) => {
      return {
        campaign_id: item.id.toString(),
        platform: item.platforms[0],
        name: item.name,
        acc_id: item.acc_id.toString(),
      };
    });

    const res = await vaticAxiosPost("/api/v1/ads/populate/audience", {
      db: countryDb,
      audience_id: audience.audience_id,
      campaigns: temp,
      audience_name: audience.audienceName
    });

    if (res && res.statusCode == 200) {
      toast.success("Successfully Created Audience");
      dispatch(unset());
      navigate("/dashboard/audience");
    } else {
      toast.error(res.message);
    }

    ReactGA.event({
      category: "Button",
      action: "Click",
      label: "Audience Created",
    });
  };

  useEffect(() => {
    dispatch(set({ activeTab: 2 }));
    setHeading(["Audience", country.charAt(0) + country.substring(1).toLowerCase(), "Create", "Summary"]);
  }, []);

  return (
    <StyledSummary>
      <div>
        <div className="t4">Audience Name</div>
        <div className="t5">{audience.audienceName}</div>
      </div>
      <div className="hor-line-neg"></div>
      {audience.method === 1 ? (
        <div className="p-div">
          <div>
            <div className="t4">Audience Goal</div>
            <div className="t5">{audience.goal}</div>
          </div>

          <div className="hor-line-neg"></div>
          <div>
            <div className="t4">Promotion</div>
            <div className="t5">{audience.promotions}</div>
          </div>

          <div className="hor-line-neg"></div>
          <div>
            <div className="t4">Targeted Audience</div>
            <div className="t5">{audience.targetAud}</div>
          </div>
        </div>
      ) : (
        <>
          {audience.method === 2 ? (
            <div className="p-div">
              <div className="t4">Expert Keywords</div>
              <div className="t5">{audience.custom_keywords}</div>
            </div>
          ) : (
            <div className="">
              <div className="t4">Urls</div>
              <div className="t5">
                {audience.urls.split("\n").map((val, ind) => (
                  <div>{val}</div>
                ))}
              </div>
            </div>
          )}
        </>
      )}

      <div className="hor-line-neg"></div>
      {/* <div>
        <div className="t4">Targeted Location</div>
        <div className="t5">
          {audience.advertise_location.charAt(0).toUpperCase() +
            audience.advertise_location.slice(1).toLocaleLowerCase()}
        </div>
      </div> */}
      {/* <div className="hor-line-neg"></div> */}
      <div>
        <div className="t4">Audience Size</div>
        {audience.audienceSize.toLocaleString()}
      </div>
      <div className="hor-line-neg"></div>
      <div className="campaign-row">
        <div className="campaign-head">Campaign</div>
        <div className="campaign-head">Platform</div>
      </div>
      {audience.campagins.map(
        (campaign, ind: number) =>
          campaign.checked && (
            <div className="campaign-row">
              <div className="campaign-name">{campaign.name}</div>
              <div className="campaign-platform">{campaign.platforms}</div>
            </div>
          )
      )}
      <div className="continue-btn-container">
        <div
          className="back-btn"
          onClick={() => {
            navigate("/dashboard/audience/create/campaign");
          }}
        >
          Back
        </div>
        <div className="continue-btn" onClick={handleClose}>
          Finish
        </div>
      </div>
    </StyledSummary>
  );
};

export default Summary;
