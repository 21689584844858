import campaignBox from "assets/newLeft.png";
import styled from "styled-components";

const StyledAuthLeftScreen = styled.div`
  width: 720px;
  height: 100vh;
  // background: linear-gradient(357deg, #00276B 0%, #272727 100%);
  background: #303633;
  .container {
    margin-left: 25%;
    padding-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .logo-cont {
    margin-top: 24px;
  }

  .logo {
    width: 74.667px;
    height: 48px;
  }

  .quotes {
    margin-top: 56px;
    color: #fff;
    font-family: Inter;
    font-size: 19px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    width: 508px;
  }

  .rating-box {
    margin-top: 16px;
    display: flex;
    gap: 8px;
  }

  .user-name {
    color: #fff;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .star-box {
    display: flex;
    gap: 4px;
    align-items: center;
  }

  .star {
    width: 11px;
    height: 10px;
    fill: #f6cf00;
  }

  .designation {
    margin-top: 7px;
    color: #fff;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    opacity: 0.6;
  }

  // .campaign-box {
  //   margin-top: auto;
  //   border-left: 5px solid #838685;
  //   border-top: 5px solid #838685;
  //   border-top-left-radius: 20px;
  //   border-radius: 16px;
  //   background: url(${campaignBox}) lightgray 50% / cover no-repeat;
  //   width: 896.863px;
  //   height: 504.387px;
  //   flex-shrink: 0;
  //   z-index: 1;
  // }

  // .campaign-box-img {
  //   height: 490px;
  //   width: 371px;
  // }

  .right {
    display: flex;
    flex-direction: column;
    // align-items: flex-end;
    margin-right: 10px;
  }

  .benefit-box {
    margin: 48px 0px;
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  .benefit-row {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  .benefit-text {
    color: #fff;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
  }

  .text {
    color: #fff;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .company-logo-ctn {
    margin: 24px 0px 26px 0px;
    display: flex;
    gap: 24px;
    align-items: center;
  }
`;

export default StyledAuthLeftScreen;
