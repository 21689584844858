import styled from "styled-components";

const StyledHome = styled.div`
    margin: 0px;
    height: 810px;
    background: #FFF;
    display: flex;

    .container {
        width: 100%;
        display: flex;
        flex-direction: column;
    }
`

export default StyledHome;