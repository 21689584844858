import React from 'react';
import { Chart as ChartJS, ChartOptions } from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register();

interface Props {
    values: {label: string, val: number}[];
    hexColor: string;
}

const Histogram: React.FunctionComponent<Props> = ({values, hexColor}) => {
  const data = values.map(item => item.val);

  function hexToRgba(alpha: number = 1): string {
    // Remove the hash symbol if present
    const cleanHex = hexColor.replace(/^#/, '');
  
    // Parse the hex color values
    const r = parseInt(cleanHex.substring(0, 2), 16);
    const g = parseInt(cleanHex.substring(2, 4), 16);
    const b = parseInt(cleanHex.substring(4, 6), 16);
  
    // Return the rgba value
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  }

  const generateBackgroundColor = (value: number) => {
    const normalizedValue = Math.min(1, Math.max(0.3, value / Math.max(...data)));
    return hexToRgba(normalizedValue);
  };

  const chartData = {
    labels: values.map((l) => l.label),
    datasets: [
      {
        label: 'Histogram',
        data,
        backgroundColor: data.map(generateBackgroundColor),
        borderWidth: 0, 
        barThickness: 10,
      },
    ],
  };

  const chartOptions: ChartOptions<"bar"> = {
    indexAxis: "y",
    plugins: {
      legend: {
        display: false, // Hide the legend
      },
    },
    scales: {
      x: {
        ticks: {
          maxRotation: 90,
          minRotation: 90,
        },
        grid: {
          display: false, // Hide x-axis grid lines
        },
      },
      y: {
        grid: {
          display: false, // Hide y-axis grid lines
        },
      },
    },
    maintainAspectRatio: false,
  };

  return (
    <div style={{height: `${Math.max(data.length * 20 + 50, 0)}px`, width: '250px'}}>
      <Bar data={chartData} options={chartOptions} />
    </div>
  );
};

export default Histogram;