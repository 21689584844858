import styled from "styled-components";

const StyledResetPassword = styled.div`
    margin: 100px 50px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    
    .form-section {
        display: flex;
        flex-direction: column;
        gap: 5px;
        max-width: 300px;
    }
    
    .password {
        width: 68px;
        position: relative;
        font-size: 12px;
        display: inline-block;
        font-family: Inter;
        color: #000;
        white-space: nowrap;
        text-align: left;
    }

    .rectangle-div {
        width: 300px;
        position: relative;
        border-radius: 8px;
        background-color: #fff;
        border: 1px solid #cdd1d8;
        box-sizing: border-box;
        height: 48px;
        padding:10px;
        outline: none;
        box-shadow: none;
    }

    .error-message {
        color: red;
        font-size: 0.8rem;
    }

    .error {
        border-color: red;
    }

    .continue-btn-container {
        position: relative;
        font-weight: 500;
        margin-top: 24px;
        cursor:pointer;
    }

    .continue-btn {
        width: 200px;
        position: relative;
        border-radius: 8px;
        background-color: #4485f4;
        border: 1px solid rgba(220, 227, 227, 0);
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding: 13px 12px;
        text-align: left;
        font-size: 18px;
        color: #fff;
        font-family: Inter;
        cursor:pointer;
    }


`;



export default StyledResetPassword;