import React, { useEffect } from "react";
import { Route, Routes } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import platform, { set as platformSet } from "services/reducer/platform";
import { RootState } from "services/store";
import StyledCreateEstimate from "./styled";
import Left from "./Left";
import Method from "./Method";
import Define from "./Define";
import Keyword from "./Keyword";

interface CreateEstimateProps {
  setHeading: (val: String[]) => void;
}

const CreateAudience: React.FunctionComponent<CreateEstimateProps> = ({
  setHeading,
}) => {
  const dispatch = useDispatch();
  return (
    <StyledCreateEstimate>
      <div className="container">
        <Left></Left>
        <div>
          <Routes>
            <Route path="*" element={<Method  setHeading={setHeading}/>} />
            <Route path="define" element={<Define  setHeading={setHeading}/>} />
            <Route path="keyword" element={<Keyword  setHeading={setHeading}/>} />
          </Routes>
        </div>
      </div>
    </StyledCreateEstimate>
  );
};

export default CreateAudience;
