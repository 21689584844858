import React, { useEffect, useState, useRef } from "react";
import StyledKeyword from "./styled";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { set, unset } from "services/reducer/estimate";
import { RootState } from "services/store";
import elipse from "assets/elipse.svg";
import elipse1 from "assets/elipse-1.svg";
import plus from "assets/plus.svg";
import plusBtn from "assets/PlusButton.svg";
import minus from "assets/minus.svg";
import elipseCircle from "assets/elipse-circle.svg";
import smallCircle from "assets/small-circle.svg";
import { vaticAxiosGet, vaticAxiosPost } from "utils/axios_helper";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import gearIcon from "assets/gear.svg";
import bulbIcon from "assets/bulb.svg";
import WordCloud from "react-d3-cloud";
import barLineIcon from "assets/chris/bar-line.svg";
import bulbIcon1 from "assets/chris/bulb.svg";
import chainIcon from "assets/chris/chain.svg";
import eyeIcon from "assets/chris/eye.svg";
import editIcon from "assets/chris/edit.svg";
import puzzleIcon from "assets/chris/puzzle.svg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

interface Props {
  setHeading: (val: String[]) => void;
}

const Keyword: React.FunctionComponent<Props> = ({setHeading}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const estimate = useSelector((state: RootState) => ({
    keywordFetched: state.estimate.keywordsFetched,
    keywords: state.estimate.keywords,
    name: state.estimate.estimateName,
    object_of_interest: state.estimate.promoting,
    estimate_id: state.estimate.currEstimateId,
    method: state.estimate.method,
    custom_urls: state.estimate.urls,
  }));

  const countryDb = useSelector((state: RootState) => state.home.countryDb);

  const [angle, setAngle] = useState(0);
  const [newKeywordName, setNewKeywordName] = useState("");
  const [allSelected1, setAllSelected1] = useState(false);
  const [allSelected2, setAllSelected2] = useState(false);
  const [addedKeywordId, setAddedKeywordId] = useState<number>(-1);
  const [error, setError] = useState("");
  const country = useSelector((state: RootState) => state.home.country);

  const addNewKeyword = async () => {
    setError("");
    if (newKeywordName) {
      const res = await vaticAxiosGet("/keyword_score", {
        source_keyword: estimate.object_of_interest,
        candidate_keyword: newKeywordName,
        db: countryDb,
      });

      if (res && res.data) {
        const id = estimate.keywords.length;
        dispatch(
          set({
            keywords: [
              ...estimate.keywords,
              {
                candi_keyword: newKeywordName,
                score: res.data.score,
                selected: false,
              },
            ],
          })
        );
        setNewKeywordName("");
        // scrollToBottom();
        setAddedKeywordId(id);
      }
    }
  };

  const handleSelectedKeywords = (ind: number) => {
    setError("");
    if (estimate.keywords[ind].score >= 0.35) {
      setAllSelected1(false);
    } else {
      setAllSelected2(false);
    }
    let temp = [...estimate.keywords];
    temp[ind] = { ...temp[ind], selected: !temp[ind].selected };
    dispatch(set({ keywords: temp }));
  };

  useEffect(() => {
    const temp = estimate.keywords.reduce((prev, curr) => {
      return prev + (curr.selected && curr.candi_keyword ? 1 : 0);
    }, 0);
    if (temp > 0) {
      setAngle(360 / temp);
    }
  }, [estimate.keywords]);

  const selectAllKeyword = (n: number) => {
    setError("");
    if (n === 1) {
      if (allSelected1) {
        setAllSelected1(false);
        const temp = estimate.keywords.map((i) => {
          if (i.score >= 0.35) {
            return { ...i, selected: false };
          } else {
            return i;
          }
        });
        dispatch(set({ keywords: temp }));
      } else {
        setAllSelected1(true);
        const temp = estimate.keywords.map((i) => {
          if (i.score >= 0.35) {
            return { ...i, selected: true };
          } else {
            return i;
          }
        });
        dispatch(set({ keywords: temp }));
      }
    } else {
      if (allSelected2) {
        setAllSelected2(false);
        const temp = estimate.keywords.map((i) => {
          if (i.score < 0.35) {
            return { ...i, selected: false };
          } else {
            return i;
          }
        });
        dispatch(set({ keywords: temp }));
      } else {
        setAllSelected2(true);
        const temp = estimate.keywords.map((i) => {
          if (i.score < 0.35) {
            return { ...i, selected: true };
          } else {
            return i;
          }
        });
        dispatch(set({ keywords: temp }));
      }
    }
  };

  const divRef = useRef<HTMLDivElement>(null);
  const scrollToBottom = () => {
    divRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "end",
    });
  };

  useEffect(() => {
    if (addedKeywordId != -1) {
      handleSelectedKeywords(addedKeywordId);
      scrollToBottom();
    }
  }, [addedKeywordId]);

  const handleClose = () => {
    const keywords = estimate.keywords.map((i) => i.candi_keyword);
    const t = keywords.join(" && ");
    vaticAxiosPost("/publish_real_time_audience", {
      aud_id: estimate.estimate_id,
      db: countryDb,
      method: estimate.method,
      keywords: t,
      custom_urls: estimate.custom_urls,
      is_estimate: true,
    });
    dispatch(unset());
    navigate("/dashboard/estimate");
  };

  const [progress, setProgress] = useState(25);
  useEffect(() => {
    setHeading(["Create", country.charAt(0) + country.substring(1).toLowerCase(), "Keyword"]);

    const progressInterval = setInterval(() => {
      setProgress((prev) => {
        if (prev >= 75 || estimate.keywordFetched) {
          clearInterval(progressInterval);
          return 75;
        }
        return prev + 1;
      });
    }, 1000);

    return () => clearInterval(progressInterval);
  }, []);

  return (
    <StyledKeyword>
      <div className="container1">
        <div className="t2">Keywords</div>
        <div className="t3">
          We have generated the best keywords based on your estimate
          information.
        </div>

        <div className="keyword-25">
          {estimate.keywords.filter((i) => i.selected)?.length} keywords{" "}
          {error && <span className="error-msg">{error}</span>}
        </div>

        <div className="keyword-table-ctn">
          <div className="keyword-input-ctn">
            <input
              type="text"
              placeholder="Add new keyword..."
              className="keyword-input"
              value={newKeywordName}
              onChange={(e) => {
                setNewKeywordName(e.target.value);
              }}
              onBlur={(event) => setNewKeywordName(event.target.value)}
            />
            <img
              src={plusBtn}
              alt="add"
              className="pls-btn"
              onClick={addNewKeyword}
            />
          </div>
          <div className="keywrod-table-h">
            <div className="head1">
              <input
                className="checkbox m5"
                type="checkbox"
                onClick={(event) => {
                  selectAllKeyword(2);
                }}
                checked={allSelected2}
              />
              <div className="candidate-keyword align-right">
                Less Related keyword
              </div>
              <div className="candidate-keyword">Score</div>
            </div>
            <div className="ver-line"></div>
            <div></div>
            <div className="head1">
              <input
                className="checkbox"
                type="checkbox"
                onClick={(event) => {
                  selectAllKeyword(1);
                }}
                checked={allSelected1}
              />
              <div className="candidate-keyword align-right">
                Highly Related keyword
              </div>
              <div className="candidate-keyword">Score</div>
            </div>
          </div>
          <div className="hor-line"></div>
          {estimate.keywordFetched ? (
            <div className="keyword-table-body">
              <div className="w-100" ref={divRef}>
                {estimate.keywords
                  .map((i, index) => {
                    return { ...i, ind: index };
                  })
                  .filter((i) => i.score < 0.35)
                  .map((item, index) => (
                    <div
                      className={
                        index % 2 === 0
                          ? "keyword-table-row-even"
                          : "keyword-table-row"
                      }
                      key={index}
                    >
                      <input
                        className="checkbox m5"
                        type="checkbox"
                        checked={item.selected}
                        onClick={() => {
                          handleSelectedKeywords(item.ind);
                        }}
                      />
                      <div className="keyword-item">{item.candi_keyword}</div>
                      <div className="keyword-item-score">
                        {item.score && item.score.toFixed(2)}
                      </div>
                    </div>
                  ))}
              </div>
              <div className="ver-line" />
              <div className="w-100">
                {estimate.keywords
                  .map((i, index) => {
                    return { ...i, ind: index };
                  })
                  .filter((i) => i.score >= 0.35)
                  .map((item, index) => (
                    <div
                      className={
                        index % 2 === 0
                          ? "keyword-table-row-even"
                          : "keyword-table-row"
                      }
                      key={index}
                    >
                      <input
                        className="checkbox m5"
                        type="checkbox"
                        checked={item.selected}
                        onClick={() => {
                          handleSelectedKeywords(item.ind);
                        }}
                      />
                      <div className="keyword-item">{item.candi_keyword}</div>
                      <div className="keyword-item-score">
                        {item.score.toFixed(2)}
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          ) : (
            <div className="loading-ctn">
              <div className="progress-ctn">
                <CircularProgressbar
                  value={progress}
                  text={``}
                  styles={buildStyles({
                    // Rotation of path and trail, in number of turns (0-1)
                    rotation: 0,

                    // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                    strokeLinecap: "butt",

                    // Text size
                    textSize: "16px",

                    // How long animation takes to go from one percentage to another, in seconds
                    pathTransitionDuration: 0.5,

                    // Can specify path transition in more detail, or remove it entirely
                    // pathTransition: 'none',

                    // Colors
                    pathColor: "#4485f4",
                    textColor: "#4485f4",
                    trailColor: "#d6d6d6",
                    backgroundColor: "#4485f4",
                  })}
                  className="progress-text"
                />
                <img src={gearIcon} alt="" className="icon" />
              </div>
              <div className="text1">
                Generating Keywords
                <br /> Having a think <br />
                Unlocking insights
              </div>
              <div className="c-icon-ctn1">
                <Slider
                  dots={false}
                  infinite={true}
                  speed={1000}
                  slidesToShow={3}
                  slidesToScroll={1}
                  autoplay={true}
                  autoplaySpeed={500}
                  vertical={true}
                >
                  <div>
                    <img src={barLineIcon} alt="1" className="c-icon" />
                  </div>
                  <div>
                    <img src={bulbIcon1} alt="2" className="c-icon" />
                  </div>
                  <div>
                    <img src={chainIcon} alt="3" className="c-icon" />
                  </div>
                </Slider>
              </div>
            </div>
          )}
        </div>
        {estimate.keywordFetched ? (
          <div className="continue-btn-container">
            <div
              className="back-btn"
              onClick={() => {
                dispatch(set({ activeTab: 1 }));
                navigate("/dashboard/estimate/create/define");
              }}
            >
              Back
            </div>
            <div
              className="continue-btn"
              onClick={() => {
                if (estimate.keywords.filter((i) => i.selected).length == 0) {
                  setError("You must select atleast one keyword");
                  return;
                }
                handleClose();
              }}
            >
              Save Estimate
            </div>
          </div>
        ) : (
          <div className="continue-btn-container">
            <div
              className="back-btn"
              onClick={() => {
                dispatch(set({ activeTab: 1 }));
                navigate("/dashboard/estimate/create/define");
              }}
            >
              Back
            </div>
            <div className="continue-btn-disabled">Save Estimate</div>
          </div>
        )}
      </div>

      {estimate.keywordFetched ? (
        <div className="ctn-right">
          <div className="text2">
            You can remove words from the word cloud here
          </div>
          <div className="word-ctn">
            <WordCloud
              data={estimate.keywords
                .filter((i) => i.selected)
                .map((i) => {
                  return {
                    text: i.candi_keyword,
                    value: Math.floor(i.score * 100),
                  };
                })}
              rotate={0}
              fill={() => "#4484F3"}
              fontSize={() => Math.floor(Math.random() * (30 - 15 + 1)) + 15}
              fontWeight={() =>
                ["normal", "bold", "semibold"][Math.floor(Math.random() * 3)]
              }
              padding={5}
            />
            {/* <WordCloud
              data={estimate.keywords.map((i) => {
                return { text: i.candi_keyword, value: i.score * 1000 };
              })}
              rotate={0}
              padding={3}
            /> */}
          </div>
        </div>
      ) : (
        <div className="container2">
          <div className="text2">
            You can remove words from the word cloud here
          </div>
          <div className="loading-ctn">
            <div className="progress-ctn">
              <CircularProgressbar
                value={progress}
                text={``}
                styles={buildStyles({
                  // Rotation of path and trail, in number of turns (0-1)
                  rotation: 0,

                  // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                  strokeLinecap: "butt",

                  // Text size
                  textSize: "16px",

                  // How long animation takes to go from one percentage to another, in seconds
                  pathTransitionDuration: 0.5,

                  // Can specify path transition in more detail, or remove it entirely
                  // pathTransition: 'none',

                  // Colors
                  pathColor: "#4485f4",
                  textColor: "#4485f4",
                  trailColor: "#d6d6d6",
                  backgroundColor: "#4485f4",
                })}
                className="progress-text"
              />
              <img src={bulbIcon} alt="" className="icon" />
            </div>
            <div className="c-icon-ctn">
              <Slider
                dots={false}
                infinite={true}
                speed={1000}
                slidesToShow={3}
                slidesToScroll={1}
                autoplay={true}
                autoplaySpeed={500}
                vertical={true}
              >
                <div>
                  <img src={eyeIcon} alt="1" className="c-icon" />
                </div>
                <div>
                  <img src={editIcon} alt="2" className="c-icon" />
                </div>
                <div>
                  <img src={puzzleIcon} alt="3" className="c-icon" />
                </div>
              </Slider>
            </div>
            <div className="text1">
              Creating visuals <br />
              Charting a course <br />
              Getting things done <br />
              Making the magic happen <br />
              Visualizing Keywords
            </div>
          </div>
        </div>
      )}
    </StyledKeyword>
  );
};

export default Keyword;
