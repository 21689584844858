import google_logo from "assets/SignUp/google-logo.png";
import InviteTeam from "components/Auth/InviteTeam";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { set } from "services/reducer/signup";
import { RootState } from "services/store";
import {
  FULLSTORY_ORG_ID,
  getGoogleUserInfo,
  googleSignup,
  vaticAxiosPost,
} from "utils/axios_helper";
import StyledSignup from "./styled";
import queryString from "query-string";
import validator from "validator";
import FullStory from "react-fullstory";

const SignupForm: React.FC = () => {
  const navigate = useNavigate();
  const signupInfo = useSelector((state: RootState) => ({
    company_name: state.signup.company_name,
    first_name: state.signup.first_name,
    last_name: state.signup.last_name,
    email: state.signup.email,
    password: state.signup.password,
  }));
  const dispatch = useDispatch();

  const [companyName, setCompanyName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isChecked, setIsChecked] = useState(false); // State for checkbox
  const [showInvite, setShowInvite] = useState<boolean>(false);
  const [dataFetched, setDataFetched] = useState<boolean>(false);
  const [fetchedData, setFetchedData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    img_url: "",
    access_token: "",
    company_name: "",
    condition: true,
  });

  // State variables for error messages
  const [errors, setErrors] = useState({
    companyName: "",
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    checkbox: "",
  });

  const [errors1, setErrors1] = useState({
    companyName: "",
    firstName: "",
    lastName: "",
    email: "",
    condition: "",
  });

  useEffect(() => {
    setCompanyName(signupInfo.company_name);
    setFirstName(signupInfo.first_name);
    setLastName(signupInfo.last_name);
    setEmail(signupInfo.email);
    setPassword(signupInfo.password);
  }, [showInvite]);

  const dataFromGoogle = async (access_token: string) => {
    const data = await getGoogleUserInfo(access_token);

    if (data) {
      setDataFetched(true);
      setFetchedData({
        first_name: data.given_name,
        last_name: data.family_name,
        email: data.email,
        img_url: data.picture,
        access_token: access_token,
        company_name: "",
        condition: true,
      });
    } else {
      toast.error("Unable to sign up using google account", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    }
  };

  useEffect(() => {
    const parsedHash = queryString.parse(window.location.hash);
    const accessToken = Array.isArray(parsedHash["access_token"])
      ? parsedHash["access_token"][0]
      : parsedHash["access_token"];
    const referToken = Array.isArray(parsedHash["token"])
      ? parsedHash["token"][0]
      : parsedHash["token"];
    
    if(referToken) {
      localStorage.setItem('refer_token', referToken);
    }
    if (accessToken) {
      dataFromGoogle(accessToken);
    }
  }, []);

  // Validation function to check if any field is left empty
  const validateForm = () => {
    let valid = true;
    const newErrors = {
      companyName: "",
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      checkbox: "",
    };

    if (!companyName) {
      newErrors.companyName = "Company name is required";
      valid = false;
    }
    if (!firstName) {
      newErrors.firstName = "First name is required";
      valid = false;
    }
    if (!lastName) {
      newErrors.lastName = "Last name is required";
      valid = false;
    }
    if (!email) {
      newErrors.email = "Email is required";
      valid = false;
    } else if (!validator.isEmail(email)) {
      newErrors.email = "Please enter a valid Email";
      valid = false;
    }
    if (!password) {
      newErrors.password = "Password is required";
      valid = false;
    } else {
      const minLength = validator.isLength(password, { min: 8 });
      const hasNumbers = validator.matches(password, /\d/);
      const hasUppercase = validator.matches(password, /[A-Z]/);
      const hasSpecialChars = validator.matches(password, /[^A-Za-z0-9]/);

      if (!minLength || !hasNumbers || !hasUppercase || !hasSpecialChars) {
        newErrors.password =
          "Your password must be at least 8 characters long, include at least one uppercase letter, one number, and one special character.";
        valid = false;
      }
    }
    if (!isChecked) {
      newErrors.checkbox = "Please agree to the terms";
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  const signupDetails = () => {
    // Validate form before continuing
    const isValid = validateForm();

    // Proceed with signup if the form is valid
    if (isValid) {
      dispatch(
        set({
          company_name: companyName,
          first_name: firstName,
          last_name: lastName,
          email: email,
          password: password,
        })
      );
      setIsChecked(true);
      setShowInvite(true);
    }
  };

  const validateForm1 = () => {
    let valid = true;
    const newErrors = {
      companyName: "",
      firstName: "",
      lastName: "",
      email: "",
      condition: "",
    };

    if (!fetchedData.company_name) {
      newErrors.companyName = "Company name is required";
      valid = false;
    }
    if (!fetchedData.first_name) {
      newErrors.firstName = "First name is required";
      valid = false;
    }
    if (!fetchedData.last_name) {
      newErrors.lastName = "Last name is required";
      valid = false;
    }
    if (!fetchedData.condition) {
      newErrors.condition = "Please agree to the terms";
      valid = false;
    }

    setErrors1(newErrors);
    return valid;
  };

  const handleGoogleSignup = async () => {
    const valid = validateForm1();
    if (!valid) {
      return;
    }
    const referToken = localStorage.getItem('refer_token') || '';
    const res = await vaticAxiosPost("/api/auth/v1/register-by-google", {
      first_name: fetchedData.first_name,
      last_name: fetchedData.last_name,
      email: fetchedData.email,
      img_url: fetchedData.img_url,
      access_token: fetchedData.access_token,
      company_name: fetchedData.company_name,
      refer_token: referToken,
    });
    if (res.statusCode == 200) {
      toast.success(res.message, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
      navigate("/auth/login");
    } else {
      toast.error(`${res.message}`, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    }
  };

  return (
    <div>
      {!showInvite ? (
        <StyledSignup>
          {!dataFetched ? (
            <div className="sign-up-container">
              <b className="sign-up">Sign up</b>
              <div className="form-section">
                <label className="company-name" htmlFor="company_name">
                  Company name *
                </label>
                <input
                  className={`rectangle-div ${
                    errors.companyName ? "error" : ""
                  }`}
                  placeholder="Your company name"
                  type="text"
                  id="company_name"
                  name="company_name"
                  value={companyName}
                  onChange={(e) => setCompanyName(e.target.value)}
                />
                {errors.companyName && (
                  <span className="error-message">{errors.companyName}</span>
                )}
              </div>

              <div className="name-container form-section">
                <div>
                  <label className="first-name" htmlFor="first_name">
                    First Name *
                  </label>
                  <input
                    className={`rectangle-div ${
                      errors.firstName ? "error" : ""
                    }`}
                    placeholder="First Name"
                    type="text"
                    id="first_name"
                    name="first_name"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                  {errors.firstName && (
                    <span className="error-message">{errors.firstName}</span>
                  )}
                </div>

                <div>
                  <label className="last-name" htmlFor="last_name">
                    Last Name *
                  </label>
                  <input
                    className={`rectangle-div ${
                      errors.lastName ? "error" : ""
                    }`}
                    placeholder="Last Name"
                    type="text"
                    id="last_name"
                    name="last_name"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                  />
                  {errors.lastName && (
                    <span className="error-message">{errors.lastName}</span>
                  )}
                </div>
              </div>

              <div className="form-section">
                <label className="email-title" htmlFor="email">
                  Email *
                </label>
                <input
                  className={`rectangle-div ${errors.email ? "error" : ""}`}
                  placeholder="name@work-email.com"
                  type="email"
                  id="email"
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                {errors.email && (
                  <span className="error-message">{errors.email}</span>
                )}
              </div>

              <div className="form-section">
                <label className="password" htmlFor="password">
                  Password *
                </label>
                <input
                  className={`rectangle-div ${errors.password ? "error" : ""}`}
                  placeholder="Create a password"
                  type="password"
                  id="password"
                  name="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                {errors.password && (
                  <span className="error-message">{errors.password}</span>
                )}
              </div>

              <div className="tnc-container">
                <input
                  className="tnc-checkbox"
                  type="checkbox"
                  checked={isChecked}
                  onChange={() => setIsChecked(!isChecked)}
                ></input>
                <div className="by-creating-an-container">
                  By creating an account you agree with our
                  <span className="terms-of-service">
                    <a
                      href="https://audience.vaticai.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Terms of Service, Privacy Policy.
                    </a>
                  </span>
                </div>
              </div>
              {errors.checkbox && (
                <div className="error-message">{errors.checkbox}</div>
              )}

              <div className="continue-btn-container">
                <button className="continue-btn" onClick={signupDetails}>
                  Continue
                </button>
              </div>

              <div className="orline">
                <div className="line-div"></div>
                <div className="or">OR</div>
                <div className="line-div"></div>
              </div>

              <div
                className="google-signup-btn-container"
                onClick={googleSignup}
              >
                <img
                  className="google-icon"
                  alt="google-login"
                  src={google_logo}
                />
                <div className="continue-with-google">Sign up with Google</div>
              </div>

              <div className="already-have-an-container">
                <span>Already have an account? </span>
                <span
                  className="log-in"
                  onClick={() => navigate("/auth/login")}
                >
                  Log in
                </span>
              </div>

              <div className="privacy">
                <span
                  className="privacy-text"
                  onClick={() => navigate("/privacy")}
                >
                  PRIVACY POLICY
                </span>
              </div>
            </div>
          ) : (
            <div className="sign-up-container">
              <b className="sign-up">Create Account</b>
              <div className="form-section">
                <label className="company-name" htmlFor="company_name">
                  Company name *
                </label>
                <input
                  className={`rectangle-div ${
                    errors1.companyName ? "error" : ""
                  }`}
                  placeholder="Your company name"
                  type="text"
                  id="company_name"
                  name="company_name"
                  value={fetchedData.company_name}
                  onChange={(e) =>
                    setFetchedData((prev) => {
                      return { ...prev, company_name: e.target.value };
                    })
                  }
                />
                {errors1.companyName && (
                  <span className="error-message">{errors1.companyName}</span>
                )}
              </div>

              <div className="name-container form-section">
                <div>
                  <label className="first-name" htmlFor="first_name">
                    First Name *
                  </label>
                  <input
                    className={`rectangle-div ${
                      errors1.firstName ? "error" : ""
                    }`}
                    placeholder="First Name"
                    type="text"
                    id="first_name"
                    name="first_name"
                    value={fetchedData.first_name}
                    onChange={(e) =>
                      setFetchedData((prev) => {
                        return { ...prev, first_name: e.target.value };
                      })
                    }
                  />
                  {errors1.firstName && (
                    <span className="error-message">{errors1.firstName}</span>
                  )}
                </div>

                <div>
                  <label className="last-name" htmlFor="last_name">
                    Last Name *
                  </label>
                  <input
                    className={`rectangle-div ${
                      errors1.lastName ? "error" : ""
                    }`}
                    placeholder="Last Name"
                    type="text"
                    id="last_name"
                    name="last_name"
                    value={fetchedData.last_name}
                    onChange={(e) =>
                      setFetchedData((prev) => {
                        return { ...prev, last_name: e.target.value };
                      })
                    }
                  />
                  {errors1.lastName && (
                    <span className="error-message">{errors1.lastName}</span>
                  )}
                </div>
              </div>

              <div className="form-section">
                <label className="email-title" htmlFor="email">
                  Email *
                </label>
                <input
                  className={`rectangle-div ${errors.email ? "error" : ""}`}
                  placeholder="name@work-email.com"
                  type="email"
                  id="email"
                  name="email"
                  value={fetchedData.email}
                  disabled
                />
              </div>

              <div className="tnc-container">
                <input
                  className="tnc-checkbox"
                  type="checkbox"
                  checked={fetchedData.condition}
                  onChange={() =>
                    setFetchedData((prev) => {
                      return { ...prev, condition: !prev.condition };
                    })
                  }
                ></input>
                <div className="by-creating-an-container">
                  By creating an account you agree with our
                  <span className="terms-of-service">
                    <a
                      href="https://audience.vaticai.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Terms of Service, Privacy Policy.
                    </a>
                  </span>
                </div>
              </div>
              {errors1.condition && (
                <div className="error-message">{errors1.condition}</div>
              )}

              <div className="continue-btn-container">
                <button className="continue-btn" onClick={handleGoogleSignup}>
                  Create Account
                </button>
              </div>

              <div className="privacy">
                <span
                  className="privacy-text"
                  onClick={() => navigate("/privacy")}
                >
                  PRIVACY POLICY
                </span>
              </div>
            </div>
          )}
        </StyledSignup>
      ) : (
        <InviteTeam setShowInvite={setShowInvite} />
      )}
    </div>
  );
  <div>
    <FullStory org={FULLSTORY_ORG_ID || ""} />
  </div>;
};

export default SignupForm;
