import styled from "styled-components";

const StyledSignalCategory = styled.div`
  // margin-left: 5px;
  padding-left: 10px;
  padding-bottom: 5px;
  background: cream;

  .head {
    cursor: pointer;
    background: #c6dafd;
    padding: 5px 10px;
    display: flex;
    align-item: center;
    justify-content: space-between;
    font-size: 14px;
    font-weight: medium;
  }

  .img {
    width: 20px;
    height: 20px;
  }

  .text {
    display: flex;
    align-items: center;
    color: black;
  }

  .signal-name {
    cursor: pointer;
    padding-left: 7px;
    font-size: 12px;
  }

  .signal-name: hover {
    opacity: 0.7;
  }
  .active {
    color: blue;
  }
`;

export default StyledSignalCategory;
