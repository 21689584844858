import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface PreDefinedAud {
  id: number;
  is_custom: number;
  is_intent: number;
  name: string;
  total_maid: number;
}

interface KeywordsInterface {
  related_candi_keyword: string;
  related_score: number;
  isSelected: boolean;
  included_candi_keyword: string;
  included_score: number;
}

interface Campaign {
  name: string;
  client: string;
  platforms: string[];
  checked: boolean;
  id: number | string;
  acc_id: number | string;
}

export interface NewAudienceState {
  active_tab: number;
  is_predefined_on: boolean;
  curr_audi_id: number;
  is_audience_stored: boolean;
  industry_list: string[];
  selected_industry_id: number;
  pre_defined_aud_list: PreDefinedAud[];
  selected_pre_defined_aud: number;
  product_service_desc: string;
  keywords_list: KeywordsInterface[];
  is_keyword_fetched: boolean;
  campaigns: Campaign[];
  source_keyword: string;
  audience_size: number;
  creation_method: string;
  industry: string;
  defined_audience: string;
  credit_qualification: string;
  audience_name: string;
  fetch_keyword_data: boolean;
  tmp_ind: number;
  l: number;
  promoting: string;
  t_audience: string;
  t_region: string;
  awareness: boolean;
  prompt_id: number;
  updating: boolean;
}

// const temp = localStorage.getItem('newAudienceState') || '{}';
const temp = "{}";
const data = JSON.parse(temp);

const initialState: NewAudienceState = {
  active_tab: data.active_tab || 1,
  is_predefined_on: data.is_predefined_on || true,
  curr_audi_id: -1,
  is_audience_stored: false,
  industry_list: data.industry_list || ["Banking"],
  selected_industry_id: data.selected_industry_id || -1,
  pre_defined_aud_list: data.pre_defined_aud_list || [],
  selected_pre_defined_aud: data.selected_pre_defined_aud || -1,
  product_service_desc: data.product_service_desc || "",
  keywords_list: data.keywords_list || [],
  is_keyword_fetched: data.is_keyword_fetched || false,
  campaigns: [],
  source_keyword: "",
  audience_size: -1,
  creation_method: "Pre Defined",
  industry: "",
  defined_audience: "",
  credit_qualification: "",
  audience_name: "",
  fetch_keyword_data: true,
  tmp_ind: 0,
  l: 0,
  promoting: "",
  t_audience: "",
  t_region: "",
  awareness: false,
  prompt_id: -1,
  updating: false,
};

export const newAudienceSlice = createSlice({
  name: "newaudience",
  initialState,
  reducers: {
    set: (state, action: PayloadAction<Partial<NewAudienceState>>) => {
      localStorage.setItem(
        "newAudienceState",
        JSON.stringify({ ...state, ...action.payload })
      );
      return {
        ...state,
        ...action.payload,
      };
    },
    unset: (state) => {
      console.log("...unset...");
      return {
        ...initialState,
      };
    },
    addKeyword: (state, action: PayloadAction<KeywordsInterface>) => {
      return {
        ...state,
        keywords_list: [...state.keywords_list, action.payload],
      };
    },
  },
});

export const { set, unset, addKeyword } = newAudienceSlice.actions;
export default newAudienceSlice.reducer;
