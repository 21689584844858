import styled from "styled-components";

const StyledKeyword = styled.div`
  display: flex;
  gap: 25px;
  width: 1200px;
  // justify-content: flex-end;

  .container1 {
    width: 540px;
    display: flex;
    flex-direction: column;
    margin: 4px 0px;
  }

  .container2 {
    width: 540px;
    display: flex;
    flex-direction: column;
    margin: 24px 0px;
    height: 620px;
    fill: #fff;
    stroke-width: 1px;
    stroke: #cdd1d8;
    border: 1px solid #cdd1d8;
    border-radius: 8px;
  }

  .progress-ctn {
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
  }

  .elipse-ctn {
    margin-top: 72px;
    position: relative;
    width: 80px;
    height: 80px;
  }

  .elipse {
    position: absolute;
    top: 0px;
    left: 39%;
  }

  .elipse-text {
    color: #000;
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 150% */
    position: absolute;
    top: 35%;
    left: 35%;
  }

  .t1 {
    color: #000;
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
  }

  .t2 {
    color: #000;
    font-family: Inter;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px; /* 100% */
  }

  .t3 {
    margin-top: 16px;
    color: #000;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
  }

  .t4 {
    margin-top: 32px;
    color: #000;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 150% */
  }

  .drop-ctn {
    margin-top: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .dropdown-ctn {
    border-radius: 8px;
    border: 1px solid #cdd1d8;
    background: #fff;
    width: 232px;
    height: 48px;
    padding: 12px 14px;
    box-shadow: none;
    outline: none;
  }

  .option {
    color: #000;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .t5 {
    color: #000;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .t6 {
    color: #000;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .help {
    fill: #b5b6b9;
    width: 12px;
    height: 12px;
    cursor: pointer;
  }

  .speedo-meter-ctn {
    position: relative;
    height: 40px;
    width: 77px;
    margin-right: 15px;
  }

  .speedo-meter {
    position: absolute;
  }

  .needle {
    position: absolute;
  }

  .keyword-25 {
    margin-top: 24px;
    color: #000;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 150% */
  }

  .keyword-table-ctn {
    width: 540px;
    height: 520px;
    flex-shrink: 0;
    fill: #fff;
    stroke-width: 1px;
    stroke: #cdd1d8;
    border: 1px solid #cdd1d8;
    border-radius: 8px;
  }

  .candidate-keyword {
    color: #000;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-align: center;
  }

  .keyword-score {
    color: #5c5e62;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .keywrod-table-h {
    // padding: 8px 13px;
    display: grid;
    grid-template-columns: 1fr 1px 5px 1fr;
  }

  .head1 {
    margin: 8px 13px;
    display: grid;
    grid-template-columns: 0.5fr 3fr 0.75fr;
  }

  .head2 {
    margin: 8px 13px;
    display: grid;
    grid-template-columns: 0.5fr 3fr 0.75fr;
  }

  .ver-line {
    width: 1px;
    height: 100%;
    background: #cdd1d8;
  }

  .keyword-item {
    color: #000;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    // width: 180px;
    word-wrap: break-word;
    text-align: right;
  }

  .keyword-item-score {
    color: #000;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .w-100 {
    width: 100%;
  }

  .keyword-table-row {
    padding: 8px 13px;
    min-height: 40px;
    background: #f7f7f7;
    display: grid;
    grid-template-columns: 0.5fr 3fr 0.75fr;
    align-items: center;
    gap: 15px;
  }

  .keyword-table-row-even {
    padding: 8px 13px;
    min-height: 40px;
    background: #fff;
    display: grid;
    grid-template-columns: 0.5fr 3fr 0.75fr;
    align-items: center;
    gap: 15px;
  }

  .keyword-table-row1 {
    // padding: 0px 13px;
    height: 56px;
    background: #f7f7f7;
    display: grid;
    grid-template-columns: 3fr 0.75fr;
    align-items: center;
  }

  .keyword-table-row-even1 {
    // padding: 0px 13px;
    height: 56px;
    background: #fff;
    display: grid;
    grid-template-columns: 3fr 0.75fr;
    align-items: center;
  }

  .keyword-table-body {
    height: 80%;
    overflow-y: auto;
    display: grid;
    grid-template-columns: 1fr 1px 1fr;
  }

  .continue-btn-disabled {
    border-radius: 8px;
    background-color: #b5b6b9;
    padding: 5px 0px;
    font-size: 16px;
    color: #fff;
    font-family: Inter;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0px;
    width: 150px;
  }

  /* Hide default scrollbar */
  .keyword-table-body::-webkit-scrollbar {
    width: 5px; /* Set the width of the scrollbar */
  }

  /* Track */
  .keyword-table-body::-webkit-scrollbar-track {
    background: #f1f1f1; /* Color of the scrollbar track */
  }

  /* Handle */
  .keyword-table-body::-webkit-scrollbar-thumb {
    background: #c5c9ce; /* Color of the scrollbar handle */
    border-radius: 6px; /* Rounded corners */
  }

  /* Handle on hover */
  .keyword-table-body::-webkit-scrollbar-thumb:hover {
    background: #555; /* Color of the scrollbar handle on hover */
  }

  .hor-line {
    height: 1px;
    width: 100%;
    background: #cdd1d8;
  }

  .keyword-input-ctn {
    width: 540px;
    height: 48px;
    background: #fff;
    stroke-width: 1px;
    stroke: #cdd1d8;
    border-bottom: 1px solid #cdd1d8;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    display: flex;
    justify-content: space-between;
  }

  .keyword-input {
    color: #000;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border: none;
    outline: none;
    box-shadow: none;
    margin: 12px;
    width: 90%;
  }

  .keyword-input:placeholder {
    color: #82868f;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border: none;
    outline: none;
    box-shadow: none;
    margin: 12px;
    width: auto;
  }

  .pls-btn {
    margin: 12px;
    width: 24px;
    height: 24px;
    cursor: pointer;
  }

  .ctn-right {
    margin: 24px 0px;
    width: 540px;
    height: 620px;
    fill: #fff;
    stroke-width: 1px;
    stroke: #cdd1d8;
    border: 1px solid #cdd1d8;
    border-radius: 8px;
  }

  .ctn-right-h {
    height: 48px;
    display: flex;
    justify-content: space-between;
    margin-left: 16px;
    margin-right: 16px;
    align-items: center;
  }

  .ctn-right-h-text {
    color: #000;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
  }

  .ctn-right-h-text-b {
    color: #000;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
  }

  .act-cnt {
    display: flex;
    gap: 12px;
    align-items: center;
  }

  .act-item {
    width: 24px;
    padding: 4px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 4px;
    border: 1px solid #cdd1d8;
    background: #fff;
    cursor: pointer;
  }

  .hor-line {
    height: 1px;
    width: 100%;
    background: #cdd1d8;
  }

  .circle-ctn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    position: relative;
  }

  .center-circle {
    position: absolute;
    width: 18px;
    height: 18px;
    fill: #fff;
    stroke-width: 5px;
    stroke: #4485f4;
  }

  .empty {
    width: 10px;
  }

  .line {
    width: 70px;
    height: 1px;
    background: #cdd1d8;
  }

  .small-circle {
    width: 12px;
    height: 12px;
    transform: rotate(-105deg);
    flex-shrink: 0;
    fill: #fff;
    stroke-width: 2px;
    stroke: #4485f4;
  }

  .c1 {
    left: 50%;
    position: absolute;
    transform-origin: left;
    transform: rotateZ(20deg);
    display: flex;
    align-items: center;
  }

  .keyword-text {
    color: #000;
    text-align: right;
    font-family: Inter;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-left: 10px;
  }

  .c-text {
    color: #000;
    text-align: center;
    -webkit-text-stroke-width: 4;
    -webkit-text-stroke-color: #fff;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .c-text-cnt {
    width: 100%;
    position: relative;
    margin-top: 40px;
  }

  .rotate180 {
    transform: rotateZ(180deg);
    padding-right: 10px;
    padding-left: 0px;
  }

  .continue-btn-container {
    display: flex;
    justify-content: flex-end;
    gap: 20px;
    margin-top: 20px;
  }

  .continue-btn {
    border-radius: 8px;
    background-color: #4485f4;
    padding: 5px 0px;
    font-size: 16px;
    color: #fff;
    font-family: Inter;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0px;
    width: 150px;
  }

  .continue-btn:hover {
    opacity: 0.8;
  }

  .back-btn {
    border-radius: 8px;
    background-color: #b5b6b9;
    padding: 5px 0px;
    font-size: 16px;
    color: #000;
    font-family: Inter;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0px;
    width: 150px;
  }

  .back-btn:hover {
    opacity: 0.8;
  }

  .loading-ctn {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    position: relative;
  }

  .checkbox {
    margin: 0px;
    padding: 0px;
    width: 12px;
    height: 12px;
  }

  .m5 {
    margin-left: 5px !important;
  }

  .align-right {
    text-align: right;
    padding-right: 15px;
  }

  .error {
    border: 1px solid red;
  }

  .error-msg {
    color: red;
    font-size: 14px;
    font-weight: 400;
  }

  .progress-text {
    font-size: 16px;
    font-weight: bold;
    fill: #4485f4;
    margin: 0px;
    padding: 0px;
    stroke-width: 0px;
  }

  .progress-ctn {
    width: 100px;
    height: 100px;
  }

  .text1 {
    text-align: center;
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
  }

  .icon {
    position: absolute;
    width: 40px;
    height: 40px;
  }

  .text2 {
    padding: 20px;
    font-size: 16px;
    font-weight: 500;
    border-bottom: 1px solid #d8d8d8;
  }

  .word-ctn {
    height: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px;
  }

  .c-icon-ctn {
    position: absolute;
    right: 0px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    z-index: 100;
    width: 50px;
  }

  .c-icon {
    width: 30px;
    height: 30px;
  }

  .c-icon-ctn1 {
    position: absolute;
    right: 0px;
    top: 25%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    z-index: 100;
    width: 50px;
  }
`;

export default StyledKeyword;
